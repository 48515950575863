import React from 'react';
import { ICE_URL } from '../../types';

interface Props {
  url?: string;
}
export let Cart = ({ url = ICE_URL }: Props) => {
  let submit = () => {
    window.location.href = url;
  };
  return (
    <div id="cart" className="h-full text-white">
      <div className="h-full flex items-center">
        <a className="w-6 h-6 cursor-pointer" onClick={submit} href={ICE_URL}>
          <img className="w-11" src="https://static.sky.co.nz/sky/icons/cart.svg" alt="Shopping Cart" />
        </a>
      </div>
    </div>
  );
};
