import React, { useEffect, useState } from "react";

import { SocialButtons } from "../social";
import { closex, menu, TVGuideIcon } from "../icon";
import { SideNav } from "./sideNav";
import { SearchBar } from "./SearchBar";
import { Cart } from "./Cart";
import cx from "classnames";
import { getMenuSectionListMobileQuery, getMenuSectionListQuery } from "../../graphql/queries/getMenuList";
import { useLockBodyScrollOnValue } from "../../helper/useLockBodyScroll";
import { NavigationLinks, Link, MobileLink } from "../../types";
import User from "./User";
import { useQuery } from "@apollo/client";
import { LogoIconName, TVGuide } from "../../config";
import { tags,AUTH0_LOGOUT_URL,ACCOUNT_URL } from "../../config";
import { useAuth0 } from "@auth0/auth0-react";
const HEADER_HEIGHT = "4rem";

interface BtnProps {
  SessionClickHandler: () => void;
  isSignedIn: boolean;
  isScreen: string;
}

let LoginButton = ({}: React.DetailedHTMLProps<
  React.ButtonHTMLAttributes<HTMLButtonElement>,
  HTMLButtonElement
>) => {
  const { isAuthenticated, loginWithRedirect, logout } = useAuth0();
  return (
    <div className="sm:hidden flex justify-center bg-blue-pure text-white text-center">
      <button
        className="font-bold p-4 text-2xl md:text-xl text-white hover:text-navy"
        onClick={() =>
          isAuthenticated
            ? logout({ logoutParams: { returnTo:AUTH0_LOGOUT_URL  } })
            : loginWithRedirect()
        }
      >
        {isAuthenticated ? "Log out" : "Log in"}
      </button>
    </div>
  );
};

interface Props {
  SessionClickHandler?: () => void;
  isSignedIn?: boolean;
  name?: string;
  links: NavigationLinks;
  cartURL?: string;
}

let Header = () => {
  const { isAuthenticated, isLoading, user, loginWithRedirect, logout } =
    useAuth0();
  let currentUrl = window.location.href;
  const [menuHeader, setMenuHeader] = useState([]);
  const [skyLogo, setSkyLogo] = useState();

  //for header rendering data coming from this service
  const { data: GlobalmenuSectionList } = useQuery<any>(
    getMenuSectionListQuery,
    {
      variables: {
        usePreview: false,
        tags: tags?.globalHeader,
      },
      fetchPolicy: "cache-and-network",
    }
  );

 //for header mobile rendering data coming from this service
 const { data: getMenuSectionListMobile } = useQuery<any>(
  getMenuSectionListMobileQuery,
  {
    variables: {
      usePreview: false,
      tags: tags?.golbalMobileHeader,
    },
    fetchPolicy: "cache-and-network",
  }
);
  //for Social media icons comming from this service

  const { data: GlobalSocialIcons } = useQuery<any>(getMenuSectionListQuery, {
    variables: {
      usePreview: false,
      tags: tags?.globalFooter,
    },
    fetchPolicy: "cache-and-network",
  });
  interface composePageSubMenuDataProps {
    name: string;
    itemUrl: string;
    itemIcon: string;
    internalName: string;
  }

  interface link {
    itemIcon: string | undefined;
    slug: null;
    itemUrl: string;
    sectionLinks: [composePageSubMenuDataProps];
  }
  const SocialIcons: link[] =
    GlobalSocialIcons?.GlobalMenuList?.[0]?.menuSections;

  const composePageSubMenuDataNew: link[] =
    GlobalmenuSectionList?.GlobalMenuList?.[0]?.menuSections;

  const mobieMenudata = getMenuSectionListMobile?.GlobalMenuList?.[0]?.menuSections?.filter(
    (linkVal: { slug: string; }) => linkVal?.slug === "main-menu-items-mobile"
  );


  const modifiedMobileMenuData = { 
    children: mobieMenudata?.length > 0 && mobieMenudata[0]?.sectionLinks?.map((linkVal: { itemUrl: any; name: any; sectionName: any; sectionLinks: any[]; }) => ({ 
      link: linkVal.itemUrl,
      title: linkVal.name || linkVal?.sectionName,
      children: linkVal?.sectionLinks?.map(subLinkVal => ({
        link: subLinkVal.itemUrl,
        title: subLinkVal.name,
      }))
     })) 
  }

  
  let [showSidebar, showSideBarSet] = useState(false);
  let close = () => showSideBarSet(false);
  useLockBodyScrollOnValue(showSidebar);
  return (
    <>
      {showSidebar && (
        <div className="fixed inset-0 z-1000">
          <div
            className="absolute inset-0 bg-blue-darker bg-opacity-50"
            onClick={close}
          ></div>
          <div
            className="absolute h-full left-0 w-110 md:w-120 max-w-full bg-white"
          >
            <div className="px-3 md:px-6">
              <button className="w-16 h-16 text-black" onClick={close}>
                {closex}
              </button>
            </div>
            
            <p className="md:hidden text-2xl md:text-xl text-black font-bold px-8 pt-4">
              {isAuthenticated ? `Kia ora, ${user?.nickname || user?.given_name}` : "Welcome"}
            </p>
            <SideNav
              close={close} 
              link={modifiedMobileMenuData}
            />

            <div className=" absolute left-0 bottom-0 w-full">
              <div className="flex justify-center py-5">
                <SocialButtons link={SocialIcons} />
              </div>
              <div className="sm:hidden flex justify-center bg-blue-pure text-white text-center">
                <LoginButton className="font-bold p-8 text-2xl md:text-xl text-white hover:text-navy" />
              </div>
            </div>
          </div>
        </div>
      )}

      {/* New Navbar */}
      <div
        className="bg-white font-sans flex items-center justify-start z-100 px-[20px] 2xl:px-50px sticky top-0 shadow-md"
        style={{ height: HEADER_HEIGHT }}
        id="header"
      >
        <div className="flex min-w-16 pr-8">
          <div
            className="flex items-center gap-6 2lg:gap-0 px-0 w-full"
            style={{ height: HEADER_HEIGHT }}
          >
            {/* Hide hamburger menu from desktop. */}
            <div className="2lg:hidden">
              <button
                className="w-10 h-10 relative text-blue-darker top-3px"
                onClick={() => showSideBarSet(true)}
              >
                {menu}
              </button>
            </div>
            <div className="w-full h-full flex items-center">
              {composePageSubMenuDataNew?.map((link, index) => {
                if (link?.slug === "sky_logo_hamburger") {
                  return (
                    <React.Fragment key={index}>
                      {link?.sectionLinks?.map((sectionLinksData, idx) => {
                        if (sectionLinksData?.name === LogoIconName) {
                          return (
                            <a key={idx} href={sectionLinksData?.itemUrl}>
                              <img
                                src={sectionLinksData?.itemIcon}
                                className="sm:h-10 h-32px m-auto"
                                alt="Sky logo"
                              />
                            </a>
                          );
                        }
                        return null;
                      })}
                    </React.Fragment>
                  );
                }
                // Return null if link?.slug !== "sky_logo_hamburger"
                return null;
              })}
            </div>
          </div>
        </div>

        <nav id="header-nav" className="new-navbar font-extrabold font-sans">
          {composePageSubMenuDataNew?.map(
            (link, index: React.Key | null | undefined) => {
              if (
                link?.slug !== "sky_logo_hamburger" &&
                link?.slug !== "cart_search_tvguide"
              ) {
                let current = currentUrl.includes(link.itemUrl!);
                return (
                  <React.Fragment key={index}>
                    {link?.sectionLinks?.map(
                      (sectionLinksData, idx: React.Key | null | undefined) => (
                        <a
                          key={idx}
                          href={sectionLinksData.itemUrl}
                          className={cx(
                            "font-bold text-blue-darker leading-normal",
                            {
                              "md:sky-h6-black": current,
                              "md:sky-h7": !current,
                            }
                          )}
                        >
                          {sectionLinksData.name}
                        </a>
                      )
                    )}
                  </React.Fragment>
                );
              }
              return null;
            }
          )}
        </nav>
        <div
          className={`flex items-center gap-6 2lg:gap-4 justify-end 2lg:min-w-64 pl-6 ml-auto`}
        >
          {composePageSubMenuDataNew?.map((link, index) => {
            if (link?.slug === "cart_search_tvguide") {
              return (
                <React.Fragment key={index}>
                  {link?.sectionLinks?.map((sectionLinksData, idx) => {
                    if (sectionLinksData?.name === TVGuide) {
                      return (
                        <a
                          key={idx}
                          className="flex"
                          href={sectionLinksData?.itemUrl}
                        >
                          <TVGuideIcon />
                        </a>
                      );
                    }
                    return null;
                  })}
                </React.Fragment>
              );
            }
            return null;
          })}

        {composePageSubMenuDataNew? <SearchBar />:""}
          {
            composePageSubMenuDataNew?.map((link, index) => {
              if (link?.slug === "cart_search_tvguide") {
                return (
                  <React.Fragment key={index}>
                    {link?.sectionLinks?.map((sectionLinksData, idx) => {
                      if (
                        sectionLinksData?.internalName === "Menu Item - Cart"
                      ) {
                        return !isAuthenticated?<Cart url={sectionLinksData?.itemUrl} />: "";
                      }
                      return null;
                    })}
                  </React.Fragment>
                );
              }
              return null;
            })}

          {composePageSubMenuDataNew &&<div
            className={`border-l-2 sm:flex items-center border-blue-darker pl-4 hidden sm:pt-2`}
          >
            {!isAuthenticated && <User />}
            <div className="flex flex-col items-end">
              {isAuthenticated && (
                <p className="text-lg  text-blue-darker font-bold block whitespace-no-wrap">
                  Kia ora, {user?.nickname || user?.given_name}
                </p>
              )}
              <button
                className="sky-h5-black text-blue-pure text-lg block whitespace-no-wrap"
                onClick={() =>
                  isAuthenticated
                    ? logout({ logoutParams: { returnTo:AUTH0_LOGOUT_URL  } })
                    : loginWithRedirect()
                    // loginWithRedirect({ appState: { returnTo: ACCOUNT_URL ? ACCOUNT_URL : window.location.origin } })
                }
              >
                {isAuthenticated ? "Log out" : "Log in"}
              </button>
            </div>
          </div>}
        </div>
      </div>
    </>
  );
};

export { Header, HEADER_HEIGHT };
