import React, { ReactNode } from 'react';
import { Document, BLOCKS, INLINES, MARKS } from '@contentful/rich-text-types';
import { Options, documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { Accordion } from '../Accordion';

interface TermsAndConditionContentProps {
  content: TermsAndConditionContentType;
}

export type TermsAndConditionContentType = {
  bgTitle: string;
  internalName: string;
  isExpandable: boolean;
  slug: string;
  subTitle?: string;
  title?: string;
  terms?: Document;
};

export const TermsAndConditionContent = ({ content }: TermsAndConditionContentProps) => {
  const tableProps = {
    style: { borderWidth: '1px' },
    className: 'border-collapse border-black text-left',
  };

  const tableBodyStyle = {
    style: { ...tableProps.style },
    className: `${tableProps.className} py-2 w-full md:w-4/5 lg:w-3/5`,
  };

  const renderOptions: Options = {
    renderText: (text: string) =>
      text
        .split('\n')
        .reduce(
          (children: any, textSegment: any, index: number) => [
            ...children,
            index > 0 && <br className="sky-h4" key={index} />,
            textSegment,
          ],
          []
        ),

    renderMark: {
      [MARKS.BOLD]: (text: ReactNode) => <b>{text}</b>,
    },
    renderNode: {
      [BLOCKS.HEADING_1]: (_, children: ReactNode) => (
        <h1 className="py-2 mx-auto text-2xl md:text-3xl text-black">{children}</h1>
      ),
      [BLOCKS.PARAGRAPH]: (_, children: ReactNode) => <p className="text-lg text-black">{children}</p>,
      // [INLINES.HYPERLINK]: (node: any, children: ReactNode) => (
      //   <a href={node?.data?.uri} className="sitemapColor">
      //     {children}
      //   </a>
      // ),
      [INLINES.HYPERLINK]: (node: any, children: ReactNode) => (
        <a
          href={node?.data?.uri}
          onClick={(e) => {
            e.preventDefault();
            const url = node?.data?.uri;

            if (
              url &&
              (url.startsWith("http://") ||
                url.startsWith("https://") ||
                url.includes("."))
            ) {
              window.open(url, "_blank", "noopener,noreferrer");
            } else {
              window.location.href = url;
            }
          }}
          className="sitemapColor"
        >
          {children}
        </a>
      ),
      [BLOCKS.OL_LIST]: (_, children: ReactNode) => {
        return content?.slug === 'sky-rewards-terms' ? (
          <div className="px-8 pb-8 lg:px-0 container mx-auto container">
            <ol className="text-lg text-black termsOrderedList">{children}</ol>
          </div>
        ) : content?.slug === 'online-and-mobile-terms' ? (
          <ol className="text-lg text-black online-mobile-terms-termsOrderedList">{children}</ol>
        ) : (
          <ol className="text-lg text-black termsOrderedList">{children}</ol>
        );
      },
      [BLOCKS.UL_LIST]: (_, children: ReactNode) => <ul className="text-lg text-black termsUnorderedList">{children}</ul>,
      [BLOCKS.TABLE]: (_, children: ReactNode) => (
        <table {...tableBodyStyle}>
          <tbody>{children}</tbody>
        </table>
      ),
      [BLOCKS.TABLE_HEADER_CELL]: (_, children: ReactNode) => <th {...tableProps}>{children}</th>,
      [BLOCKS.TABLE_ROW]: (_, children: ReactNode) => <tr {...tableProps}>{children}</tr>,
      [BLOCKS.TABLE_CELL]: (_, children: ReactNode) => <td {...tableProps}>{children}</td>,
      [BLOCKS.EMBEDDED_ENTRY]: (node: any) => {
        let { isExpandable, title, terms, bgTitle, subTitle, referenceId } = node?.data?.target?.fields
        return (<>
          {isExpandable ? (
            <div className="md:w-11/12 md:termscontainer mx-auto" id={referenceId}>
              <Accordion title={title!}>
                <div className="px-12 py-4 sky-bg-gray-lighter">
                  <TermsContent terms={terms} />
                </div>
              </Accordion>
            </div>
          ) : (
            <>
              <Title text={bgTitle} />
              <SubTitle text={subTitle} />
              <div className="px-8 pb-8 lg:px-0 container mx-auto">
                <TermsContent terms={terms} />
              </div>

            </>
          )}
        </>
        )
      }
      ,
    },
  };

  const Title = ({ text }: { text?: string }) =>
    text ? (
      <div className="px-4 py-18 sky-blue-gradient text-center text-white">
        <h3 className="sky-h3 md:sky-h3"
          dangerouslySetInnerHTML={{
            __html: text,
          }}></h3>
      </div>
    ) : (
      <></>
    );

  const SubTitle = ({ text }: { text?: string }) =>
    text ? <div className="text-lg text-black text-center font-semibold"
      dangerouslySetInnerHTML={{
        __html: text,
      }}></div> : <></>;

  const TermsContent = ({ terms }: { terms?: Document }) =>
    terms ? <>{documentToReactComponents(terms, renderOptions)}</> : <></>;

  const isEmbeddedContent = content?.terms?.content?.some((c: any) => c?.nodeType === BLOCKS.EMBEDDED_ENTRY);

  return (
    <div className="flex flex-col mx-auto justify-start gap-4">
      <>
        <Title text={content?.bgTitle} />
        <SubTitle text={content?.subTitle} />
        {(!isEmbeddedContent) ? (
          <div className="px-8 pb-8 lg:px-0 container mx-auto">
            <TermsContent terms={content?.terms} />
          </div>
        ) : (
          <TermsContent terms={content?.terms} />
        )}
      </>
    </div>
  );
};
