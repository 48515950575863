import React from 'react';
import { search, closex } from '../icon';
import { useState } from 'react';
import { LIFERAY_URL } from '../../types';

export let SearchBar = () => {
  let [query, querySet] = useState('');
  let [show, showSet] = useState(false);
  let onKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.keyCode === 13 && query.length > 0) {
      submit();
    }
  };
  let submit = () => {
    let url = `https://help.sky.co.nz/s/global-search/${query}`;
    window.location.href = url;
  };
  return (
    <div id="search-bar" className="h-full flex text-blue-darker">
      {show && (
        <div className="absolute flex  items-center justify-between h-full right-0 md:w-92 bg-white">
          <div className="w-6 h-6 m-4">{search}</div>
          <input
            className="bg-transparent flex-1 min-w-0 text-blue-darker"
            type="text"
            value={query}
            placeholder="Type to search..."
            onChange={e => querySet(e.target.value)}
            onKeyDown={onKeyDown}
          />
          <button className="w-12 h-12  m-4 mr-4" onClick={() => showSet(false)}>
            {closex}
          </button>
        </div>
      )}

      {!show && (
        <div className="h-full flex items-center">
          <button className="w-6 h-6" onClick={() => showSet(true)}>
            {search}
          </button>
        </div>
      )}
    </div>
  );
};
