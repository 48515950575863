import React, { useEffect, useState } from "react";
import { isTablet } from "../../types";

export const LinksCollections = ({
  componentCardSectionTitle,
  subTitle,
  titleBackgroundGradientColor,
  titleTextAlignment,
  subTitleTextAlignment,
  linkCollections,
  linkType,
  linkAlignmentPosition,
  linksDataContent
}: any) => {
  const [isMobile, setIsMobile] = useState(false);
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  return (
    <>
      {componentCardSectionTitle && (
        <h1
          className={
            titleBackgroundGradientColor === "None" ||
            titleBackgroundGradientColor === null
              ? "sky-h4 sm:sky-h4 md:sky-h4 custom-card-font-bold mt-3  text-4xl p-2"
              : "sky-h4 sm:sky-h4 md:sky-h4 custom-card-font-bold mt-3  text-4xl p-2 sky-blue-gradient"
          }
          style={{
            color:
              titleBackgroundGradientColor === "None" ||
              titleBackgroundGradientColor === null
                ? "#00013A"
                : "white",
            fontFamily: "MarkPro-Black SKY",
            // marginBottom: "3rem",
            padding:
              titleBackgroundGradientColor === "None" ||
              titleBackgroundGradientColor === null
                ? ""
                : "3.5rem",
            // marginTop: " -0.25rem",//BB:commented for heading having top and bottom spaces
            marginBottom:isMobile?"2rem":
              titleBackgroundGradientColor === "None" ||
              titleBackgroundGradientColor === null
                ? ""
                :"2rem",
            marginTop: "2rem",
            marginLeft:
              isMobile?"":(titleBackgroundGradientColor === "None" ||
              titleBackgroundGradientColor === null
                ? titleTextAlignment ?? "left" === "left"
                  ? "4rem"
                  : ""
                : ""),
            fontSize: isMobile?"1.825rem":"48px",
            textAlign:
              (titleTextAlignment as "left" | "right" | "center" | "justify") ||
              "left",
          }}
          dangerouslySetInnerHTML={{
            __html: componentCardSectionTitle as string,
          }}
        ></h1>
      )}
      {subTitle && (
        <div
          className="py-4"
          style={{
            color: "#000000",
            marginLeft:
            subTitleTextAlignment ?? "left" === "left"?( isMobile? "0.5rem" : "5rem"):"",
            fontSize: "1.125rem",
            textAlign:
              (subTitleTextAlignment as
                | "left"
                | "right"
                | "center"
                | "justify") || "left",
          }}
          dangerouslySetInnerHTML={{
            __html: subTitle as string,
          }}
        ></div>
      )}

      {linksDataContent && (
        <div
          style={{
            textAlign: linksDataContent?.alignmentPosition,
            paddingTop: isMobile ? "0.5rem" : "1rem",
            paddingBottom:"1rem"
          }}
          className={
            linksDataContent?.linkText ? "link-item text-center text-blue-400" : ""
          }
        >
          <a
            href={linksDataContent?.linkUrl || linksDataContent?.linkDocument?.url}
            onClick={(e) => {
              e.preventDefault();
              window.open(linksDataContent?.linkUrl || linksDataContent?.linkDocument?.url);
            }}
            className={
              linksDataContent?.linkText
                ? linksDataContent?.backgroundGradientColor === "Blue"
                  ? "text-lg font-bold px-4 sky-bg-daylight text-white py-4 rounded-full btn btn-lg"
                  : "sky-button sky-button--secondary-light btn btn-nm sky-h7 font-bold py-4 px-10 btn btn-lg"
                : ""
            }
            style={{
              cursor: "pointer",
              fontSize:"1.125rem"
            }}
          >
            {linksDataContent?.linkText ? (
              linksDataContent?.linkText
            ) : (
              <img
                style={{
                  width: linkType ? "80%" : "80%",
                  marginLeft: linkType ? "" : "auto",
                  marginRight: linkType ? "" : "auto",
                }}
                src={linksDataContent?.linkImage?.url}
              />
            )}
          </a>
        </div>
      )}
      <div
        className={
          linkType
            ? "grid lg:grid-cols-2 gap-1 md:grid-cols-1 lg:mx-20 md:mx-5 mx-4"
            : "grid lg:grid-cols-2 gap-10 md:grid-cols-1 lg:mx-20 md:mx-5 mx-4"
        }
        style={{
          marginRight: isMobile ? "" : linkType ? "17rem" :isTablet?"13rem" : "25rem",
          marginLeft: isMobile ? "" : linkType ? "0px" : isTablet ? "13rem":"25rem",
          marginBottom:"5rem"
        }}
      >
        {linkCollections &&
          linkCollections?.map((linkData: any, i: any) => (
            <div
              key={i}
              style={{
                textAlign: linkData?.alignmentPosition,
                paddingTop: isMobile ? "0.5rem" : "1rem",
              }}
              className={
                linkData?.linkText ? "link-item text-center text-blue-400" : ""
              }
            >
              <a
                href={linkData?.linkUrl || linkData?.linkDocument?.url}
                onClick={(e) => {
                  e.preventDefault();
                  if (
                    linkData?.linkDocument?.url &&
                    (linkData?.linkDocument?.url.startsWith("http://") ||
                      linkData?.linkDocument?.url.startsWith("https://") ||
                      linkData?.linkDocument?.url.includes(".")) || linkData?.linkUrl &&
                      (linkData?.linkUrl.startsWith("http://") ||
                      linkData?.linkUrl.startsWith("https://") ||
                        linkData?.linkUrl.includes("."))
                  ) {
                    window.open(
                      linkData?.linkUrl || linkData?.linkDocument?.url,
                      "_blank",
                      "noopener,noreferrer"
                    );
                  } else {
                    window.location.href =
                      linkData?.linkUrl || linkData?.linkDocument?.url;
                  }
                }}
                className={
                  linkData?.linkText
                    ? linkData?.backgroundGradientColor === "Blue"
                      ? "text-lg font-bold px-4 sky-bg-daylight text-white py-4 rounded-full btn btn-lg"
                      : "sky-button sky-button--secondary-light btn btn-nm sky-h7 font-bold py-4 px-10 btn btn-lg"
                    : ""
                }
                style={{
                  cursor: "pointer",
                  fontSize:"1.125rem"
                }}
              >
                {linkData?.linkText ? (
                  linkData?.linkText
                ) : (
                  <img
                    style={{
                      width: linkType ? "70%" : "70%",
                      marginLeft: linkType ? "" : "auto",
                      marginRight: linkType ? "" : "auto",
                    }}
                    src={linkData?.linkImage?.url}
                  />
                )}
              </a>
            </div>
          ))}
      </div>
    </>
  );
};
