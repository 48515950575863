import numeral from "numeral";

export enum ComposePageContentType {
  WEB_CONTENT_SINGLE_BANNER = 'webContentSingleBanner',
  COMPONENT_CARD_SECTION = 'componentCardSection',
  POPUP_CARDS = 'popupCardCollection',
  ROLLING_BANNER = 'heroScrollingBanner',
  COMPONENT_CARD_COLLECTION = 'componentCardCollection',
  LOGOS_CONTENT_ACCORDIAN = 'logoContentAccordion',
  COMPONENT_CARD = 'componentCard',
  ARTICAL_COLLECTION = "articleCollection",
  FREEFORM_CONTENT = "freeformContent",
  LINKS_COLLECTIONS = "linksCollection",
  LINKS = "links",
  CARDPANEL= "cardPanel",
}

export interface composePageSubMenuDataProps {
  // sectionName: string | null | undefined;
  name: string
  itemUrl: string;
  itemIcon: string | null | undefined;
  internalName: string | null | undefined;
  // sectionName:string | null | undefined;

}

export interface composePageSubMenuMobileDataProps {
  sectionName: string | null | undefined;
  name: string | null; 
  itemUrl: string;
  itemIcon: string | null | undefined;
  internalName: string | null | undefined;
  // sectionName:string | null | undefined;

}
export interface composePageSubMenuMobileDataProps {
  name: string | null;
  itemUrl: string;
  itemIcon: string | null | undefined;
  internalName: string | null | undefined;
  sectionLinks: [composePageSubMenuDataProps];
  sectionName:string | null | undefined;
}
export interface Link {
  itemIcon: string | undefined;
  slug: null | string | undefined;
  itemUrl: string | null | undefined;
  sectionLinks: composePageSubMenuDataProps[];
}

export interface NavigationLinks {
  readonly logoUrl: string;
  readonly logoSrc: string;
  readonly TVGuideURL: string;
  readonly header: Link[];
  readonly side: Link[];
  readonly footer: Link[];
  readonly social: Link[];
  readonly corporate: Link[];
}

export const ICE_URL = "https://get.sky.co.nz/build-my-plan/tv-packages";

export const LIFERAY_URL = "https://www.sky.co.nz";
export const linkCollectionType = "componentCardCollection"
export interface FooterLink {
  itemIcon: string | undefined;
  slug: string | undefined | null;
  itemUrl: string | undefined | null;
  sectionLinks: composePageSubMenuDataProps[];
}
export interface MobileLink {
  link: any;
  itemIcon: string | undefined |null;
  slug: null | string | undefined;
  itemUrl: string;
  name:string;
  internalName:string;
  sectionLinks: [composePageSubMenuMobileDataProps];
  sectionName:string | null | undefined;
  parent?:MobileLink; // Optional parent link
}
export interface linkDoc {
  file: string;
}

export interface articleProps {
  heading: string;
  date: string;
  linkText: string;
  linkDocument: linkDoc;
  articleType: string;
}

export const formatMoneyAutoDecimal = (num: number) => numeral(num).format('$0,0[.]00');

export const isTablet = window.innerWidth >= 768 && window.innerWidth < 1024;

export const isMobile = window.innerWidth <= 768;
