import React, { useEffect, useState } from "react";
import {
  HORIZONTAL_VIDEO_PLAYER_URL,
  VERTICAL_VIDEO_PLAYER_URL,
} from "../../config";
import { DocumentToReactComponents } from "./DocumentToReactComponents";
import { useNavigate } from "react-router-dom";
import { LinksCollections } from "./LinkCardCollections";

interface ComponentCardSection {
  heading?: string;
  cardImageUrl?: {
    url?: string;
    text?: string;
  };
  cardImagePosition?: string;
  cardImageLinkUrl?: string;
  cardTitle?: string;
  cardSubTitle?: string;
  cardDescription?: string;
  cardDescriptionTextAlignment?: string;
  titleAlignmentPosition:string;
  cardDetailedDescription?: JSON;
  cardDetailedDescriptionAlignment?: string;
  brightCoveVideoId?: string;
  linkText?: string;
  linkUrl?: string;
  links?: {
    internalName?: string;
    linkText?: string;
    linkUrl?: string;
    linkDocument?: {
      url?: string;
      text?: string;
    };
  };
}
interface ComponentCardProps {
  componentCardSectionTitle: string;
  componentCards: ComponentCardSection[];
  componentCardDisplayTile: string;
  titleBackgroundGradientColor:string;
  subTitle?: string;
  subTitleTextAlignment?:string | undefined | null;
  titleTextAlignment?:string | undefined | null
  referenceId?: string;
}

export const ComponentCardCollection = ({
  componentCardSectionTitle,
  componentCards,
  componentCardDisplayTile,
  titleBackgroundGradientColor,
  subTitle,
  subTitleTextAlignment,
  titleTextAlignment,
  referenceId
}: ComponentCardProps) => {
  let navigate = useNavigate();
  let displayTile;
  switch (componentCardDisplayTile.replace(" ", "")) {
    case "Twotile":
      displayTile = "grid-cols-2 gap-4";
      break;

    case "Threetile":
      displayTile = "grid-cols-3 gap-4";
      break;

    case "Fourtile":
      displayTile = "grid-cols-4 gap-0";
      break;

    case "Fivetile":
      displayTile = "grid-cols-5 gap-0";
      break;
    case "Sixtile":
      displayTile = "grid-cols-6 gap-0";
      break;
    default:
      displayTile = "grid-cols-1 gap-0";
      break;
  }
  const [isMobile, setIsMobile] = useState(false);
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  return (
    <>
    <div id={referenceId}>
      {componentCardSectionTitle && (
        <h1
          className={
            titleBackgroundGradientColor === "None" ||
            titleBackgroundGradientColor === null
              ? "sky-h4 sm:sky-h4 md:sky-h4 custom-card-font-bold mt-3  text-4xl p-2"
              : "sky-h4 sm:sky-h4 md:sky-h4 custom-card-font-bold mt-3  text-4xl p-2 sky-blue-gradient"
          }
          style={{
            color:
              titleBackgroundGradientColor === "None" ||
              titleBackgroundGradientColor === null
                ? "#00013A"
                : "white",
            fontFamily: "MarkPro-Black SKY",
            // marginBottom: "3rem",
            padding:
              titleBackgroundGradientColor === "None" ||
              titleBackgroundGradientColor === null
                ? ""
                : "3.5rem",
            // marginTop: " -0.25rem",//BB:commented for heading having top and bottom spaces
            marginBottom:
              titleBackgroundGradientColor === "None" ||
              titleBackgroundGradientColor === null
                ? ""
                :"2rem",
            marginTop: "2rem",
            marginLeft: isMobile?"":(titleBackgroundGradientColor === "None" ||
            titleBackgroundGradientColor === null ?(titleTextAlignment ?? "left" === "left" ? "4rem" : ""):""),
            fontSize:isMobile?"1.825rem": "48px",
            textAlign:
              (titleTextAlignment as "left" | "right" | "center" | "justify") ||
              "left",
          }}
          dangerouslySetInnerHTML={{
            __html: componentCardSectionTitle as string,
          }}
        ></h1>
      )}
     
      {subTitle && (
        <div
          className="py-4"
          style={{
            color: "#000000",
            marginLeft:
            subTitleTextAlignment ?? "left" === "left"?( isMobile? "0.5rem" : "5rem"):"",
            fontSize: "1.3rem",
            fontWeight:"600",
            textAlign:
              (subTitleTextAlignment as
                | "left"
                | "right"
                | "center"
                | "justify") || "left",
          }}
          dangerouslySetInnerHTML={{
            __html: subTitle as string,
          }}
        ></div>
      )}
      <div
        className={`pb-4 grid lg:${displayTile} md:grid-cols-2 lg:mx-20 md:mx-5 mx-4 grid-cols-1`}
        style={{ marginTop: "3rem" }}
      >
        {componentCards?.map((componentCardCollection: any) => {
          return (
            <div
              className="card overflow-hidden shadow-none pb-4"
              style={{ borderRadius: 0 }}
            >
              {componentCardCollection?.heading && (
                <div
                  className="sky-h3 md:sky-h3-bold sky-text-midnight"
                  style={{ paddingBottom: "2rem", textAlign: "left" }}
                  dangerouslySetInnerHTML={{
                    __html: componentCardCollection?.heading as string,
                  }}
                ></div>
              )}
              <div>
                {componentCardCollection?.cardImageUrl?.url?.length > 0 &&
                  !componentCardCollection?.iframeSourceUrl && (
                    <a
                      href={componentCardCollection?.cardImageLinkUrl}
                      onClick={(e) => {
                        e.preventDefault();
                        if (
                          componentCardCollection?.cardImageLinkUrl &&
                          componentCardCollection?.cardImageLinkUrl != null &&
                          (componentCardCollection?.cardImageLinkUrl.startsWith(
                            "http://"
                          ) ||
                            componentCardCollection?.cardImageLinkUrl.startsWith(
                              "https://"
                            ) ||
                            componentCardCollection?.cardImageLinkUrl.includes(
                              "."
                            ))
                        ) {
                          window.open(
                            componentCardCollection?.cardImageLinkUrl,
                            "_blank",
                            "noopener,noreferrer"
                          );
                        } else {
                          if(componentCardCollection?.cardImageLinkUrl !=null) {
                            window.location.href =
                            componentCardCollection?.cardImageLinkUrl;
                          }
                         
                        }
                      }}
                    >
                      <img
                        src={
                          componentCardCollection?.cardImageUrl?.url as string
                        }
                        className="w-[98%]"
                        style={{
                          borderTopLeftRadius: 0,
                          borderTopRightRadius: 0,
                          cursor:componentCardCollection?.cardImageLinkUrl?"pointer":"",
                          height: (() => {
                            // Handle "Three tile" cases
                            if (componentCardDisplayTile === "Three tile") {
                              switch (componentCardCollection?.imageType) {
                                case "Card Image":
                                // return "218.996px";
                                case "Logo":
                                  return "";
                                case "Icon":
                                  return "130px";
                                default:
                                //return "330.996px";
                              }
                            }

                            // Handle "Two tile" cases
                            if (componentCardDisplayTile === "Two tile") {
                              switch (componentCardCollection?.imageType) {
                                case "Card Image":
                                  return "";
                                case "Logo":
                                  return "20rem";
                                case "Icon":
                                  return "";
                                default:
                                //return "330.996px";
                              }
                            }

                            // Handle "Four tile" case
                            if (componentCardDisplayTile === "Four tile") {
                              return "";
                            }

                            // Default height if no conditions match
                            return "";
                          })(),
                        }}
                      />
                    </a>
                  )}
                {componentCardCollection?.iframeSourceUrl && (
                  <div><iframe
                    className="w-full lg: w-full"
                    src={componentCardCollection?.iframeSourceUrl}
                    title={componentCardCollection?.iframeTitle}
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    referrerPolicy="strict-origin-when-cross-origin"
                    style={{
                      borderTopLeftRadius: 0,
                      borderTopRightRadius: 0,
                      height: "204.997px",
                    }}
                  ></iframe>
                  <script type="text/javascript" src={"https://skytv.elmotalent.co.nz/js/iframeResizer.min.js"}></script></div>
                )}
              </div>

              {componentCardCollection?.brightCoveVideoId && (
                <div
                  style={{
                    position: "relative",
                    display: "block",
                    maxWidth: "1920px",
                  }}
                >
                  <div style={{ paddingTop: "56.25%" }}>
                    <iframe
                      title={componentCardCollection?.cardTitle}
                      // src ={'https://players.brightcove.net/6122285394001/experience_63211edca1522b0025cf4d1d/index.html?videoId=6331602517112'}
                      src={
                        HORIZONTAL_VIDEO_PLAYER_URL +
                        componentCardCollection?.brightCoveVideoId
                      }
                      allow="encrypted-media"
                      className="absolute w-full h-full top-0 left-0 bottom-0"
                    ></iframe>
                  </div>
                </div>
              )}

              <div className="mt-2 py-3">
                <div
                  className="sky-cc-h4 sm:sky-h4 md:sky-h4"
                  style={{
                    color: "#000000",                    
                  }}
                >
                  <div>
                    {componentCardCollection?.cardTitle && (
                      <div
                        style={{
                          fontWeight: "bold",
                          textAlign:
                            componentCardCollection?.titleAlignmentPosition,
                        }}
                        dangerouslySetInnerHTML={{
                          __html: componentCardCollection?.cardTitle as string,
                        }}
                      ></div>
                    )}
                    {componentCardCollection?.cardSubTitle && (
                      <div
                        className="sky-h6 sm:sky-h6 md:sky-h6 py-4"
                        style={{
                          color: "#000000",
                          fontSize: "18px",
                          fontWeight: "400",
                          textAlign:
                            componentCardCollection?.subTitleAlignmentPosition,
                        }}
                        dangerouslySetInnerHTML={{
                          __html:
                            componentCardCollection?.cardSubTitle as string,
                        }}
                      ></div>
                    )}
                    {componentCardCollection?.cardDetailedDescription && (
                      <div
                        style={{
                          //height: componentCardCollection?.linkUrl && "220px",
                          textAlign:
                            componentCardCollection?.cardDetailedDescriptionAlignment,
                        }}
                      >
                        <p className="sky-h4" style={{ fontSize: "18px" }}>
                          <DocumentToReactComponents
                            richtextEditorProps={
                              componentCardCollection?.cardDetailedDescription as any
                            }
                          />
                        </p>
                      </div>
                    )}

                    {componentCardCollection?.links &&
                      componentCardCollection?.links?.length === 1 &&
                      componentCardCollection.links.map(
                        (linkData: any, i: any) => (
                          <div
                            key={i}
                            className={
                              linkData?.linkText
                                ? "link-item text-center text-blue-400"
                                : ""
                            }
                            style={{
                              textAlign: linkData?.alignmentPosition,
                              paddingTop: "1rem",
                              paddingBottom: "1rem",
                            }}
                          >
                            <a
                              href={
                                linkData?.linkUrl || linkData?.linkDocument?.url
                              }
                              onClick={(e) => {
                                e.preventDefault();
                                if (
                                  (linkData?.linkDocument?.url &&
                                    (linkData?.linkDocument?.url.startsWith(
                                      "http://"
                                    ) ||
                                      linkData?.linkDocument?.url.startsWith(
                                        "https://"
                                      ) ||
                                      linkData?.linkDocument?.url.includes(
                                        "."
                                      ))) ||
                                  (linkData?.linkUrl &&
                                    (linkData?.linkUrl.startsWith("http://") ||
                                      linkData?.linkUrl.startsWith(
                                        "https://"
                                      ) ||
                                      linkData?.linkUrl.includes(".")))
                                ) {
                                  window.open(
                                    linkData?.linkUrl ||
                                      linkData?.linkDocument?.url,
                                    "_blank",
                                    "noopener,noreferrer"
                                  );
                                } else {
                                  window.location.href =
                                    linkData?.linkUrl ||
                                    linkData?.linkDocument?.url;
                                }
                              }}
                              className={
                                linkData?.linkText
                                  ? linkData?.backgroundGradientColor === "Blue"
                                    ? "text-lg font-bold px-4 sky-bg-daylight text-white py-4 rounded-full"
                                    : "sky-button sky-button--secondary-light btn btn-nm sky-h7 font-bold py-4 px-10"
                                  : ""
                              }
                              style={{
                                cursor: "pointer",
                              }}
                            >
                              {linkData?.linkText ? (
                                linkData?.linkText
                              ) : (
                                <img
                                  style={{ width: "35%" }}
                                  src={linkData?.linkImage?.url}
                                />
                              )}
                            </a>
                          </div>
                        )
                      )}
                    {componentCardCollection?.links &&
                    componentCardCollection?.links?.length === 2 ? (
                      <LinksCollections
                        linkCollections={componentCardCollection?.links}
                        linkType="componentCardCollection"
                        linkAlignmentPosition={
                          componentCardCollection?.linkAlignmentPosition
                        }
                      />
                    ) : (
                      ""
                    )}
                  </div>
                </div>

                {componentCardCollection?.cardDescription && (
                  <div
                    className="sky-h6 sm:sky-h7 md:sky-h7"
                    style={{
                      // height: componentCardCollection?.linkUrl && "220px",
                      textAlign: "center",
                    }}
                  >
                    <p>{componentCardCollection?.cardDescription}</p>
                  </div>
                )}

                {componentCardCollection?.linkUrl &&
                  componentCardCollection?.links === null && (
                    <div
                      className="mt-4"
                      style={{
                        marginTop: "1rem",
                        textAlign:
                          componentCardCollection?.linkAlignmentPosition,
                      }}
                    >
                      <a
                        href={componentCardCollection?.linkUrl}
                        onClick={(e) => {
                          e.preventDefault();
                          window.open(componentCardCollection?.linkUrl);
                        }}
                        className="text-lg font-bold sky-text-daylight text-blue py-4 "
                        style={{ fontSize: "18px" }}
                      >
                        {componentCardCollection?.linkText}
                      </a>
                    </div>
                  )}
                {componentCardCollection?.linkUrl === null &&
                  componentCardCollection?.links === null &&
                  componentCardCollection?.linkText &&
                  componentCardCollection?.linkDocument &&
                  componentCardCollection?.linkDocument?.url && (
                    <div
                      className="mt-4"
                      style={{
                        marginTop: "1rem",
                        textAlign:
                          componentCardCollection?.linkAlignmentPosition,
                      }}
                    >
                      <a
                        href={componentCardCollection?.linkDocument?.url}
                        onClick={(e) => {
                          e.preventDefault();
                          window.open(
                            componentCardCollection?.linkDocument?.url
                          );
                        }}
                        className="text-lg font-bold sky-text-daylight text-blue px-4 py-4 "
                        style={{ fontSize: "18px" }}
                      >
                        {componentCardCollection?.linkText}
                      </a>
                    </div>
                  )}
              </div>
              {componentCardCollection?.footnoteText && (
                <div style={{ textAlign: "center" }}>
                  <DocumentToReactComponents
                    richtextEditorProps={
                      componentCardCollection?.footnoteText as any
                    }
                  />
                </div>
              )}
            </div>
          );
        })}
      </div>
      </div>
    </>
  );
};

export default ComponentCardSection;
