import React, { useEffect, useState } from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import { Helmet } from "react-helmet";
import { ComposePageScreen } from "./screens/composePage";
import { useQuery } from "@apollo/client";
import {
  getMenuSectionListQuery,
} from "./graphql/queries/getMenuList";
import { NotFoundContainer } from "./components/not-found-page/NotFoundContainer";
import { IEBanner } from "./components/ieBanner";
import { Header } from "./components/header";
import { Footer } from "./components/footer";
import { tags } from "./config";
export type MenuItemsType = {
  slug: string;
  text: string;
};
let Body = () => {
  const { data: menuSectionList } = useQuery<any>(getMenuSectionListQuery, {
    variables: {
      usePreview: false,
      tags: tags?.globalSubMenu,
    },
    fetchPolicy: "cache-and-network",
  });

  interface composePageSubMenuDataProps {
    name: string;
    itemUrl: string;
  }

  const composePageSubMenuData: composePageSubMenuDataProps[] =
    menuSectionList?.MenuList?.[0]?.menuSections;

  const composePageSubMenu = (composePageSubMenuData ?? []).map(
    ({ name, itemUrl }) => ({
      slug: itemUrl,
      text: name,
    })
  );

  const { pathname } = useLocation();
  const isSitemap = pathname === '/sitemap'
  const slug = pathname.replace("/", "");

  const isComposePageMenu =
    composePageSubMenu?.length > 0 &&
    (composePageSubMenu ?? []).some((m) => m.slug === slug)!;

    const [isMobile, setIsMobile] = useState(false);
    useEffect(() => {
      const handleResize = () => {
        setIsMobile(window.innerWidth <= 768);
      };
      handleResize();
      window.addEventListener("resize", handleResize);
      return () => {
        window.removeEventListener("resize", handleResize);
      };
    }, []);
  return (
    <div className="flex flex-col mx-auto lg:max-w-[1280px] md:max-w-[960px] max-tab:max-w-[600px] mb-4" style={{display:isMobile?"block":"",}}>
      <div className="flex-1" >
        <div>
          <Routes>
            {(isComposePageMenu) ? (
              (composePageSubMenu ?? []).map(({ slug }) => (
                <Route
                  key={slug!}
                  path={`/${slug}`}
                  element={
                    <ComposePageScreen
                      menus={composePageSubMenu as MenuItemsType[]}
                    />
                  }
                />
              ))
            ) : slug !== "notfound" && slug !== "" && !isSitemap ? (
              <Route
                key={slug!}
                path={`/${slug}`}
                element={
                  <ComposePageScreen
                    menus={[{ slug, text: slug }] as MenuItemsType[]}
                  />
                }
              />
            ) : isSitemap ? (
              <Route
                key={pathname!}
                path={pathname}
                element={
                  <ComposePageScreen
                    menus={[{ slug, text: slug }] as MenuItemsType[]}
                  />
                }
              />
            ):(
              <Route
                path="*"
                key="notfound"
                element={<NotFoundContainer redirectURL="/" label="Go Home" />}
              />
            )}
          </Routes>
        </div>
      </div>
    </div>
  );
};
const App = () => {
  return (
    <div className="App ">
      <Helmet>
        <link
          rel="canonical"
          href={window.location.protocol + "//" + window.location.host}
        />
      </Helmet>
      <Header />
      <IEBanner>
        <Body />
      </IEBanner>
      <div id="download"></div>
      <Footer />
    </div>
  );
};

export default App;
