import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { Arrow } from './Arrow';

export interface ISliderProps {
  children:any,
  arrowColorClassName?: string;
  dots?: boolean;
  infinite?: boolean;
  speed?: number;
  autoplay?: boolean;
  autoplaySpeed?: number;
  centerMode?: boolean;
  variableWidth?: boolean;
  centerPadding?: string;
  slidesToShow?: number;
  slidesToScroll?: number;
  swipeToSlide?: boolean;
  arrows?: boolean;
  prevArrow?: JSX.Element;
  nextArrow?: JSX.Element;
  customClassName?: string;
  responsiveBlock?: any[];
  initialSlide?: number;
  sliderRef?: React.RefObject<Slider>;
  afterChange?: (currentSlide: number) => void;
}
const CarouselContainer: React.FC<ISliderProps> = ({
  children,
  arrowColorClassName,
  dots = false,
  infinite = true,
  autoplay = false,
  autoplaySpeed = 3000,
  speed = 500,
  centerMode = false,
  variableWidth = false,
  centerPadding = '0px',
  slidesToShow = 1,
  slidesToScroll = 1,
  swipeToSlide = true,
  arrows = true,
  initialSlide = 0,
  prevArrow = <Arrow invert={true} colorClassName={arrowColorClassName} />,
  nextArrow = <Arrow invert={false} colorClassName={arrowColorClassName} />,
  customClassName = 'custom-carousel',
  sliderRef,
  afterChange,
  responsiveBlock = [
    {
      breakpoint: 768,
      settings: {
        dots: true,
        arrows: false,
        infinite: true,
        slidesToShow: 1,
      },
    },
  ],
}) => {
  let slider = React.createRef<Slider>();
  const carouselSettings = {
    dots,
    infinite,
    speed,
    autoplay,
    autoplaySpeed,
    centerMode,
    centerPadding,
    variableWidth,
    slidesToShow,
    slidesToScroll,
    arrows,
    prevArrow,
    nextArrow,
    initialSlide,
    className: customClassName,
    responsive: responsiveBlock,
    swipeToSlide,
  };

  return (
    <Slider {...carouselSettings} ref={sliderRef !== undefined ? sliderRef : slider} afterChange={afterChange}>
      {React.Children.map(children, child => (
        <div>{child}</div>
      ))}
    </Slider>
  );
};

export { CarouselContainer };
