import React from "react";
import { Facebook, Insta, Twitter, Youtube } from "../footer/icons"; // Ensure icons are imported
import { Link } from "../../types";

interface Props {
  link: Link[];
}

const iconMapping: { [key: string]: React.FC } = {
  facebook: Facebook,
  twitter: Twitter,
  youtube: Youtube,
  instagram: Insta,
};

export const SocialButtons: React.FC<Props> = ({ link }) => {
  if (!link || !Array.isArray(link)) {
    return null; 
  }

  const socialIcons = link.find(data => data.slug === "social_media")?.sectionLinks || [];

  return (
    <>
      {socialIcons.map(subLink => {
        const IconComponent = iconMapping[subLink.name];

        return (
          <div
            key={subLink.name}
            className={`w-12 h-12 text-white m-2 border border-blue-darker rounded-full bg-white hover:bg-social-${subLink.name}`}
          >
            <a
             data-testId = "ss"
              href={subLink.itemUrl}
              className="p-2 block text-black hover:text-white"
              target="_blank"
              rel="noopener noreferrer"
            >
              {IconComponent ? <IconComponent /> : null}
            </a>
          </div>
        );
      })}
    </>
  );
};
