import { useEffect } from 'react';
import { DiscoverWebTitle } from '../constants';

export const useDocumentTitle = (pageTitle?: string) => {
  useEffect(() => {
    if (pageTitle) document.title = pageTitle;
    return () => {
      // Change title to default Broadband web page title
      document.title = DiscoverWebTitle;
    };
  }, [pageTitle]);
};
