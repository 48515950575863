import { gql } from "@apollo/client";

export const ComposePage = gql`
  fragment ComposePage on ComposePage {
    name
    title
    slug
    seo
    subMenu {
      sectionName
      slug
      parentMenu {
        name
        itemUrl
      }
      sectionLinks {
        name
        itemUrl
      }
    }
    content {
      name
      content {
        contentType
        bannerName
        bgTitle
        title
        slug
        isExpandable
        subTitle
        terms
        sitemap {
          navigationItem {
            name
            itemUrl
          }
          menuSections {
            sectionName
            slug
            name
            itemUrl
            sectionLinks {
              name
              itemUrl
              sectionName
              slug
              sectionLinks {
                name
                itemUrl
                sectionName
                slug
                sectionLinks {
                  name
                  itemUrl
                }
              }
            }
          }
        }
        articleCollection {
          internalName
          sectionTitle
          searchText
          articleType
          description
          articlesPerPage
        }
        freeformContent {
          content
          heading
          iframeUrl
          iframeTitle
          backgroundGradientColor
          imageUrl {
            url
            text
          }
          subHeading
          alignmentPosition
        }
        logoContentAccordion {
          internalName
          logos {
            url
            text
          }
          heading
          subHeading
          headingBackgroundColor
          content
        }
          cardPanel {
          cardPanelName
          cardPanelHeading
          cardPanelHeadingGradient
          productCategory
          bottomCallToActionLink
          skyCustomerText
          skyCustomerSwitch
          termsAndConditions
          bottomCallToActionText
          cardsToShow {
            title
            type
            occurrenceType
            detailsList
            detailsLink
            productSet
            sku
            titleLogo
            promoted
            thumbnail {
              url
              text
            }
            description
            detailsText
            ctaName
            lozengeText
            termsAndConditionsCaption
            price
            priceFrequencyType
            upgradeOfferPrice
            acquistionOfferPrice
            discountText
            iconList {
              url
              text
            }
            addLink
            addLinkText
            iconListCaption
            copyrightText
            bgColor
            
          }
        }
        rollingBanner {
          layoutOptions
          bannerName
          bannerTitle
          iframeUrl {
            url
            text
          }
          iframeTitle
          callToActionText
          callToActionLink
          heading
          lozengeText
          callToActionSecondaryText
          callToActionLinkSecondary
          detailParagraphColumn2
          channelOrSponsorLogos {
            url
            text
          }
          detailParagraph
          copyrightParagraph
          heroImageMobile {
            url
            text
          }
          heroImage {
            url
            text
          }
          brightCoveVideoId
          brightCoveVideoIdMobile
          brightCoveVideoExperienceId
        }
        bannerContent {
          layoutOptions
          bannerName
          bannerTitle
          iframeUrl {
            url
            text
          }
          iframeTitle
          heading
          lozengeText
          detailParagraph
          detailParagraphColumn2
          copyrightParagraph
          callToActionText
          callToActionLink
          callToActionSecondaryText
          callToActionLinkSecondary
          channelOrSponsorLogos {
            url
            text
          }
          detailParagraph
          copyrightParagraph
          heroImage {
            url
            text
          }
          heroImageMobile {
            url
            text
          }
          heroBrandBackground
          channelOrSponsorLogos {
            url
            text
          }
          brightCoveVideoId
          brightCoveVideoIdMobile
          brightCoveVideoExperienceId
        }
        componentCardSectionTitle
        componentCardDisplayTile
        titleTextAlignment
        titleBackgroundGradientColor
        isCarousel
        referenceId
        subTitleTextAlignment
        componentCards {
          contentType
          internalName
          linkDocument {
            url
            text
          }
          titleAlignmentPosition
          subTitleAlignmentPosition
          linkAlignmentPosition
          imageType
          footnoteText
          componentCardCollection {
            internalName
            componentCards {
              linkUrl
              linkText
              backgroundGradientColor
              linkDocument {
                url
                text
              }
              heading
              footnoteText
              titleAlignmentPosition
              subTitleAlignmentPosition
              linkAlignmentPosition
              imageType
              cardImageLinkUrl
              iframeSourceUrl
              cardTitle
              cardSubTitle
              cardDescription
              cardDetailedDescription
              cardImagePosition
              cardDescriptionTextAlignment
              cardDetailedDescriptionAlignment
              cardImageUrl {
                url
                text
              }
              brightCoveVideoId
              brightCoveVideoExperienceId
              links {
                linkUrl
                linkText
                backgroundGradientColor
                alignmentPosition
                linkImage {
                  url
                  text
                }
                linkDocument {
                  url
                  text
                }
              }
            }
          }
          linkUrl
          linkText
          backgroundGradientColor
          brightCoveVideoId
          heading
          cardImageLinkUrl
          brightCoveVideoExperienceId
          cardTitle
          cardSubTitle
          cardDescription
          cardDetailedDescription
          cardImagePosition
          cardDescriptionTextAlignment
          cardDetailedDescriptionAlignment
          cardImageUrl {
            url
            text
          }
          links {
            internalName
            linkText
            linkUrl
            alignmentPosition
            backgroundGradientColor
            linkImage {
              url
              text
            }
            linkDocument {
              url
              text
            }
          }
          isSlider
          popupcards {
            internalName
            popupCardImageURL
            popupCardSubTitle
            popupCardTitle
            popupCardDescription
            popupCardIconURL
            linkUrl
            linkText
            popupCardImageLinkUrl
          }
        }
      }
    }
  }
`;
