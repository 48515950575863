import { ScrollToTop } from "./ScrollToTop";
import App from "./App";
import { AppState, Auth0Provider, User } from "@auth0/auth0-react";
import config from "./store/authStore/auth0.config";
import history from "./utils/history";
import { ACCOUNT_URL } from "./config";

export function Root() {
  function onRedirectCallback(
    appState?: AppState | undefined,
    user?: User | undefined
  ): void {
    history.push(
      appState && appState.returnTo
        ? appState.returnTo
        : window.location.origin ,
        user,
    ); 
  }
 
  return (
    <>
      <Auth0Provider
        domain={config?.domain}
        clientId={config?.clientId}
        authorizationParams={{
          redirect_uri:window.location.origin
        }}
        onRedirectCallback={onRedirectCallback}
      >
        <ScrollToTop />
        <App />
      </Auth0Provider>
    </>
  );
}
