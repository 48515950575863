import React, { useEffect, useState } from "react";
import { CarouselContainer } from "../carousel/CarouselContainer";
import { NextArrow, PrevArrow } from "../carousel/DartCarouselArrow";
import { isTablet } from "../../types";
import { popuparrow } from "../icon";

interface T_Popup {
  popupCardImageURL: string;
  popupCardTitle: string;
  popupCardSubTitle: string;
  popupCardDescription: string;
  popupCardIconURL: string;
  linkText?: string;
  linkUrl?: string;
  popupCardImageLinkUrl?:string;
}

interface IWhatsHotProps {
  popupCards: T_Popup[];
  componentCardSectionTitle: string;
  isCarousel?: boolean;
  isSlider?: boolean;
  titleBackgroundGradientColor?: string;
  titleTextAlignment?: string;
  subTitleTextAlignment?: string;
  componentCardDisplayTile?: string;
  subTitle?:string;
}
interface IWhatsHotCardProps {
  backgroundColorGradient: string;
  popUpCard?: T_Popup;
}

export const PopUpCard = ({
  popupCards,
  componentCardSectionTitle,
  isCarousel,
  isSlider,
  titleBackgroundGradientColor,
  titleTextAlignment,
  subTitleTextAlignment,
  subTitle,
  componentCardDisplayTile,
}: IWhatsHotProps) => {
  //placeholder until the data for the cards is available, then will assign a different color to each card.
  const [isMobile, setIsMobile] = useState(false);
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  function assignBackgroundGradient(index: number) {
    let backgroundColorGradient: string;
    switch (index) {
      case 1:
        backgroundColorGradient = "sky-peach-gradient";
        break;
      case 2:
        backgroundColorGradient = "sky-blue-gradient";
        break;
      case 3:
        backgroundColorGradient = "sky-blue-gradient";
        break;
      case 4:
        backgroundColorGradient = "sky-young-passion-gradient";
        break;
      case 5:
        backgroundColorGradient = "sky-tempting-azure-gradient";
        break;
      case 6:
        backgroundColorGradient = "sky-tempting-azure-gradient";
        break;
      case 7:
        backgroundColorGradient = "sky-peach-gradient";
        break;
      case 8:
        backgroundColorGradient = "sky-blue-gradient";
        break;
      case 9:
        backgroundColorGradient = "sky-blue-gradient";
        break;
      case 10:
        backgroundColorGradient = "sky-young-passion-gradient";
        break;
      case 11:
        backgroundColorGradient = "sky-tempting-azure-gradient";
        break;
      case 12:
        backgroundColorGradient = "sky-tempting-azure-gradient";
        break;
      default:
        backgroundColorGradient = "sky-tempting-azure-gradient";
        break;
    }
    return backgroundColorGradient;
  }
  const slickSettings = {
    adaptiveHeight: true,
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    customClassName:
      "more-choice-for-you-section-carousel more-choice-for-you-section-carousel-slick-dots mb-sky-lg",
  };

  const WhatsHotCard = ({
    backgroundColorGradient,
    popUpCard,
  }: IWhatsHotCardProps) => {
    const [isMobile, setIsMobile] = useState(false);
    useEffect(() => {
      const handleResize = () => {
        setIsMobile(window.innerWidth <= 768);
      };
      handleResize();
      window.addEventListener("resize", handleResize);
      return () => {
        window.removeEventListener("resize", handleResize);
      };
    }, []);
    return (
      <>
        <div
          style={{
            width: (() => {
              const tile = componentCardDisplayTile?.replace(" ", "");
              switch (tile) {
                case "Fourtile":
                  return isMobile ? "100%" : "20.333333%";
                case "Fivetile":
                  return isMobile ? "100%" : "18.333333%";
                case "Sixtile":
                  return isMobile ? "100%" : "13.333333%";
                default:
                  return isMobile ? "100%" : isTablet?"50%" : "33.333333%";
              }
            })(),
          }}
          className="w-full flex-none relative
                              xl:w-4/12 h-auto
                              md:w-6/12 md:flex-grow md:overflow-hidden overflow-hidden group popupcardMaxheight"
        >
          {/* BB: popup right arrow                     */}
          {isSlider && (
            <div className="absolute top-0 right-0 m-4">
              {/* <img src="https://www.sky.co.nz/o/sky-theme/images/arrow/arrow-next.svg" /> */}
              {popuparrow}
            </div>
          )}
          <div className="object-cover w-full h-full">
            <a
              href={popUpCard?.popupCardImageLinkUrl}
              onClick={(e) => {
                e.preventDefault();
                const link = popUpCard?.popupCardImageLinkUrl;
                if (
                  link &&
                  (link.startsWith("http://") ||
                    link.startsWith("https://") ||
                    link.includes("."))
                ) {
                  window.open(link, "_blank", "noopener,noreferrer");
                } else if (link) {
                  window.location.href = link;
                }
              }}
            >
              {" "}
              <img
                className="w-full h-full block"
                src={popUpCard?.popupCardImageURL}
                alt=""
              />
            </a>
          </div>
          {isSlider && (
            <Silder
              popUpCard={popUpCard}
              backgroundColorGradient={backgroundColorGradient}
            />
          )}
        </div>
      </>
    );
  };

  const Silder = ({
    popUpCard,
    backgroundColorGradient,
  }: IWhatsHotCardProps) => {
    return (
      <>
        <div
          className={`absolute top-0 left-0 h-full w-full 
                      overflow-hidden ${backgroundColorGradient}
                      transform -translate-x-full group-hover:translate-x-0
                      transition duration-500 flex flex-col items-start`}
        >
          <div className="sky-text-white flex flex-col pt-4 px-4 overflow-auto h-85">
            <h3 style= {{fontSize:"18px"}} className="sky-h3-bold sm:sky-h5-bold md:sky-h5-bold">
              {popUpCard?.popupCardTitle}
            </h3>
            <h4 style= {{fontSize:"16px"}} className="sky-h4-reg sm:sky-h6 md:sky-h6">
              {popUpCard?.popupCardSubTitle}
            </h4>
            <div className="mt-7 overflow-auto h-40">
              <p style = {{fontSize:"1.125rem"}} className="sky-h5 sky-text-white">
                {popUpCard?.popupCardDescription}
              </p>
            </div>
          </div>

          {/* BB: linktext for popup  */}
          {popUpCard?.linkText && isMobile === false && isTablet === false && (
            <div
              className="absolute bottom-0 left-0 mr-3 mb-3 sm:hidden md:hidden"
              style={{ paddingLeft: "1rem" }}
            >
              <a
                className="h-auto w-[6rem] sky-h5-bold sm:sky-h7-bold md:sky-h7-bold text-white"
                href={popUpCard?.linkUrl}
                target="_blank"
              >
                {popUpCard?.linkText}
              </a>
            </div>
          )}
          {popUpCard?.popupCardIconURL && isMobile === false && isTablet === false &&(
            <div className="absolute bottom-0 right-0 mr-3 mb-3 sm:hidden md:hidden">
              <img
                className="h-auto w-[6rem]"
                src={popUpCard?.popupCardIconURL}
                alt="channel logo"
              />
            </div>
          )}
          {/* {isMobile ===true && ( */}
            <div
              className="flex flex-row "
              style={{ marginBottom: "3rem", paddingLeft: "1rem" }}
            >
              {popUpCard?.linkText && (
                <div
                  className=" absolute bottom-0 left-0 mr-3 mb-3 sky-h5 md:sky-h7 sky-text-white md:sky-h5 md:sky-h7 md:sky-text-white"
                  style={{ paddingLeft: "1rem" }}
                >
                  <a href={popUpCard?.linkUrl} target="_blank">
                    {popUpCard?.linkText}
                  </a>
                </div>
              )}
              {popUpCard?.popupCardIconURL && (
                <div className="absolute bottom-0 right-0 mr-3 mb-3  md:right-3 md:mb-3">
                  <img
                    className="h-auto w-[4rem] md:w-[6rem]"
                    src={popUpCard?.popupCardIconURL}
                    alt="channel logo"
                  />
                </div>
              )}
            </div>
          {/* // )} */}

          {/* {isTablet === true && (
            <div
              className="flex flex-row "
              style={{ marginBottom: "3rem", paddingLeft: "1rem" }}
            >
              {popUpCard?.linkText && (
                <div className=" absolute sky-h5 md:sky-h7 sky-text-white">
                  <a href={popUpCard?.linkUrl} target="_blank">
                    {popUpCard?.linkText}
                  </a>
                </div>
              )}
              {popUpCard?.popupCardIconURL && (
                <div className="absolute right-3 mb-3">
                  <img
                    className="h-auto w-[6rem]"
                    src={popUpCard?.popupCardIconURL}
                    alt="channel logo"
                  />
                </div>
              )}
            </div>
          )} */}
        </div>
      </>
    );
  };
  return (
    <>
      {componentCardSectionTitle && (
        <h1
          className={
            titleBackgroundGradientColor === "None" ||
            titleBackgroundGradientColor === null
              ? "sky-h4 sm:sky-h4 md:sky-h4 custom-card-font-bold mt-3  text-4xl p-2"
              : "sky-h4 sm:sky-h4 md:sky-h4 custom-card-font-bold mt-3  text-4xl p-2 sky-blue-gradient"
          }
          style={{
            color:
              titleBackgroundGradientColor === "None" ||
              titleBackgroundGradientColor === null
                ? "#00013A"
                : "white",
            fontFamily: "MarkPro-Black SKY",
            // marginBottom: "3rem",
            padding:
              titleBackgroundGradientColor === "None" ||
              titleBackgroundGradientColor === null
                ? ""
                : "3.5rem",
            // marginTop: " -0.25rem",//BB:commented for heading having top and bottom spaces
            marginBottom:
              titleBackgroundGradientColor === "None" ||
              titleBackgroundGradientColor === null
                ? "2rem"
                : "2rem",
            marginTop: "2rem",
            marginLeft: isMobile
              ? ""
              : titleBackgroundGradientColor === "None" ||
                titleBackgroundGradientColor === null
              ? titleTextAlignment ?? "left" === "left"
                ? "4rem"
                : ""
              : "",
            fontSize: isMobile ? "1.825rem" : "48px",
            textAlign:
              (titleTextAlignment as "left" | "right" | "center" | "justify") ||
              "left",
          }}
          dangerouslySetInnerHTML={{
            __html: componentCardSectionTitle as string,
          }}
        ></h1>
      )}

      {subTitle && (
        <div
          className="py-4"
          style={{
            color: "#000000",
            marginLeft:
              subTitleTextAlignment ?? "left" === "left"
                ? isMobile
                  ? "0.5rem"
                  : "5rem"
                : "",
            fontSize: "1.3rem",
            fontWeight: "600",
            textAlign:
              (subTitleTextAlignment as
                | "left"
                | "right"
                | "center"
                | "justify") || "left",
          }}
          dangerouslySetInnerHTML={{
            __html: subTitle as string,
          }}
        ></div>
      )}
      {isCarousel ? (
        <div className="pb-8 lg:mx-20 md:mx-5 mx-4">
          <CarouselContainer
            {...{
              // ...slickSettings,
              adaptiveHeight: true,
              slidesToShow: 3,
              slidesToScroll: 3,
              arrows: true,
              dots: false,
              infinite: true,
              speed: 250,
              prevArrow: (
                <div>
                  <PrevArrow className={`relative left-0-5 -top-3`} />
                </div>
              ),
              nextArrow: (
                <div>
                  <NextArrow className={`relative right-1 -top-3`} />
                </div>
              ),
              responsiveBlock: [
                {
                  breakpoint: 750,
                  settings: {
                    dots: true,
                    arrows: true,
                    infinite: true,
                    slidesToShow: 1,
                    swipeToSlide: true,
                    centerMode: true,
                    centerPadding: "2px",
                  },
                },
                {
                  breakpoint: 1024,
                  settings: {
                    dots: true,
                    arrows: true,
                    infinite: true,
                    swipeToSlide: true,
                    slidesToShow: 2,
                    prevArrow: (
                      <div>
                        <PrevArrow className={`relative -left-4 -top-3`} />
                      </div>
                    ),
                    nextArrow: (
                      <div>
                        <NextArrow className={`relative right-1 -top-3`} />
                      </div>
                    ),
                  },
                },
              ],
            }}
          >
            {popupCards?.map((popupCard, i) => (
              <div className="w-full flex-none relative md:flex-grow md:overflow-hidden overflow-hidden group">
                <div className="object-cover w-full h-full">
                  <a
                    href={popupCard?.popupCardImageLinkUrl}
                    onClick={(e) => {
                      e.preventDefault();
                      const link = popupCard?.popupCardImageLinkUrl;
                      if (
                        link &&
                        (link.startsWith("http://") ||
                          link.startsWith("https://") ||
                          link.includes("."))
                      ) {
                        window.open(link, "_blank", "noopener,noreferrer");
                      } else if (link) {
                        window.location.href = link;
                      }
                    }}
                  >
                    {" "}
                    <img
                      className="w-full h-full block"
                      src={popupCard?.popupCardImageURL}
                      alt=""
                    />
                  </a>
                </div>
                {isSlider && (
                  <Silder
                    key={i}
                    popUpCard={popupCard}
                    backgroundColorGradient={assignBackgroundGradient(i)}
                  />
                )}
                <div
                  className="sky-h5 md:sky-h7 mt-2 text-center"
                  style={{ color: "#00013A" }}
                >
                  <h1 style={{ fontFamily: "MarkPro-Black SKY" }}>
                    {popupCard?.popupCardTitle}
                  </h1>
                </div>
                {popupCard?.linkText && popupCard?.linkUrl && (
                  <div className="sky-h5 md:sky-h7 text-center sky-text-daylight border-sky-secondaryBlue underline ">
                    <a href={popupCard?.linkUrl} target="_blank">
                      {popupCard?.linkText}
                    </a>
                  </div>
                )}
              </div>
            ))}
          </CarouselContainer>
        </div>
      ) : (
        <div
          className="flex flex-col pb-8  flex-no-wrap my-0 lg:mx-20 md:mx-5 mx-4 sm:flex-wrap sm:flex-row sm:overflow-hidden"
          style={{ marginLeft: "2rem", marginRight: "2rem" }}
        >
          {popupCards?.map((item, i) => (
            <WhatsHotCard
              key={i}
              popUpCard={item}
              backgroundColorGradient={assignBackgroundGradient(i)}
            />
          ))}
        </div>
      )}
    </>
  );
};
