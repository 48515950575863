import React, { useEffect, useState } from "react";
import { DocumentToReactComponents } from "./DocumentToReactComponents";
import { VideoSrcScript } from "./VideoSrcScript";
import { LinksCollections } from "./LinkCardCollections";
import { HORIZONTAL_VIDEO_PLAYER_URL } from "../../config";
import { isTablet } from "../../types";

interface ComponentCardSection {
  imageType: any;
  links: any;
  subTitleAlignmentPosition: string;
  linkAlignmentPosition: string | undefined;
  titleAlignmentPosition: string;
  linkDocument: {
    url?: string;
    text?: string;
  };
  heading?: string;
  cardImageUrl?: {
    url?: string;
    text?: string;
  };
  cardImagePosition?: string;
  cardImageLinkUrl?: string;
  cardTitle?: string;
  cardSubTitle?: string;
  cardDescription?: string;
  cardDescriptionTextAlignment?: string;
  cardDetailedDescription?: JSON;
  cardDetailedDescriptionAlignment?: string;
  brightCoveVideoId?: string;
  brightCoveVideoExperienceId?: string;
  linkText?: string;
  linkUrl?: string;
  footnoteText?:string;
}
interface ComponentCardProps {
  componentCardSectionTitle?: string;
  componentCards: ComponentCardSection;
  componentCardDisplayTile?: string;
  titleBackgroundGradientColor?: string;
  subTitleTextAlignment?: string | undefined | null;
  titleTextAlignment?: string | undefined | null;
  subTitle?: string;
  referenceId?:string;
}
const ComponentCardsComp = ({
  componentCards,
  subTitleTextAlignment,
  subTitle,
  referenceId
}: ComponentCardProps) => {
  let displayLayout;
  const [isMobile, setIsMobile] = useState(false);
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  switch (componentCards?.cardImagePosition) {
    case "Center":
      displayLayout = "";
      break;
    case "Right":
      displayLayout = "lg:grid lg:grid-cols-2";
      break;
    case "Left":
      displayLayout = "lg:grid lg:grid-cols-2";
      break;
    default:
      displayLayout = "";
      break;
  }

  let displayTextAlign;
  switch (
    componentCards?.cardDetailedDescriptionAlignment
  ) {
    case "Center":
      displayTextAlign = "flex justify-center";
      break;
    case "Right":
      displayTextAlign = "text-right";
      break;
    case "Left":
      displayTextAlign = "text-left";
      break;
    default:
      displayTextAlign = "text-left";
      break;
  }
  return (
    <>
      <div
        className={`card overflow-hidden ${displayLayout} gap-4 shadow-none pb-4`}
        style={{
          borderRadius: 0,
          padding:
            isMobile || isTablet
              ? ""
              : componentCards?.cardImagePosition === "Right" ||
                componentCards?.cardImagePosition === "Left" ||
                componentCards?.cardImagePosition === "Center"
              ? ""
              : "0 13.5%",
        }}
        id={referenceId}
      >
        {componentCards?.heading && (
          <div
            className="sky-h3 md:sky-h3-bold sky-text-midnight"
            style={{ paddingBottom: "2rem", textAlign: "center" }}
            dangerouslySetInnerHTML={{
              __html: componentCards?.heading as string,
            }}
          ></div>
        )}

        <div
          className={`${
            componentCards?.cardImagePosition === "Right"
              ? "order-last"
              : "order-first"
          }`}
        >
          {componentCards?.brightCoveVideoId && (
            <div
              style={{
                // paddingLeft: isTablet ? "1rem" : isMobile ? "0.5rem" : "1rem",
                display: "flex",
                justifyContent: "center",
                paddingBottom: isMobile ? "2rem" : "",
                marginTop:"3rem"
              }}
            >
              <iframe
                title={componentCards?.cardTitle}
                //title="Watch House of the Dragon Season 2 Official Trailer"
                // src ={'https://players.brightcove.net/6122285394001/experience_63211edca1522b0025cf4d1d/index.html?videoId=6331602517112'}
                src={
                  HORIZONTAL_VIDEO_PLAYER_URL +
                  componentCards?.brightCoveVideoId
                }
                allow="encrypted-media"
                className={
                  componentCards?.cardImagePosition === "Left"
                    ? "md:w-[41rem] sm:w-[20rem] lg:w-[39rem] md:h-[23rem] sm:h-[11rem] lg:h-[22rem]"
                    : "md:w-[41rem] sm:w-[20rem] lg:w-[60rem] md:h-[23rem] sm:h-[11rem] lg:h-[34rem]"
                }
              ></iframe>
            </div>
          )}
          {componentCards?.cardImageUrl?.url && (
            <a
              href={componentCards?.cardImageLinkUrl}
              onClick={(e) => {
                e.preventDefault();
                const link = componentCards?.cardImageLinkUrl;
                if (
                  link &&
                  (link.startsWith("http://") ||
                    link.startsWith("https://") ||
                    link.includes("."))
                ) {
                  window.open(link, "_blank", "noopener,noreferrer");
                } else if (link) {
                  window.location.href = link;
                }
              }}
            >
              <img
                className={`${
                  (componentCards?.cardImagePosition === "None" ||
                    componentCards?.cardImagePosition === "Center") &&
                  !isMobile
                    ? "imageWidth"
                    : ""
                }`}
                style={{
                  marginRight: "auto",
                  marginLeft: "auto",
                  paddingTop: "2rem",
                  width: (() => {
                    const { imageType, cardImagePosition } =
                      componentCards || {};
                    // if (imageType && (cardImagePosition === "None" || cardImagePosition === "Center") && !isMobile) {
                    switch (imageType) {
                      case "Card Image":
                        return "100%";
                      case "Logo":
                        return isMobile ? "60%" : "28%";
                      case "Icon":
                        return isMobile ? "62%" : "28%";
                      default:
                        return "28%";
                    }
                    // }
                    return ""; // Return an empty string if none of the conditions match
                  })(),
                }}
                src={componentCards?.cardImageUrl?.url as string}
              />{" "}
            </a>
          )}{" "}
        </div>

        <div className={componentCards?.cardImagePosition === "Left" ?"pl-[2rem] mt-2":"mt-2 py-3"}>
          {componentCards?.cardTitle && (
            <div
              className={`"${displayTextAlign} sky-h4 sm:sky-h4 md:sky-h4"`}
              style={{
                color: componentCards?.cardImagePosition === "Center" && componentCards?.cardImageUrl?.url ? "#00013A": "#000000",
                fontSize: isMobile ? "2rem" : "48px",
                fontWeight: "bold",
                lineHeight: "1.2",
                textAlign:
                  (componentCards?.titleAlignmentPosition as
                    | "left"
                    | "right"
                    | "center"
                    | "justify") || "left",
              }}
              dangerouslySetInnerHTML={{
                __html: componentCards?.cardTitle as string,
              }}
            ></div>
          )}

          {componentCards?.cardSubTitle && (
            <div
              className="sky-h6 sm:sky-h6 md:sky-h6 py-4"
              style={{
                color: "#000000",
                fontSize: "1rem",
                fontWeight: "bold",
                textAlign:
                  (componentCards?.subTitleAlignmentPosition as
                    | "left"
                    | "right"
                    | "center"
                    | "justify") || "left",
              }}
              dangerouslySetInnerHTML={{
                __html: componentCards?.cardSubTitle as string,
              }}
            >
              {/* <div>{componentCards?.cardSubTitle}</div> */}
            </div>
          )}
          <div>
            {componentCards?.cardDescription && (
              <div
                className={`sky-h6 md:sky-h7 ${
                  componentCards?.cardImageUrl?.url
                    ? "font-semibold"
                    : displayTextAlign
                }`}
                style={{ textAlign: "center" }}
              >
                <p>{componentCards?.cardDescription}</p>
              </div>
            )}
            {componentCards?.cardDetailedDescription && (
              <div
                // 18/10/2024 me and sowmya changed
                className={`${(() => {
                  let displayTextAlign = "";

                  switch (componentCards?.cardDetailedDescriptionAlignment) {
                    case "Center":
                      displayTextAlign = "text-center";
                      break;
                    case "Right":
                      displayTextAlign = "text-right";
                      break;
                    case "Left":
                      displayTextAlign = "text-left";
                      break;
                    default:
                      displayTextAlign = "text-left";
                      break;
                  }

                  return displayTextAlign;
                })()}`}

                style={{ color: componentCards?.cardImagePosition === "Center" && componentCards?.cardImageUrl?.url ? "#00013A": "#000000",}}
              >
                <DocumentToReactComponents
                  richtextEditorProps={
                    componentCards?.cardDetailedDescription as any
                  }
                />
              </div>
            )}

            {componentCards?.links &&
              componentCards?.links?.map((linkData: any, i: any) => (
                <div
                  key={i}
                  className={
                    linkData?.linkText
                      ? "link-item text-center text-blue-400"
                      : ""
                  }
                  style={{
                    textAlign:
                      (linkData?.alignmentPosition as
                        | "left"
                        | "right"
                        | "center"
                        | "justify") || "left",
                    paddingTop: "4rem",
                    paddingBottom: "1rem",
                  }}
                >
                  <a
                    href={linkData?.linkUrl || linkData?.linkDocument?.url}
                    onClick={(e) => {
                      e.preventDefault();
                      if (
                        (linkData?.linkDocument?.url &&
                          (linkData?.linkDocument?.url.startsWith("http://") ||
                            linkData?.linkDocument?.url.startsWith(
                              "https://"
                            ) ||
                            linkData?.linkDocument?.url.includes("."))) ||
                        (linkData?.linkUrl &&
                          (linkData?.linkUrl.startsWith("http://") ||
                            linkData?.linkUrl.startsWith("https://") ||
                            linkData?.linkUrl.includes(".")))
                      ) {
                        window.open(
                          linkData?.linkUrl || linkData?.linkDocument?.url,
                          "_blank",
                          "noopener,noreferrer"
                        );
                      } else {
                        window.location.href =
                          linkData?.linkUrl || linkData?.linkDocument?.url;
                      }
                    }}
                    className={
                      linkData?.linkText
                        ? linkData?.backgroundGradientColor === "Blue"
                          ? "text-lg font-bold px-4 sky-bg-daylight text-white py-4 rounded-full"
                          : "sky-button sky-button--secondary-light btn btn-nm sky-h7 font-bold py-4 px-10"
                        : ""
                    }
                    style={{
                      cursor: "pointer",
                    }}
                  >
                    {linkData?.linkText
                      ? linkData.linkText
                      : linkData?.linkImage?.url && (
                          <img
                            style={{ width: "35%" }}
                            src={linkData.linkImage.url}
                            alt="Link preview"
                          />
                        )}
                  </a>
                </div>
              ))}
          </div>
          {/* {componentCards?.linkUrl  && (
            <div className="mt-4 text-center">
              <a
                href={componentCards?.linkUrl}
                className="text-lg font-bold sky-bg-daylight text-white  px-4 py-4 rounded-full">
                {componentCards?.linkText}
              </a>
            </div>
          )} */}
          {componentCards?.linkUrl === null &&
            componentCards?.links === null &&
            componentCards?.linkDocument &&
            componentCards?.linkText &&
            componentCards?.linkDocument?.url && (
              <div
                className="mt-4"
                style={{
                  marginTop: "1rem",
                  textAlign:
                    (componentCards?.linkAlignmentPosition as
                      | "left"
                      | "right"
                      | "center"
                      | "justify") || "left",
                }}
              >
                <a
                  href={componentCards?.linkDocument?.url}
                  onClick={(e) => {
                    e.preventDefault();
                    window.open(componentCards?.linkDocument?.url);
                  }}
                  className={`"text-lg font-bold sky-text-daylight text-blue px-4 py-4 "`}
                  style={{ fontSize: "18px" }}
                >
                  {componentCards?.linkText}
                </a>
              </div>
            )}
          {componentCards?.linkUrl && componentCards?.links === null && (
            <div
              className="mt-4"
              style={{
                marginTop: "1rem",
                textAlign:
                  (componentCards?.linkAlignmentPosition as
                    | "left"
                    | "right"
                    | "center"
                    | "justify") || "left",
              }}
            >
              <a
                href={componentCards?.linkUrl}
                onClick={(e) => {
                  e.preventDefault();
                  window.open(componentCards?.linkUrl);
                }}
                className="text-lg font-bold sky-text-daylight text-blue py-4 "
                style={{ fontSize: "18px" }}
              >
                {componentCards?.linkText}
              </a>
            </div>
          )}
          {componentCards?.footnoteText && (
                <div style={{textAlign:"center"}}>
                  <DocumentToReactComponents
                    richtextEditorProps={
                      componentCards?.footnoteText as any
                    }
                  />
                </div>
              )}
        </div>
      </div>
    </>
  );
};

export const ComponentCard = ({
  componentCardSectionTitle,
  componentCards,
  componentCardDisplayTile,
  titleBackgroundGradientColor,
  subTitleTextAlignment,
  titleTextAlignment,
  subTitle,
  referenceId
}: ComponentCardProps) => {
  const [isMobile, setIsMobile] = useState(false);
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  return (
    <>
      {componentCardSectionTitle && (
        <h1
          className={
            titleBackgroundGradientColor === "None" ||
            titleBackgroundGradientColor === null
              ? "sky-h4 sm:sky-h4 md:sky-h4 custom-card-font-bold mt-3 text-4xl p-2"
              : "sky-h4 sm:sky-h4 md:sky-h4 custom-card-font-bold mt-3  text-4xl p-2 sky-blue-gradient"
          }
          style={{
            color:
              titleBackgroundGradientColor === "None" ||
              titleBackgroundGradientColor === null
                ? "#00013A"
                : "white",
            fontFamily: "MarkPro-Black SKY",
            marginBottom:
              titleBackgroundGradientColor === "None" ||
              titleBackgroundGradientColor === null
                ? ""
                : "3rem",
            padding:
              titleBackgroundGradientColor === "None" ||
              titleBackgroundGradientColor === null
                ? ""
                : "3.5rem",
            marginTop: " -0.25rem",
            fontSize: isMobile ? "1.825rem" : "48px",
            textAlign:
              (titleTextAlignment as "left" | "right" | "center" | "justify") ||
              "center",
          }}
          dangerouslySetInnerHTML={{
            __html: componentCardSectionTitle as string,
          }}
        ></h1>
      )}
       {subTitle && (
            <div
              className="py-4"
              style={{
                color: "#000000",
                marginLeft:
                  subTitleTextAlignment ?? "left" === "left"
                    ? isMobile
                      ? "0.5rem"
                      : "5rem"
                    : "",
                fontSize: "1.3rem",
                fontWeight:"600",
                textAlign:
                  (subTitleTextAlignment as
                    | "left"
                    | "right"
                    | "center"
                    | "justify") || "left",
              }}
              dangerouslySetInnerHTML={{
                __html: subTitle as string,
              }}
            ></div>
          )}
      <div
        className={`pb-4 ${
          componentCards?.cardImageUrl?.url
            ? (componentCards?.cardImagePosition === "None" ||
                componentCards?.cardImagePosition === "Center") &&
              componentCardDisplayTile === "Single tile"
              ? "lg:max-w-full w-11/12 d-block mx-auto"
              : componentCards?.cardImagePosition === "None" ||
                componentCards?.cardImagePosition === "Center"
              ? "lg:w-1/2 w-11/12 d-block mx-auto"
              : "lg:mx-20 md:mx-5 mx-4 "
            : "lg:mx-20 md:mx-5 mx-4"
        }`}
      >
        <>
          <ComponentCardsComp
            subTitleTextAlignment={subTitleTextAlignment}
            subTitle={subTitle}
            componentCards={componentCards}
            referenceId = {referenceId}
          />
        </>
      </div>

      {componentCards?.brightCoveVideoExperienceId && (
        <div
          data-experience={componentCards?.brightCoveVideoExperienceId}
          style={{ overflow: "auto" }}
        >
          <VideoSrcScript
            brightCoveVideoExperienceId={
              componentCards?.brightCoveVideoExperienceId
            }
          />
        </div>
      )}
    </>
  );
};

export default ComponentCard;
