import React, { useEffect, useRef } from 'react';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { VideoDisplay } from './VideoDisplay';
import { HalfWidthImage } from './HalfWidthImage';
import { BannerContent } from './WebContentSingleBanner';

export interface videoContent {
  bannerVideoContent?: BannerContentVideoText;
  heroBrandBackground?:string
}
export interface BannerContentVideoText {
  bannerTitle: string;
  bannerName?: string;
  heading?: Document;
  detailParagraph?: Document;
  brightCoveVideoId?: string;
  brightCoveVideoIdMobile?: string;
  heroImage?: Image;
  heroImageMobile?: Image;
  heroBrandBackground?: string;
  channelOrSponsorLogos?: Image;
  callToActionText?: string;
  callToActionLink?: string;
  callToActionSecondaryText?: string;
  callToActionLinkSecondary?: string;
  layoutOptions?: string | null | undefined ;
}
interface Image {
  url?: string;
  text?: string;
}

export const WebContentVideoText = ({ bannerVideoContent,heroBrandBackground }: videoContent) => {
  let gradient: string;
    switch (bannerVideoContent?.heroBrandBackground || heroBrandBackground) {
        case '30/60Pink-DarkBlue': gradient = "sky-banner-background";
            break;
        default: gradient = 'sky-header-gradient';
            break;
    }
  return (
    <>
      {bannerVideoContent?.brightCoveVideoId !== null ? (
        <div className="mt-2 md:flex flex-row justify-center w-full bg-center bg-cover">
          <div
            className={`${
              bannerVideoContent?.layoutOptions === "VideoOnly" ? "" : gradient
            } flex flex-col  justify-center w-full`}
          >
            {/* {bannerVideoContent?.bannerName  && <div className="flex mt-5 w-full mb-5 p-2 w-full text-center">
              <h3
                className="sky-h3-bold justify-center text-center w-full md:sky-h3-bold justify-center text-center w-full"
                style={{ color: '#fff' }}>
                {bannerVideoContent?.brightCoveVideoId !== null && bannerVideoContent?.layoutOptions !== 'SkyBackground-HalfWidth' ? <div>{bannerVideoContent?.bannerName}</div> : null}
              </h3>
            </div>} */}
            {bannerVideoContent?.bannerTitle && (
              <div className="flex mt-5 w-full mb-5 p-2 w-full text-center">
                <h3
                  className="sky-h3-bold justify-center text-center w-full md:sky-h3-bold justify-center text-center w-full"
                  style={{
                    color:
                      bannerVideoContent?.layoutOptions === "VideoOnly"
                        ? ""
                        : "#fff",
                  }}
                >
                  <div>{bannerVideoContent?.bannerTitle}</div>
                </h3>
              </div>
            )}
            {/* <---!content---> */}

            {/* <---Mobile View---> */}
            <div className="flex-col w-full justify-center lg:hidden md:hidden">
              <div className="w-full">
                {bannerVideoContent &&
                bannerVideoContent?.brightCoveVideoIdMobile ? (
                  <VideoDisplay
                    src={bannerVideoContent?.brightCoveVideoIdMobile}
                    width="375"
                    height="210"
                  />
                ) : (
                  <HalfWidthImage src={bannerVideoContent?.heroImage?.url} />
                )}
              </div>
            </div>
            <div
              className="flex flex-col p-5 whitespace-pre-line lg:hidden md:hidden"
              style={{
                color:
                  bannerVideoContent?.layoutOptions === "VideoOnly"
                    ? ""
                    : "#fff",
              }}
            >
              {bannerVideoContent?.brightCoveVideoId !== null &&
                (documentToReactComponents(
                  bannerVideoContent?.heading as any
                ) ||
                  documentToReactComponents(
                    bannerVideoContent?.detailParagraph as any
                  )) && (
                  <div className="w-full">
                    <h4
                      className="sky-h3-bold whitespace-pre-line"
                      style={{ color: "#fff" }}
                    >
                      {bannerVideoContent?.brightCoveVideoId !== null ? (
                        <div>
                          {documentToReactComponents(
                            bannerVideoContent?.heading as any
                          )}
                        </div>
                      ) : null}
                    </h4>
                    <h5 className="sky-h5-reg whitespace-pre-line mt-2">
                      {bannerVideoContent?.brightCoveVideoId !== null ? (
                        <div>
                          {documentToReactComponents(
                            bannerVideoContent?.detailParagraph as any
                          )}
                        </div>
                      ) : null}
                    </h5>
                  </div>
                )}
              <div
                className="flex flex-col mt-2"
                style={{
                  color:
                    bannerVideoContent?.layoutOptions === "VideoOnly"
                      ? ""
                      : "#fff",
                }}
              >
                <div className="flex-row"></div>
                <div>
                  <h5 className="sky-h5" style={{marginBottom:"1.5rem"}}>
                    <div>
                      {bannerVideoContent?.callToActionLink && (
                        <div className="mt-8 w-full">
                          <a
                            href={bannerVideoContent?.callToActionLink}
                            onClick={(e) => {
                              e.preventDefault();
                              const link = bannerVideoContent?.callToActionLink;
                              if (
                                link &&
                                (link.startsWith("http://") ||
                                  link.startsWith("https://") ||
                                  link.includes("."))
                              ) {
                                window.open(link, "_blank", "noopener,noreferrer");
                              } else if (link) {
                                window.location.href = link;
                              }
                            }}
                            className="text-lg font-bold sky-text-white px-4 py-4 rounded-full border solid white bg-transparent hover:bg-white"
                            style={{
                              color: "#ffffff",
                              transition: "color 0.1s ease-in-out",
                            }}
                            onMouseEnter={(e: any) =>
                              (e.target.style.color = "#0b5fff")
                            }
                            onMouseLeave={(e: any) =>
                              (e.target.style.color = "#ffffff")
                            }
                          >
                            {bannerVideoContent?.callToActionText}
                          </a>
                        </div>
                      )}
                    </div>
                  </h5>
                  <div>
                    {bannerVideoContent?.brightCoveVideoId !== null &&
                    bannerVideoContent?.channelOrSponsorLogos?.url ? (
                      <a
                        className="m-auto"
                        target=""
                        href="https://www.skygo.co.nz/"
                      >
                        <img
                          className="w-2/4 mt-2"
                          alt="channel logo"
                          src={bannerVideoContent?.channelOrSponsorLogos?.url}
                        ></img>
                      </a>
                    ) : null}
                  </div>
                </div>
              </div>
            </div>

            {/* <---Desktop view---> */}
            <div className="hidden lg:flex flex-row w-full justify-center p-4">
              <div
                className={`${
                  bannerVideoContent?.layoutOptions === "VideoOnly"
                    ? "lg:grid grid-cols-15 p-5 w-full center"
                    : "lg:grid grid-cols-15 p-5 w-3/5"
                }`}
                style={{
                  marginTop:bannerVideoContent?.layoutOptions ===
                    "BackgroundImagewithVideo" ? "-2.5rem":"",
                  paddingLeft:
                    bannerVideoContent?.layoutOptions === "VideoOnly"
                      ? "18rem"
                      : bannerVideoContent?.layoutOptions ===
                        "BackgroundImagewithVideo"
                      ? "5rem"
                      : "",
                  width:
                    bannerVideoContent?.layoutOptions === "VideoOnly"
                      ? "200%"
                      : bannerVideoContent?.layoutOptions ===
                        "BackgroundImagewithVideo"
                      ? "1000%"
                      : "",
                  height:
                    bannerVideoContent?.layoutOptions ===
                    "BackgroundImagewithVideo"
                      ? "100%"
                      : "",
                }}
              >
                <div className="lg: flex flex-row w-full">
                  {bannerVideoContent &&
                  bannerVideoContent?.brightCoveVideoId ? (
                    <VideoDisplay
                      src={bannerVideoContent?.brightCoveVideoId}
                      width="720"
                      height="275"
                    />
                  ) : (
                    <HalfWidthImage src={bannerVideoContent?.heroImage?.url} />
                  )}
                  
                </div>
                {bannerVideoContent?.layoutOptions ===
                    "BackgroundImagewithVideo" && bannerVideoContent?.callToActionText &&<div style = {{paddingTop:"2rem"}} className="custom-sky-button pb-3 text-center text-break">
                    <div>
                      <a
                        // className="sky-button sky-button--primary-light btn btn-sm"
                        className = "text-lg font-bold px-4 sky-bg-daylight text-white py-4 rounded-full"                
                        href={bannerVideoContent?.callToActionLink}
                        onClick={(e) => {
                          e.preventDefault();
                          const link = bannerVideoContent?.callToActionLink;
                          if (
                            link &&
                            (link.startsWith("http://") ||
                              link.startsWith("https://") ||
                              link.includes("."))
                          ) {
                            window.open(link, "_blank", "noopener,noreferrer");
                          } else if (link) {
                            window.location.href = link;
                          }
                        }}
                        id="fragment-vunu-link"
                        target="_blank"
                        onMouseEnter={(e: any) =>
                          (e.target.style.color = "#ffffff")
                        }
                        onMouseLeave={(e: any) =>
                          (e.target.style.color = "#ffffff")
                        }
                        style={{paddingLeft:"3rem",paddingRight:"3rem"}}
                      >
                        {bannerVideoContent?.callToActionText}
                      </a>
                    </div>
                  </div>}
              </div>
              <div
                className="lg:grid grid-cols-15 p-5 w-1/3 whitespace-pre-line"
                style={{
                  color:
                    bannerVideoContent?.layoutOptions === "VideoOnly"
                      ? ""
                      : "#fff",
                }}
              >
                {bannerVideoContent?.brightCoveVideoId !== null &&
                  (documentToReactComponents(
                    bannerVideoContent?.heading as any
                  ) ||
                    documentToReactComponents(
                      bannerVideoContent?.detailParagraph as any
                    )) && (
                    <div className="w-full">
                      <h4
                        className="lg: sky-h3-bold whitespace-pre-line"
                        style={{ color: "#fff" }}
                      >
                        {bannerVideoContent?.brightCoveVideoId !== null ? (
                          <div>
                            {documentToReactComponents(
                              bannerVideoContent?.heading as any
                            )}
                          </div>
                        ) : null}
                      </h4>
                      <h5 className="sky-h5-reg lg:sky-h3-reg whitespace-pre-line mt-2 overflow-y-auto h-[17rem]">
                        {bannerVideoContent?.brightCoveVideoId !== null ? (
                          <div>
                            {documentToReactComponents(
                              bannerVideoContent?.detailParagraph as any
                            )}
                          </div>
                        ) : null}
                      </h5>
                    </div>
                  )}
                {/* {bannerVideoContent?.channelOrSponsorLogos?.url ||
                  bannerVideoContent?.callToActionLink && ( */}
                <div
                  className="lg:flex flex-col justify-end"
                  style={{ color: "#fff" }}
                >
                  <div className="lg:flex-row"></div>
                  <div>
                    <h5 className="sky-h5 md:sky-h7">
                      <div>
                        {bannerVideoContent?.layoutOptions !=
                    "BackgroundImagewithVideo" &&bannerVideoContent?.callToActionLink && (
                          <div className="mt-40 w-full">
                            <a
                              href={bannerVideoContent?.callToActionLink}
                              onClick={(e) => {
                                e.preventDefault();
                                const link = bannerVideoContent?.callToActionLink;
                                if (
                                  link &&
                                  (link.startsWith("http://") ||
                                    link.startsWith("https://") ||
                                    link.includes("."))
                                ) {
                                  window.open(link, "_blank", "noopener,noreferrer");
                                } else if (link) {
                                  window.location.href = link;
                                }
                              }}
                              className="text-lg font-bold sky-text-white px-4 py-4 rounded-full border solid white bg-transparent hover:bg-white"
                              style={{
                                color: "#ffffff",
                                transition: "color 0.1s ease-in-out",
                              }}
                              onMouseEnter={(e: any) =>
                                (e.target.style.color = "#0b5fff")
                              }
                              onMouseLeave={(e: any) =>
                                (e.target.style.color = "#ffffff")
                              }
                            >
                              {bannerVideoContent?.callToActionText}
                            </a>
                          </div>
                        )}
                      </div>
                    </h5>
                    <div>
                      {bannerVideoContent?.channelOrSponsorLogos?.url && (
                        <a className="m-auto" target="" href="">
                          <img
                            className="w-2/5 mt-10"
                            alt="channel logo"
                            src={bannerVideoContent?.channelOrSponsorLogos?.url}
                          ></img>
                        </a>
                      )}
                    </div>
                  </div>
                </div>
                {/* )} */}
              </div>
            </div>

            {/* <---Tablet view---> */}
            <div className="hidden lg:hidden md:flex flex-col w-full justify-center">
              <div className="md:w-full">
                {bannerVideoContent && bannerVideoContent?.brightCoveVideoId ? (
                  <VideoDisplay
                    src={bannerVideoContent?.brightCoveVideoId}
                    width="520"
                    height="290"
                  />
                ) : (
                  <HalfWidthImage src={bannerVideoContent?.heroImage?.url} />
                )}
              </div>
              <div
                className="md:flex flex-col p-5 whitespace-pre-line "
                style={{
                  color:
                    bannerVideoContent?.layoutOptions === "VideoOnly"
                      ? ""
                      : "#fff",
                }}
              >
                <div className="w-full">
                  <h4
                    className="md: sky-h3-bold whitespace-pre-line"
                    style={{ color: "#fff" }}
                  >
                    {/* {bannerVideoContent?.brightCoveVideoId !== null ? ( */}
                      <div>
                        {documentToReactComponents(
                          bannerVideoContent?.heading as any
                        )}
                      </div>
                    {/* ) : null} */}
                  </h4>
                  <h5 className="sky-h5-reg md:sky-h7-reg whitespace-pre-line mt-2">
                    {/* {bannerVideoContent?.brightCoveVideoId !== null ? ( */}
                      <div>
                        {documentToReactComponents(
                          bannerVideoContent?.detailParagraph as any
                        )}
                      </div>
                    {/* ) : null} */}
                  </h5>
                </div>
                <div
                  className="md:flex flex-col mt-2"
                  style={{
                    color:
                      bannerVideoContent?.layoutOptions === "VideoOnly"
                        ? ""
                        : "#fff",
                  }}
                >
                  <div className="md:flex-row"></div>
                  <div>
                    <h5 className="sky-h5 md:sky-h7" style={{marginBottom:"1.5rem"}}>
                      <div>
                        {bannerVideoContent?.callToActionLink && (
                          <div className="mt-36 w-full">
                            <a
                              href={bannerVideoContent?.callToActionLink}
                              onClick={(e) => {
                                e.preventDefault();
                                const link = bannerVideoContent?.callToActionLink;
                                if (
                                  link &&
                                  (link.startsWith("http://") ||
                                    link.startsWith("https://") ||
                                    link.includes("."))
                                ) {
                                  window.open(link, "_blank", "noopener,noreferrer");
                                } else if (link) {
                                  window.location.href = link;
                                }
                              }}
                              className="text-lg font-bold sky-text-white px-4 py-4 rounded-full border solid white bg-transparent hover:bg-white"
                              style={{
                                color: "#ffffff",
                                transition: "color 0.1s ease-in-out",
                              }}
                              onMouseEnter={(e: any) =>
                                (e.target.style.color = "#0b5fff")
                              }
                              onMouseLeave={(e: any) =>
                                (e.target.style.color = "#ffffff")
                              }
                            >
                              {bannerVideoContent?.callToActionText}
                            </a>
                          </div>
                        )}
                      </div>
                    </h5>
                    {/* <div>
                      {bannerVideoContent?.channelOrSponsorLogos?.url == null ? (
                        <a className="m-auto" target="" href="">
                          <img
                            className="w-2/4 mt-2"
                            alt="channel logo"
                            src={bannerVideoContent?.channelOrSponsorLogos?.url}></img>
                        </a>
                      ) : null}
                    </div> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <>
          <div className="mt-2 md:flex flex-row justify-center w-full bg-center bg-cover">
            <div
              className={`"flex flex-col ${gradient} justify-center w-full"`}
            >
              {/* {bannerVideoContent?.bannerName && <div className="flex mt-5 w-full mb-5 p-2 w-full text-center">
                <h3
                  className="sky-h3-bold justify-center text-center w-full md:sky-h3-bold justify-center text-center w-full"
                  style={{ color: '#fff' }}>
                  {bannerVideoContent?.heroImage !== null ? <div>{bannerVideoContent?.bannerName}</div> : null}
                </h3>
              </div>} */}

              {bannerVideoContent?.bannerTitle && (
                <div className="flex mt-5 w-full mb-5 p-2 w-full text-center">
                  <h3
                    className="sky-h3-bold justify-center text-center w-full md:sky-h3-bold justify-center text-center w-full"
                    style={{ color: "#fff" }}
                  >
                    <div>{bannerVideoContent?.bannerTitle}</div>
                  </h3>
                </div>
              )}
              {/* content */}

              {/* <---Mobile view---> */}
              <div className="flex-col w-full justify-center lg:hidden md:hidden">
                <div className="w-full">
                  {bannerVideoContent &&
                  bannerVideoContent?.brightCoveVideoIdMobile ? (
                    <VideoDisplay
                      src={bannerVideoContent?.brightCoveVideoIdMobile}
                      width="375"
                      height="210"
                    />
                  ) : (
                    <HalfWidthImage src={bannerVideoContent?.heroImage?.url} />
                  )}
                </div>
              </div>
              <div
                className="flex flex-col p-5 whitespace-pre-line lg:hidden md:hidden"
                style={{ color: "#fff" }}
              >
                <div className="w-full">
                  <h4
                    className="sky-h3-bold whitespace-pre-line"
                    style={{ color: "#fff" }}
                  >
                    <div>
                      {documentToReactComponents(
                        bannerVideoContent?.heading as any
                      )}
                    </div>
                  </h4>
                  <h5 className="sky-h5-reg whitespace-pre-line mt-2">
                    <div>
                      {documentToReactComponents(
                        bannerVideoContent?.detailParagraph as any
                      )}
                    </div>
                  </h5>
                </div>
                <div className="flex flex-col mt-2" style={{ color: "#fff" }}>
                  <div className="flex-row"></div>
                  <div>
                    <h5 className="sky-h5 " style={{marginBottom:"1.5rem"}}>
                      <div>
                        {bannerVideoContent?.callToActionLink && (
                          <div className="mt-8 w-full">
                            <a
                              href={bannerVideoContent?.callToActionLink}
                              onClick={(e) => {
                                e.preventDefault();
                                const link = bannerVideoContent?.callToActionLink;
                                if (
                                  link &&
                                  (link.startsWith("http://") ||
                                    link.startsWith("https://") ||
                                    link.includes("."))
                                ) {
                                  window.open(link, "_blank", "noopener,noreferrer");
                                } else if (link) {
                                  window.location.href = link;
                                }
                              }}
                              className="text-lg font-bold sky-text-white px-4 py-4 rounded-full border solid white bg-transparent hover:bg-white"
                              style={{
                                color: "#ffffff",
                                transition: "color 0.1s ease-in-out",
                              }}
                              onMouseEnter={(e: any) =>
                                (e.target.style.color = "#0b5fff")
                              }
                              onMouseLeave={(e: any) =>
                                (e.target.style.color = "#ffffff")
                              }
                            >
                              {bannerVideoContent?.callToActionText}
                            </a>
                          </div>
                        )}
                      </div>
                    </h5>
                    {/* <div>
                      {bannerVideoContent?.channelOrSponsorLogos?.url == null ? (
                        <a className="m-auto" target="" href="https://www.skygo.co.nz/">
                          <img
                            className="w-2/4 mt-2"
                            alt="channel logo"
                            src={bannerVideoContent?.channelOrSponsorLogos?.url}></img>
                        </a>
                      ) : null}
                    </div> */}
                  </div>
                </div>
              </div>

              {/* <---Desktop view---> */}
              <div className="hidden lg:flex flex-row w-full justify-center p-4">
                <div className="lg:grid grid-cols-15 p-5 w-3/5">
                  <div className="lg: flex flex-row w-full">
                    {bannerVideoContent &&
                    bannerVideoContent?.brightCoveVideoId ? (
                      <VideoDisplay
                        src={bannerVideoContent?.brightCoveVideoId}
                        width="720"
                        height="275"
                      />
                    ) : (
                      <HalfWidthImage
                        src={bannerVideoContent?.heroImage?.url}
                      />
                    )}
                  </div>
                </div>
                <div
                  className="lg:grid grid-cols-15 p-5 w-1/3 whitespace-pre-line"
                  style={{ color: "#fff" }}
                >
                  <div className="w-full">
                    <h4
                      className="lg: sky-h3-bold whitespace-pre-line"
                      style={{ color: "#fff" }}
                    >
                      {!bannerVideoContent?.brightCoveVideoId &&
                      bannerVideoContent.heroImage?.url ? (
                        <div>
                          {documentToReactComponents(
                            bannerVideoContent?.heading as any
                          )}
                        </div>
                      ) : null}
                    </h4>
                    <h5 className="sky-h5-reg lg:sky-h3-reg whitespace-pre-line mt-2 overflow-y-auto h-[17rem]">
                      {!bannerVideoContent?.brightCoveVideoId &&
                      bannerVideoContent.heroImage?.url ? (
                        <div>
                          {documentToReactComponents(
                            bannerVideoContent?.detailParagraph as any
                          )}
                        </div>
                      ) : null}
                    </h5>
                  </div>
                  <div
                    className="lg:flex flex-col mt-2"
                    style={{ color: "#fff" }}
                  >
                    <div className="lg:flex-row"></div>
                    <div>
                      <h5 className="sky-h5 md:sky-h7">
                        <div></div>
                      </h5>
                      <div>
                        {bannerVideoContent?.callToActionLink && (
                          <>
                            <div className="mt-40">
                              <a
                                href={bannerVideoContent?.callToActionLink}
                                onClick={(e) => {
                                  e.preventDefault();
                                  const link = bannerVideoContent?.callToActionLink;
                                  if (
                                    link &&
                                    (link.startsWith("http://") ||
                                      link.startsWith("https://") ||
                                      link.includes("."))
                                  ) {
                                    window.open(link, "_blank", "noopener,noreferrer");
                                  } else if (link) {
                                    window.location.href = link;
                                  }
                                }}
                                className="text-lg font-bold sky-bg-daylight text-white  px-4 py-4 rounded-full border solid white bg-transparent hover:bg-white"
                                style={{
                                  color: "#ffffff",
                                  transition: "color 0.1s ease-in-out",
                                }}
                                onMouseEnter={(e: any) =>
                                  (e.target.style.color = "#0b5fff")
                                }
                                onMouseLeave={(e: any) =>
                                  (e.target.style.color = "#ffffff")
                                }
                              >
                                {bannerVideoContent?.callToActionText}
                              </a>
                            </div>
                          </>
                        )}
                        {bannerVideoContent?.channelOrSponsorLogos?.url ? (
                          <a className="m-auto" target="" href="">
                            <img
                              className="w-2/5 mt-10"
                              alt="channel logo"
                              src={
                                bannerVideoContent?.channelOrSponsorLogos?.url
                              }
                            ></img>
                          </a>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/* <---Tablet view---> */}
              <div className="hidden lg:hidden md:flex flex-col w-full justify-center">
                <div className="md:w-full">
                  {bannerVideoContent &&
                  bannerVideoContent?.brightCoveVideoId ? (
                    <VideoDisplay
                      src={bannerVideoContent?.brightCoveVideoId}
                      width="520"
                      height="290"
                    />
                  ) : (
                    <HalfWidthImage src={bannerVideoContent?.heroImage?.url} />
                  )}
                </div>
                <div
                  className="md:flex flex-col p-5 whitespace-pre-line "
                  style={{ color: "#fff" }}
                >
                  <div className="w-full">
                    <h4
                      className="md: sky-h3-bold whitespace-pre-line"
                      style={{ color: "#fff" }}
                    >
                      {/* {bannerVideoContent?.brightCoveVideoId !== null ? ( */}
                        <div>
                          {documentToReactComponents(
                            bannerVideoContent?.heading as any
                          )}
                        </div>
                      {/* ) : null} */}
                    </h4>
                    <h5 className="sky-h5-reg md:sky-h7-reg whitespace-pre-line mt-2">
                      {/* {bannerVideoContent?.brightCoveVideoId !== null ? ( */}
                        <div>
                          {documentToReactComponents(
                            bannerVideoContent?.detailParagraph as any
                          )}
                        </div>
                      {/* ) : null} */}
                    </h5>
                  </div>
                  <div
                    className="md:flex flex-col mt-2"
                    style={{ color: "#fff" }}
                  >
                    <div className="md:flex-row"></div>
                    <div>
                      <h5 className="sky-h5 md:sky-h7">
                        <div>
                          {bannerVideoContent?.callToActionLink && (
                            <div className="mt-36 w-full">
                              <a
                                href={bannerVideoContent?.callToActionLink}
                                onClick={(e) => {
                                  e.preventDefault();
                                  const link = bannerVideoContent?.callToActionLink;
                                  if (
                                    link &&
                                    (link.startsWith("http://") ||
                                      link.startsWith("https://") ||
                                      link.includes("."))
                                  ) {
                                    window.open(link, "_blank", "noopener,noreferrer");
                                  } else if (link) {
                                    window.location.href = link;
                                  }
                                }}
                                className="text-lg font-bold sky-text-white px-4 py-4 rounded-full border solid white bg-transparent hover:bg-white"
                                style={{
                                  color: "#ffffff",
                                  transition: "color 0.1s ease-in-out",
                                }}
                                onMouseEnter={(e: any) =>
                                  (e.target.style.color = "#0b5fff")
                                }
                                onMouseLeave={(e: any) =>
                                  (e.target.style.color = "#ffffff")
                                }
                              >
                                {bannerVideoContent?.callToActionText}
                              </a>
                            </div>
                          )}
                        </div>
                      </h5>
                      {/* <div>
                        {bannerVideoContent?.channelOrSponsorLogos?.url == null ? (
                          <a className="m-auto" target="" href="">
                            <img
                              className="w-2/4 mt-2"
                              alt="channel logo"
                              src={bannerVideoContent?.channelOrSponsorLogos?.url}></img>
                          </a>
                        ) : null}
                      </div> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default WebContentVideoText;
