import React, { useEffect, useState } from "react";
import { GetArticlePage } from "../../graphql/queries/getArticlePage";
import { useQuery } from "@apollo/client";
import TableWithoutHeaders from "../../components/composePage/TableWithoutHeaders";
import { DocumentToReactComponents } from "./DocumentToReactComponents";
import { useLocation } from "react-router-dom";

const ArticleCollection = (articleCollection: any) => {
  const { data: articlePageQuery } = useQuery<any>(GetArticlePage, {
    variables: {
      usePreview: false,
      articleType: articleCollection?.articleCollection?.articleType,
      selectCategories: articleCollection?.articleCollection?.searchText,
    },
    fetchPolicy: "cache-and-network",
  });

  const { pathname } = useLocation();

  const slug = pathname.replace("/", "");
  return (
    <>
      {articleCollection?.articleCollection &&
      articleCollection?.articleCollection?.sectionTitle ? (
        <div
          className="px-4 py-18 sky-blue-gradient text-center text-white"
          style={{ paddingTop: "2.5rem", paddingBottom: "3.5rem" }}
        >
          <h1
            className="sky-h4 sm:sky-h4 md:sky-h4 custom-card-font-bold mt-3 text-center text-5xl p-2"
            style={{ fontFamily: "MarkPro-Black SKY" }}
            dangerouslySetInnerHTML={{
              __html: articleCollection?.articleCollection?.sectionTitle,
            }}
          ></h1>
        </div>
      ) : (
        ""
      )}

      {slug === "media-centre" && articleCollection?.articleCollection &&
      articleCollection?.articleCollection?.description ? (
        <div className="text-center text-5xl" style={{ paddingTop: "1rem" }}>
          <DocumentToReactComponents
            richtextEditorProps={
              articleCollection?.articleCollection?.description as any
            }
          />
        </div>
      ) : (
        ""
      )}

      {articlePageQuery?.articlePage ? (
        <TableWithoutHeaders data={articlePageQuery?.articlePage} description = {articleCollection?.articleCollection?.description} searchText = {articleCollection?.articleCollection?.searchText} articlesPerPage = {articleCollection?.articleCollection?.articlesPerPage} />
      ) : (
        []
      )}
    </>
  );
};
export default ArticleCollection;
