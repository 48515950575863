import React from 'react';
import { DartNextArrow, DartPrevArrow } from '../icon';
import { isMobile } from '../../types';

interface Props {
  className?: any;
  onClick?: (e: any) => void;
}

export const NextArrow: React.FC<Props> = ({ className, onClick }: Props) => {
  return (
    <>
      <button className={className} onClick={onClick} style={{display:isMobile?"none":""}}>
        <div className={'w-12 h-12 z-100 block'}>{<DartNextArrow />}</div>
      </button>
    </>
  );
};

export const PrevArrow: React.FC<Props> = ({ className, onClick }: Props) => {
  return (
    <>
      <button className={className} onClick={onClick} style={{display:isMobile?"none":""}}>
        <div className={'w-12 h-12 z-100 block'}>{<DartPrevArrow />}</div>
      </button>
    </>
  );
};
