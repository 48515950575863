import { useQuery } from "@apollo/client";
import React, { useEffect, useState } from "react";
import { getComposePageQuery } from "../graphql/queries/getComposePage";
import { ComposePageContentType } from "../types";
import {
  ComposeMenuItemsType,
  MenuItemsType,
  ComposePageSubmenu,
} from "../components/composePage/ComposePageSubMenu";
import {
  WebContentSingleBanner,
  LogosOrContentAccordion,
  ComponentCard,
  ComponentCardCollection,
  RollingBannerCarousel,
  PopUpCard,
} from "../components/composePage";
import { Route, Routes, Navigate, useLocation, Router } from "react-router-dom";
import { useDocumentTitle } from "../hooks/useDocumentTitle";
import ArticleCollection from "../components/composePage/ArticleCollection";
import { DisplayContent } from "../components/freeFormContent";
import { BlockLoader } from "../components/loader";
import { Helmet } from "react-helmet";
import { NotFoundContainer } from "../components/not-found-page/NotFoundContainer";
import { IframeVideoImage } from "../components/composePage/iframeVideoImage";
import { TermsAndConditionContent } from "../components/TermsAndConditions/TermsAndConditions";
import { TermsAndConditionSubMenu } from "../components/TermsAndConditions/TermsAndConditionSubMenu";
import { Sitemap } from "../components/composePage/Sitemap";
import { LinksCollections } from "../components/composePage/LinkCardCollections";
import CardPanel from "../components/cardPanel";
import {useAnalytics} from "../useAnalytics"
interface composePageMenuProps {
  menus?: ComposeMenuItemsType[];
}
interface composePageSubMenuDataProps {
  name: string;
  itemUrl: string;
}

export const ComposePageScreen = ({ menus }: composePageMenuProps) => {
  const { pathname } = useLocation();
  const isDiscoverSlug = pathname === "/discover";
  const {page} = useAnalytics();

  useEffect(()=>{
    if(window.location.href.includes('#')){
      onClickReference(window.location.href.split('#')[1])
    }
  })

  const onClickReference = (referenceId:string) => {
    setTimeout(() => {
      const element = document.getElementById(referenceId);
      const headEle = document.getElementById("header")
      let isExperience = referenceId === "experience-terms"
      let headerOffset = headEle?.offsetHeight || 0
      let headHeight = (headEle && !isExperience )? headerOffset:(isExperience)? headerOffset*3 :0
      let top = element? element?.offsetTop-headHeight+1:0
        window.scrollTo({
            behavior: element ? "smooth" : "auto",
            top:  top
        });
    }, 100);
  }
  const {
    WEB_CONTENT_SINGLE_BANNER,
    COMPONENT_CARD_SECTION,
    POPUP_CARDS,
    ROLLING_BANNER,
    COMPONENT_CARD_COLLECTION,
    LOGOS_CONTENT_ACCORDIAN,
    COMPONENT_CARD,
    FREEFORM_CONTENT,
    ARTICAL_COLLECTION,
    LINKS_COLLECTIONS,
    LINKS,
    CARDPANEL
  } = ComposePageContentType;

  const { slug, text }: MenuItemsType = (menus ?? []).find(
    (m) => m.slug === pathname.replace("/", "")
  )!;

  const { data: composePageContent, loading } = useQuery<any>(
    getComposePageQuery,
    {
      variables: {
        usePreview: false,
        slug: pathname.replace("/", ""),
      },
      fetchPolicy: "cache-and-network",
    }
  );
  
  const composePageSubMenuData: composePageSubMenuDataProps[] =
    composePageContent?.composePage?.[0]?.subMenu?.[0]?.sectionLinks;
  //BB: variable for checking terms&condition sub menu
  const isTermsAndConditions = composePageContent?.composePage?.[0]?.subMenu?.[0]?.slug === "terms-submenu-list";
  
  const parentMenu =
    composePageContent?.composePage?.[0]?.subMenu?.[0]?.parentMenu;
  const composePageParentMeu = (parentMenu ?? []).map(
    ({ name, itemUrl }: any) => ({
      slug: itemUrl ? itemUrl : "",
      text: name ? name : "",
    })
  );
  const subMenu = (composePageSubMenuData ?? []).map(({ name, itemUrl }) => ({
    slug: itemUrl ? itemUrl : "",
    text: name ? name : "",
  }));

  useDocumentTitle(
    composePageContent?.composePage?.[0]?.title
      ? composePageContent?.composePage?.[0]?.title
      : `NotFound`
  );
  const [isMobile, setIsMobile] = useState(false);
  useEffect(()=>{
    if(!loading) {
      let path = slug;

      if(composePageContent?.composePage?.[0]?.title) {
        let title = composePageContent?.composePage?.[0]?.title;
        page(title,path)
       }else {
        page("NotFound" , path)
       }
    }
  
  },[composePageContent]);
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  return (
    <>
      {loading ? (
        <div className="h-128">
          <BlockLoader />
        </div>
      ) : isDiscoverSlug ? (
        <Navigate replace to="/discover/sky-starter" />
      ) : composePageContent?.composePage?.[0]?.slug ||
        pathname === "/sitemap" ? (
        (composePageContent?.composePage?.[0]?.slug ||
          pathname === "/sitemap") && (
          <>
            {parentMenu &&
              slug != "opensourcecode" &&
              !isTermsAndConditions && (
                <div className="flex lg:justify-center items-center gap-4 p-4 overflow-x-auto bg-white sticky top-0 z-10 mx-auto no-scrollbar scroll-smooth">
                  <ComposePageSubmenu
                    links={composePageParentMeu}
                    selectedLink={slug}
                  />
                </div>
              )}
            {subMenu && subMenu?.length !=0 && slug != "opensourcecode" && !isTermsAndConditions && (
              <div className="flex lg:justify-center items-center gap-4 p-4 overflow-x-auto bg-white sticky top-0 z-10 mx-auto no-scrollbar scroll-smooth">
                <ComposePageSubmenu links={subMenu} selectedLink={slug} />
              </div>
            )}
            {/* BB: terms and coditon is routed to terms&condition ubmenu compoenent */}
            {subMenu && isTermsAndConditions && (
              <div className="flex flex-col lg:justify-center items-center gap-4 p-4 bg-white overflow-x-auto sticky top-0 z-10 mx-auto no-scrollbar scroll-smooth">
                <TermsAndConditionSubMenu
                  menus={subMenu}
                  selectedMenu={slug}
                  splitIndex={6}
                />
              </div>
            )}

            {/* SEO Metadata */}
            {composePageContent?.composePage?.[0]?.seo && (
              <Helmet>
                <meta
                  property="og:title"
                  content={composePageContent?.composePage?.[0]?.seo?.seoTitle}
                />
                <meta
                  property="og:description"
                  content={
                    composePageContent?.composePage?.[0]?.seo?.description
                  }
                />

                <meta
                  name="description"
                  content={
                    composePageContent?.composePage?.[0]?.seo?.description
                  }
                />
              </Helmet>
            )}

            <div>
              {composePageContent?.composePage?.[0]?.content?.content?.map(
                (composePageContent: any, index: number) => (
                  <React.Fragment key={`composePageContent-${index}`}>
                    {composePageContent.contentType ===
                      WEB_CONTENT_SINGLE_BANNER && (
                      <div>
                        <WebContentSingleBanner
                          bannerContent={composePageContent?.bannerContent}
                        />
                      </div>
                    )}

                    {composePageContent.contentType ===
                      CARDPANEL && (
                      <div>
                        <CardPanel
                          module={composePageContent?.cardPanel}
                        />
                      </div>
                    )}
                    {composePageContent?.contentType === ROLLING_BANNER && (
                      <>
                        <RollingBannerCarousel
                          bannerContent={composePageContent?.rollingBanner}
                          heroBrandBackground={
                            composePageContent?.bannerContent
                              ?.heroBrandBackground
                          }
                        />
                      </>
                    )}
                    {composePageContent?.contentType ===
                      LOGOS_CONTENT_ACCORDIAN && (
                      <>
                        <LogosOrContentAccordion
                          logoContentProps={
                            composePageContent?.logoContentAccordion
                          }
                          referenceId={composePageContent?.referenceId}
                        />
                      </>
                    )}

                    {/* freeform content */}
                    {composePageContent?.contentType === FREEFORM_CONTENT &&
                      composePageContent?.freeformContent && (
                        <>
                          {composePageContent?.freeformContent?.heading && (
                            <div
                              className={
                                composePageContent?.freeformContent
                                  ?.backgroundGradientColor === "None"
                                  ? "px-4 text-center text-black"
                                  : "px-4 py-18 sky-blue-gradient text-center text-white"
                              }
                              style={{
                                paddingTop: composePageContent?.freeformContent
                                ?.backgroundGradientColor === "None" ?"1.5rem":"2.5rem",
                                paddingBottom: composePageContent?.freeformContent
                                ?.backgroundGradientColor === "None" ?"2.5rem":"2.5rem",
                                textAlign: "center",
                                lineHeight: "1.2",
                                marginLeft:composePageContent?.freeformContent
                                ?.backgroundGradientColor === "None"?"3rem":"",
                                marginRight:composePageContent?.freeformContent
                                ?.backgroundGradientColor === "None"?"3rem":""
                              }}
                            >
                              <h1
                                className="sky-h3 sm:sky-h2 md:sky-h4 custom-card-font-bold mt-3 text-center md:text-5xl p-2"
                                style={{
                                  fontFamily: "MarkPro-Black SKY",
                                  marginRight: isMobile?"":"3rem",
                                  marginLeft: isMobile?"":"3rem",
                                }}
                                dangerouslySetInnerHTML={{
                                  __html:
                                    composePageContent?.freeformContent
                                      ?.heading,
                                }}
                              ></h1>
                            </div>
                          )}

                          <div>
                            {composePageContent?.freeformContent?.iframeUrl && (
                              <div
                                className="lg: flex w-full"
                                style={{
                                  position: "relative",
                                  textAlign: "center",
                                }}
                              >
                                <IframeVideoImage
                                  src={
                                    composePageContent?.freeformContent
                                      ?.iframeUrl
                                  }
                                  title={
                                    composePageContent?.freeformContent
                                      ?.iframeTitle
                                  }
                                />
                              </div>
                            )}
                            {composePageContent?.freeformContent?.imageUrl
                              ?.url && (
                              <div
                                style={{
                                  verticalAlign: "middle",
                                  display: "block",
                                  marginLeft: "auto",
                                  marginRight: "auto",
                                  height: "auto",
                                  width:
                                    slug === "investor-centre/dividends"
                                      ? "50rem"
                                      : isMobile
                                      ? "55%"
                                      : "45%",
                                }}
                              >
                                <img
                                  style={{
                                    width:
                                      slug === "investor-centre/dividends"
                                        ? "100%"
                                        : "",
                                  }}
                                  src={
                                    composePageContent?.freeformContent
                                      ?.imageUrl?.url
                                  }
                                />
                              </div>
                            )}
                            {composePageContent?.freeformContent
                              ?.subHeading && (
                              <div
                                style={{
                                  textAlign: "center",
                                  lineHeight: "1.2",
                                  fontWeight: "600",
                                }}
                              >
                                <p
                                  className="sky-h4 sm:sky-h4 md:sky-h4 mt-3 text-center md:ml-16 ml-5"
                                  style={{
                                    fontFamily: "MarkPro-Bold SKY",
                                    marginRight: "3rem",
                                    fontWeight: "600",
                                    fontSize: "22px",
                                    paddingBottom: "1rem",
                                  }}
                                  dangerouslySetInnerHTML={{
                                    __html:
                                      composePageContent.freeformContent
                                        .subHeading,
                                  }}
                                ></p>
                              </div>
                            )}
                            <div
                              className="px-3 mx-auto desk:max-w-[1280px] tab:max-w-[960px] max-tab:max-w-[600px] mb-4"
                              style={{paddingTop: "0rem" }}
                            >
                              <div
                                className="lg:px-4 py-2 desk:max-w-[70%] max-desk:max-w-full md:overflow-x-hidden lg:overflow-x-hidden"
                                style={{
                                  textAlign:
                                    composePageContent?.freeformContent
                                      ?.alignmentPosition,
                                  marginLeft:isMobile?"1rem":"3rem",
                                marginRight:isMobile?"1rem":"3rem"
                                }}
                              >
                                <DisplayContent
                                  value={
                                    composePageContent?.freeformContent?.content
                                  }
                                  heading=""
                                  path={pathname}
                                />
                              </div>
                            </div>
                          </div>
                        </>
                      )}

                    {composePageContent?.contentType === ARTICAL_COLLECTION && (
                      <>
                        <ArticleCollection
                          articleCollection={
                            composePageContent?.articleCollection || []
                          }
                        />
                      </>
                    )}

                    {composePageContent?.contentType ===
                      COMPONENT_CARD_SECTION && (
                      <>
                        {composePageContent?.componentCards?.map(
                          (componentCards: any, index: number) => (
                            <>
                              {componentCards?.contentType === POPUP_CARDS && (
                                <PopUpCard
                                  isCarousel={composePageContent?.isCarousel}
                                  popupCards={componentCards?.popupcards}
                                  componentCardDisplayTile={
                                    composePageContent?.componentCardDisplayTile
                                  }
                                  isSlider={componentCards?.isSlider}
                                  componentCardSectionTitle={
                                    composePageContent?.componentCardSectionTitle
                                  }
                                  titleBackgroundGradientColor={
                                    composePageContent?.titleBackgroundGradientColor
                                  }
                                  titleTextAlignment={
                                    composePageContent?.titleTextAlignment
                                  }
                                  subTitleTextAlignment={
                                    composePageContent?.subTitleTextAlignment
                                  }
                                  subTitle={composePageContent?.subTitle}
                                />
                              )}
                              {componentCards?.contentType ===
                                LINKS_COLLECTIONS && (
                                <LinksCollections
                                  componentCardSectionTitle={
                                    composePageContent?.componentCardSectionTitle
                                  }
                                  subTitle={composePageContent?.subTitle}
                                  titleBackgroundGradientColor={
                                    composePageContent?.titleBackgroundGradientColor
                                  }
                                  titleTextAlignment={
                                    composePageContent?.titleTextAlignment
                                  }
                                  subTitleTextAlignment={
                                    composePageContent?.subTitleTextAlignment
                                  }
                                  linkCollections={componentCards?.links}
                                  linkType=""
                                />
                              )}
                              {componentCards?.contentType === LINKS && (
                                <LinksCollections
                                  componentCardSectionTitle={
                                    composePageContent?.componentCardSectionTitle
                                  }
                                  subTitle={composePageContent?.subTitle}
                                  titleBackgroundGradientColor={
                                    composePageContent?.titleBackgroundGradientColor
                                  }
                                  titleTextAlignment={
                                    composePageContent?.titleTextAlignment
                                  }
                                  subTitleTextAlignment={
                                    composePageContent?.subTitleTextAlignment
                                  }
                                  linkType=""
                                  linksDataContent={componentCards}
                                />
                              )}
                              {componentCards?.contentType ===
                                COMPONENT_CARD && (
                                <ComponentCard
                                  componentCardSectionTitle={
                                    composePageContent?.componentCardSectionTitle
                                  }
                                  componentCards={componentCards}
                                  componentCardDisplayTile={
                                    composePageContent?.componentCardDisplayTile
                                  }
                                  titleBackgroundGradientColor={
                                    composePageContent?.titleBackgroundGradientColor
                                  }
                                  titleTextAlignment={
                                    composePageContent?.titleTextAlignment
                                  }
                                  subTitle={composePageContent?.subTitle}
                                  subTitleTextAlignment={
                                    composePageContent?.subTitleTextAlignment
                                  }
                                  referenceId={composePageContent?.referenceId}
                                />
                              )}
                              {componentCards?.contentType ===
                                COMPONENT_CARD_COLLECTION && (
                                <ComponentCardCollection
                                  componentCardSectionTitle={
                                    composePageContent?.componentCardSectionTitle
                                  }
                                  componentCards={
                                    componentCards?.componentCardCollection
                                      ?.componentCards
                                  }
                                  componentCardDisplayTile={
                                    composePageContent?.componentCardDisplayTile
                                  }
                                  titleTextAlignment={
                                    composePageContent?.titleTextAlignment
                                  }
                                  subTitleTextAlignment={
                                    composePageContent?.subTitleTextAlignment
                                  }
                                  titleBackgroundGradientColor={
                                    composePageContent?.titleBackgroundGradientColor
                                  }
                                  subTitle={composePageContent?.subTitle}
                                  referenceId={composePageContent?.referenceId}
                                />
                              )}
                            </>
                          )
                        )}
                      </>
                    )}

                    {/*BB: terms and condition content */}
                    {composePageContent?.terms && (
                      <TermsAndConditionContent content={composePageContent} />
                    )}

                    {/* BB: sitemap */}
                    {composePageContent?.sitemap && (
                      <Sitemap value={composePageContent?.sitemap} />
                    )}
                  </React.Fragment>
                )
              )}
            </div>
          </>
        )
      ) : (
        <NotFoundContainer redirectURL="/" label="Go Home" />
      )}
    </>
  );
};


