
import { segment } from "./segment"

function useAnalytics() {
  const page = (title:string,path:string) => {
    segment.page(title,path);
  };
  // const page = () => {
  //   segment.page();
  // };
  const signUpForNL = (signUPForm: any, alertMessage: any) => {
    segment.signUpForNL(signUPForm, alertMessage);
  };

  const download = (linkData: any) => {
    segment.download(linkData);
  };
  const signedIn = (user: any) => {
    segment.signedIn(user);
  };
  const signedOut = () => {
    segment.signedOut();
  };
  const identify = (user: any) => {
    segment.identify(user);
  };
  const resetIdentity = () => {
    segment.resetIdentity();
  };

const trackClick = (slug: string, text: string, isExternal: boolean)=>{
  segment.trackClick(slug,text,isExternal)
}
  return {
    page,
    signUpForNL,
    download,
    signedIn,
    signedOut,
    identify,
    resetIdentity,
    trackClick
  };
}

export { useAnalytics };
