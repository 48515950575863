export const HORIZONTAL_VIDEO_PLAYER_URL ='https://players.brightcove.net/6122285394001/Uk656dFgP_default/index.html?videoId=';
export const VERTICAL_VIDEO_PLAYER_URL ='https://players.brightcove.net/6122285394001/BGHQ9V4jg8_default/index.html?videoId=';
export const GRAPHQL_URL1 = "https://web-graphql.prelive.sky.co.nz/prelive/graphql";
export const DOMAIN = process.env.REACT_APP_STATIC_DOMAIN!;
export const GRAPHQL_URL = process.env.REACT_APP_GRAPHQL_URL!;
export const AUTH0_LOGOUT_URL = process.env.REACT_APP_AUTH0_LOGOUT_URL!;
export const ACCOUNT_URL = process.env.REACT_APP_ACCOUNT_URL!;
export const ClientId = process.env.REACT_APP_AUTH0_CLIENT_ID!;
export let SPLITIO_KEY = {
    SKYWEB_DISCOVER_PAGE: 'SKYWEB_DISCOVER_PAGE',
}
export const LogoIconName = "Home";
export const TVGuide = "TV Guide";

export  const tags ={
    globalFooter: "globalFooter",
    globalHeader: "navigation",
    globalSubMenu: "routing-menu-for-getsky-spa",
    golbalMobileHeader:"globalHeaderMenuMobile",
}