import React, { useState } from 'react';
import cx from 'classnames';

interface AccordionProps {
  title: string | JSX.Element;
  classNames?: string;
  children?: JSX.Element;
  active?: boolean;
  bgGradient?: string;
}

const Accordion = ({ title, children, classNames, active = false, bgGradient }: AccordionProps) => {
  const [open, setOpenState] = useState(active);
  const toggleAccordian = () => setOpenState(prevState => !prevState);
  return (
    <div style={{marginTop:"2rem"}}>
      {title !== "" && 
       <div
       className={`relative ${
         bgGradient === 'Blue Gradient' ? 'sky-blue-gradient text-white' : 'sky-bg-gray-light sky-text-midnight'
       } text-left h-20 px-8 py-4 md:p-4 flex justify-start items-center cursor-pointer ${classNames}`}
       onClick={toggleAccordian}>
       <div className="w-9/10 sky-h6-bold md:sky-h4-bold">{title}</div>
       <button className="h-20 w-24 absolute right-0 top-0 sky-h3 md:sky-h4">{open ? '-' : '+'}</button>
     </div>}
      <div className={cx('transition-all', { 'h-auto overflow-visible': open, 'h-0 overflow-hidden': !open })}>
        {children}
      </div>
    </div>
  );
};

export { Accordion };
