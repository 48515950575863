import React, { useEffect } from 'react';
export const HalfWidthImage = ({ src }: any) => {
  return (
    <div className="w-full lg:block md: block">
      <div>
        <img className="w-full md: w-full lg: w-full" src={src}></img>
      </div>
    </div>
  );
};
