import React, { ReactNode, useEffect, useState } from 'react';
import { Options, documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { WebContentVideoText } from './WebContentVideoText';
import { SingleBannerWithVideoContent } from './SingleBannerWithVideoContent';
import { SingleBannerThirdWidthContent } from './SingleBannerThirdWidthContent';
import { isMobile, isTablet } from '../../types';
import { useLocation } from 'react-router-dom';

export interface bannerContents {
  bannerContent: BannerContent;
  contentWidth?: string;
  contentAlign?: string;
  heroBrandBackground?:string;
}
export interface BannerContent {
  bannerName: string;
  bannerTitle:string;
  heading: Document;
  detailParagraph: Document;
  detailParagraphColumn2: Document;
  copyrightParagraph: Document;
  heroImage: Image;
  channelOrSponsorLogos: Image;
  heroImageMobile: Image;
  brightCoveVideoId: string;
  brightCoveVideoExperienceId?: string;
  brightCoveVideoIdMobile: string;
  callToActionText: string;
  callToActionLink: string;
  callToActionSecondaryText: string;
  callToActionLinkSecondary: string;
  layoutOptions: string;
  lozengeText?: string;
}

interface Image {
  url?: string;
  text?: string;
}

export const LozengeText = ({ lozengeText }: { lozengeText?: string }) => {
  return (
    <div
      style={{
        display: 'inline-block',
        fontSize: '14px',
        lineHeight: '12px',
        padding: '7px 12px 6px 0px',
        marginBottom: '10px',
        background: 'linear-gradient(113.88deg, #9100D4 -20.5%, #F94CB1 40.06%, #FC6E73 66.71%, #FF9E1C 100.62%)',
        boxShadow: '2.79209px 2.79209px 6.98024px rgba(0, 0, 0, 0.25)',
        textTransform: 'uppercase',
        clipPath: 'polygon(0 0, 100% 0, 95% 98%, 0% 100%)',
      }}>
      {lozengeText}
    </div>
  );
};
const SingleBannerContentMobileView = ({bannerContent}: bannerContents) => {
  return (
    <>
      <div className="md:hidden lg:hidden">
        <div style={{ backgroundColor: "#00013a" }}>
          <div className="relative w-full overflow-hidden text-white">
            <div className="relative" style={{ backgroundColor: "#00013A" }}>
              <img
                src={
                  bannerContent?.heroImageMobile?.url
                    ? bannerContent?.heroImageMobile?.url
                    : bannerContent?.heroImage?.url
                }
                className="top-0 left-0 w-full h-full object-cover align-middle object-[center_top]"
              ></img>
            </div>
          </div>

          {bannerContent?.lozengeText != null ||
            (documentToReactComponents(bannerContent?.heading as any) && (
              <div className="relative">
                <div
                  className="absolute -bottom-px w-full p-[3rem_1rem_1rem]"
                  style={{
                    backgroundImage:
                      "linear-gradient(to top, #00013A 30%, transparent)",
                  }}
                >
                  {bannerContent?.lozengeText && (
                    <LozengeText lozengeText={bannerContent?.lozengeText} />
                  )}
                  <div
                    className="sky-h2 md:sky-h1 text-white leading-[1.2]"
                    style={{ fontWeight: "normal",fontFamily:"MarkPro-Black SKY" }}
                  >
                    {documentToReactComponents(bannerContent?.heading as any)}
                  </div>
                </div>
              </div>
            ))}
          <div
            className="relative text-white p-[0_1rem_2rem]"
            style={{ backgroundColor: "#00013a" }}
          >
            {bannerContent?.detailParagraph && (
              <div>
                <div className="sky-h6 md:sky-h7 text-white leading-[1.2]">
                  {documentToReactComponents(
                    bannerContent?.detailParagraph as any
                  )}
                </div>
              </div>
            )}
            {bannerContent?.callToActionText && (
              <div style={{ display: "flex" }}>
                <div className="flex m-[1rem_0_2rem] justify-evenly">
                  <a
                    href={bannerContent?.callToActionLink}
                    onClick={(e) => {
                      e.preventDefault();
                      const link = bannerContent?.callToActionLink;
                      if (
                        link &&
                        (link.startsWith("http://") ||
                          link.startsWith("https://") ||
                          link.includes("."))
                      ) {
                        window.open(link, "_blank", "noopener,noreferrer");
                      } else if (link) {
                        window.location.href = link;
                      }
                    }}
                  >
                    {" "}
                    <button
                      className={`${
                        bannerContent?.callToActionSecondaryText === null
                          ? "sky-button sky-button--primary-dark fr-banner-carousel-slide-primary sky-h7 font-bold py-4 px-10 rounded-full sm:mx-[0rem] md:mx-[0rem]"
                          : "sky-button sky-button--secondary-dark fr-banner-carousel-slide-secondary sky-h7 font-bold py-4 px-10 rounded-full"
                      }`}
                      style={{
                        minWidth: isMobile ? "4rem" : "12rem",
                        paddingLeft: isMobile ? "1rem" : "2.5rem",
                        paddingRight: isMobile ? "1rem" : "2.5rem",
                        marginLeft: "0rem",
                      }}
                    >
                      {bannerContent?.callToActionText}
                    </button>
                  </a>
                </div>
                {bannerContent?.callToActionSecondaryText && (
                  <div className="flex m-[1rem_0_2rem] justify-evenly">
                    <a
                      href={bannerContent?.callToActionLinkSecondary}
                      onClick={(e) => {
                        e.preventDefault();
                        const link = bannerContent?.callToActionLinkSecondary;
                        if (
                          link &&
                          (link.startsWith("http://") ||
                            link.startsWith("https://") ||
                            link.includes("."))
                        ) {
                          window.open(link, "_blank", "noopener,noreferrer");
                        } else if (link) {
                          window.location.href = link;
                        }
                      }}
                    >
                      {/* <p className="underline sky-h5 font-bold text-white py-4">
                    {bannerContent?.callToActionSecondaryText}
                  </p> */}
                      <button
                        style={{
                          minWidth: isMobile ? "4rem" : "12rem",
                          paddingLeft: isMobile ? "" : "2.5rem",
                          paddingRight: isMobile ? "" : "2.5rem",
                          marginLeft: "0rem",
                        }}
                        className="sky-button sky-button--primary-dark fr-banner-carousel-slide-primary sky-h7 font-bold py-4 px-10 rounded-full sm:mx-[0rem] md:mx-[0rem]"
                      >
                        {bannerContent?.callToActionSecondaryText}
                      </button>
                    </a>
                  </div>
                )}
              </div>
            )}
            {bannerContent?.copyrightParagraph && (
              <div className="absolute bottom-0 right-0 p-[0.3rem] pl-[1rem]">
                <div className="text-[10px] leading-[1.2]">
                  {documentToReactComponents(
                    bannerContent?.copyrightParagraph as any
                  )}
                </div>
              </div>
            )}

            {bannerContent?.channelOrSponsorLogos?.url && (
              <div
                className="absolute"
                style={{ bottom: "4rem", right: "5rem" }}
              >
                <img
                  src={bannerContent?.channelOrSponsorLogos?.url}
                  alt="channel logo"
                  width="100"
                  height="70"
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
}
const SingleBannerContent = ({ bannerContent }: bannerContents) => {
  return (
    <>
      <div
        className="absolute font-bold text-white md:top-[0rem] left-0 lg:top-[7rem] lg:ml-24 md:ml-5 ml-4"
      >
        {bannerContent?.lozengeText && (
          <LozengeText lozengeText={bannerContent?.lozengeText} />
        )}
        <div className="md:text-[48px] lg:sky-h2 text-white md:top-12 md:mt-8 sky-sb-h4 " style={{fontFamily:'MarkPro-Black SKY',}}>
          {documentToReactComponents(bannerContent?.heading as any)}
        </div>
        {bannerContent?.detailParagraph && (
          <div
            className="mt-4 sky-h6 md:sky-h7 text-white"
            style={{ fontWeight: "normal" }}
          >
            {documentToReactComponents(bannerContent?.detailParagraph as any)}
          </div>
        )}
        {bannerContent?.callToActionText && (
          <div className="mt-6 md:flex lg:flex gap-4 sky-text-midnight">
            <div className="pb-4">
              <a
                href={bannerContent?.callToActionLink}
                onClick={(e) => {
                  e.preventDefault();
                  const link = bannerContent?.callToActionLink;
                  if (
                    link &&
                    (link.startsWith("http://") ||
                      link.startsWith("https://") ||
                      link.includes("."))
                  ) {
                    window.open(link, "_blank", "noopener,noreferrer");
                  } else if (link) {
                    window.location.href = link;
                  }
                }}
              >
                <button
                style = {{marginLeft: "0rem"}}
                  className={`${
                    bannerContent?.callToActionSecondaryText === null
                      ? "sky-button sky-button--primary-dark fr-banner-carousel-slide-primary sky-h7 font-bold py-4 px-10 rounded-full sm:mx-[0rem] md:mx-[0rem]"
                      : "sky-button sky-button--secondary-dark fr-banner-carousel-slide-secondary sky-h7 font-bold py-4 px-10 rounded-full"
                  }`}
                >
                  {bannerContent?.callToActionText}
                </button>
              </a>
            </div>
            {bannerContent?.callToActionSecondaryText && (
              <div>
                <a
                  href={bannerContent?.callToActionLinkSecondary}
                  onClick={(e) => {
                    e.preventDefault();
                    const link = bannerContent?.callToActionLinkSecondary;
                    if (
                      link &&
                      (link.startsWith("http://") ||
                        link.startsWith("https://") ||
                        link.includes("."))
                    ) {
                      window.open(link, "_blank", "noopener,noreferrer");
                    } else if (link) {
                      window.location.href = link;
                    }
                  }}
                >
                  {/* <p className="underline sky-h5 font-bold text-white py-4">
                    {bannerContent?.callToActionSecondaryText}
                  </p> */}
                  <button style = {{marginLeft:"0rem"}} className="sky-button sky-button--primary-dark fr-banner-carousel-slide-primary sky-h7 font-bold py-4 px-10 rounded-full sm:mx-[0rem] md:mx-[0rem]">
                    {bannerContent?.callToActionSecondaryText}
                  </button>
                </a>
              </div>
            )}
          </div>
        )}
      </div>
      {bannerContent?.copyrightParagraph && (
        <div className="absolute md:pl-[1.5rem]" style={{ bottom: "1rem", right: "0rem" }}>
          <h4
            className="sky-h7 md:sky-h8 text-white"
            style={{ marginRight: "7rem" }}
          >
            {documentToReactComponents(
              bannerContent?.copyrightParagraph as any
            )}
          </h4>
        </div>
      )}

      {bannerContent?.channelOrSponsorLogos?.url && (
        <div className="absolute" style={{ bottom: "4rem", right: "5rem" }}>
          <img
            src={bannerContent?.channelOrSponsorLogos?.url}
            alt="channel logo"
            width="100"
            height="70"
          />
        </div>
      )}
    </>
  );
};

export const WebContentSingleBanner = ({ bannerContent,heroBrandBackground }: bannerContents) => {
  const { pathname } = useLocation();
  const slug = pathname.replace("/", "");
  const containsInvestorCentre = slug.includes('investor-centre') || slug.includes('sky-apps') ;
  return (
    <>
      {bannerContent?.layoutOptions === "ImageBackground-Offers" &&
        !bannerContent?.brightCoveVideoId &&
        !bannerContent?.brightCoveVideoExperienceId && (
          <>
            {/* Mobile view */}
            {bannerContent?.callToActionText || bannerContent?.callToActionSecondaryText || documentToReactComponents(bannerContent?.heading as any) ? (
              <SingleBannerContentMobileView bannerContent={bannerContent} />
            ) : (
              <div
                className="flex bg-no-repeat bg-center bg-cover md:hidden lg:hidden relative sm:h-[223px]"
                style={{
                  backgroundImage: `url('${
                    bannerContent?.heroImageMobile?.url
                      ? bannerContent?.heroImageMobile?.url
                      : bannerContent?.heroImage?.url
                  }')`,
                  backgroundSize: "cover",
                  height:slug === "yellowstone" ? "8rem":slug === "gameof2halves"?"205px":"223px"
                }}
              ></div>
            )}
            {/* Desktop view */}
            <div
              className="hidden lg:flex relative w-100 h-100 bg-center bg-cover bg-no-repeat lg:h-[36rem]"
              style={{
                backgroundImage: `url(${bannerContent?.heroImage?.url})`,
                backgroundPositionX:containsInvestorCentre?"":"right",
                height:slug === "yellowstone" ?"25rem":slug === "gameof2halves"?"45rem":"36rem"
              }}
            >
              <SingleBannerContent bannerContent={bannerContent} />
            </div>
            {/* Tablet view */}
            <div
              className="hidden md:flex lg:hidden bg-no-repeat bg-cover relative md:h-[20rem]"
              style={{
                backgroundImage: `url(${bannerContent?.heroImage?.url})`,
                height:slug === "gameof2halves"?"33rem":"22rem"
              }}
            >
              <SingleBannerContent bannerContent={bannerContent} />
            </div>
          </>
        )}

      {bannerContent?.layoutOptions === "ImageBackground-Offers" &&
        bannerContent?.brightCoveVideoExperienceId && (
          <>
            <SingleBannerWithVideoContent bannerContent={bannerContent} />
          </>
        )}
      {bannerContent?.layoutOptions === "ImageBackground-Offers" &&
        bannerContent?.brightCoveVideoId && (
          <>
            <WebContentVideoText bannerVideoContent={bannerContent} />
          </>
        )}
      {bannerContent?.layoutOptions === "ImageBackground-Offers" &&
        bannerContent?.brightCoveVideoId &&
        bannerContent?.heroImage?.url && (
          <>
            <WebContentVideoText bannerVideoContent={bannerContent} />
          </>
        )}
        {bannerContent?.layoutOptions === "BackgroundImagewithVideo" &&
        bannerContent?.brightCoveVideoId &&
        bannerContent?.brightCoveVideoIdMobile && (
          <>
            <WebContentVideoText bannerVideoContent={bannerContent} />
          </>
        )}

{bannerContent?.layoutOptions === "VideoOnly" &&
        bannerContent?.brightCoveVideoId &&
        bannerContent?.brightCoveVideoIdMobile && (
          <>
            <WebContentVideoText bannerVideoContent={bannerContent} />
          </>
        )}
{/* VideoOnly */}
      {bannerContent?.layoutOptions === "SkyBackground-ThirdWidth" && (
        <>
          <SingleBannerThirdWidthContent bannerContent={bannerContent} />
        </>
      )}
      {bannerContent?.layoutOptions === "SkyBackground-HalfWidth" &&
        bannerContent?.brightCoveVideoId &&
        bannerContent.brightCoveVideoIdMobile && (
          <>
            {/* Mobile view */}
            <div>
              <WebContentVideoText bannerVideoContent={bannerContent as any} heroBrandBackground = {heroBrandBackground}/>
            </div>

            {/* Desktop view */}
            <div className="hidden">
              <WebContentVideoText bannerVideoContent={bannerContent as any} />
            </div>
          </>
        )}
      {bannerContent.layoutOptions === "ImageBackground-Offers" &&
        bannerContent?.brightCoveVideoId &&
        bannerContent.brightCoveVideoIdMobile && (
          <div className="hidden">
            <WebContentVideoText bannerVideoContent={bannerContent} />
          </div>
        )}
      {bannerContent?.layoutOptions === "SkyBackground-HalfWidth" &&
        !bannerContent?.brightCoveVideoId &&
        bannerContent?.heroImage.url && (
          <div>
            <WebContentVideoText bannerVideoContent={bannerContent} heroBrandBackground = {heroBrandBackground}/>
          </div>
        )}
    </>
  );
};
