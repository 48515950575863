import React, { useEffect, useState } from 'react';
import { BannerContent } from './WebContentSingleBanner';
import ArticleCollection from './ArticleCollection';
import { ComposePageContentType } from '../../types';
import { useQuery } from '@apollo/client';
import { getComposePageQuery } from '../../graphql/queries/getComposePage';
import { useLocation } from 'react-router-dom';
import {
  HORIZONTAL_VIDEO_PLAYER_URL
} from "../../config";
import { relative } from 'path';

export interface VideoImg {
  src?: string;
  title?: string;
}

export const IframeVideoImage = ({ src, title }: VideoImg) => {
  const [isMobile, setIsMobile] = useState(false);
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  return (
    
      <>
      {src && src.includes("youtube") ? (
        <iframe className="items-center justify-center w-11/12 md:w-[1000px] md:h-[500px] lg:w-full lg:mx-14 lg:h-[500px] mx-auto" src={src} title={title} allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerPolicy="strict-origin-when-cross-origin"
        ></iframe>
      ) : (
        <div className='relative w-full overflow-hidden' style={{padding:isMobile?'':'0 4rem 0 4rem' }}><script type="text/javascript" src={"https://skytv.elmotalent.co.nz/js/iframeResizer.min.js"}></script>

    <iframe id="elmo-recruitment-embed" className="block w-full border-0  lg:h-[29rem]" 
    style={{height: isMobile?'102vh':'',padding: isMobile?'0 1rem 0 1rem':''}} src={src} data-tabindex="-1"></iframe>
    <script type="text/javascript" src={"https://skytv.elmotalent.co.nz/js/iframeResizer.min.js"}></script>
    </div>
      )}
    </>
       
  );
};
