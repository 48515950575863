import React from 'react';
import { Link } from 'react-router-dom';
import { useAnalytics } from '../../useAnalytics'; 

export type ComposeMenuItemsType = {
  slug: string;
  text: string;
};

export type MenuItemsType = {
  slug: string;
  text: string;
};

export const ComposePageSubmenu = ({
  links,
  selectedLink,
}: {
  links: ComposeMenuItemsType[];
  selectedLink: string;
}) => {
  const { trackClick } = useAnalytics(); 

  return (
    <>
      {(links ?? []).map(({ slug, text }) => {
        const isExternal = slug.includes('https');

        return isExternal ? (
          <a
            href={slug}
            className="whitespace-no-wrap text-lg font-bold sky-text-midnight hover:sky-text-daylight"
            target="_blank"
            rel="noopener noreferrer" 
            key={slug} 
            // onClick={() => trackClick(slug, text, true)} 
          >
            {text}
          </a>
        ) : (
          <Link
            id={`nav-link-${slug}`}
            key={`nav-link-${slug}`}
            className={`whitespace-no-wrap text-lg font-bold ${
              selectedLink === slug
                ? 'sky-text-daylight border-sky-secondaryBlue border-b-2 border-solid'
                : 'sky-text-midnight hover:sky-text-daylight'
            } `}
            to={`/${slug}`}
            // onClick={() => trackClick(slug, text, false)} 
          >
            {text}
          </Link>
        );
      })}
    </>
  );
};
