export const SegmentECommerceEvents = {
  DOWNLOAD: "file_download_clicked",
  SIGNUP_FOR_NEWSLETTER: "sky_static_submit_clicked",
  SIGNED_IN: "Signed In",
  SIGNED_OUT: "Signed Out",
  FORM_NAME: "Newsletter Signup",
  MENU_ITEM_CLICKED: "menu_item_clicked",
};

class SClient {
  getPageProperties(path: string) {
    return {
      title: document.title,
      url: window.location.href,
      path: path,
      referrer: document.referrer,
      pageName: document.title,
    };
  }

  track(event: string, properties?: any, options?: any, callback?: any) {
    let segmentFromWindowObject = (window as any).analytics;
    if (!segmentFromWindowObject) {
      return;
    }

    try {
      const finalProperties = {
        ...this.getPageProperties(properties?.path),
        ...properties,
      };

      segmentFromWindowObject.track(event, finalProperties, options, callback);
    } catch (err) {
      console.error("Error tracking event:", err);
    }
  }

  identify(user: any) {
    let segmentFromWindowObject = (window as any).analytics;
    if (!segmentFromWindowObject) {
      console.error("Segment disabled.");
      return;
    }
    let traits = {
      userId: user?.sub,
      email: user?.email,
    };
    try {
      segmentFromWindowObject.identify(user?.email, traits);
    } catch (err) {
      console.error(err);
    }
  }

  resetIdentity() {
    let segmentFromWindowObject = (window as any).analytics;
    if (!segmentFromWindowObject) {
      console.error("Segment disabled.");
      return;
    }
    try {
      segmentFromWindowObject?.reset();
    } catch (err) {
      console.error(err);
    }
  }

  page(title: string, path: string) {
    let segmentFromWindowObject = (window as any).analytics;
    if (!segmentFromWindowObject) {
      console.error("Segment disabled.");
      return;
    }
    try {
      const pageProperties = this.getPageProperties(path);
      segmentFromWindowObject.page(title, pageProperties);
    } catch (err) {
      console.error(err);
    }
  }

  //GA for SignUp for Newsletter
  signUpForNL(signUPForm: any, alertMessage: any) {
    this.track(SegmentECommerceEvents.SIGNUP_FOR_NEWSLETTER, {
      pageName:
        window.location.pathname === "/"
          ? "Home"
          : window.location.pathname
              .replace(/^\/+/, "") // Remove leading slashes
              .replace(/-/g, " ") // Replace hyphens with spaces
              .replace(/\b\w/g, (char) => char.toUpperCase()),
      e_userName: signUPForm?.emailAddress?.split("@")?.[0],
      e_domainName: signUPForm?.emailAddress?.split("@")?.[1],
      company: signUPForm?.company,
      //emailAddress: signUPForm?.emailAddress,
      alertMessage: alertMessage,
      formName: SegmentECommerceEvents.FORM_NAME,
      is_segment: "yes",
    });
  }
  //GA for Download
  download(linkdata: any) {
    this.track(SegmentECommerceEvents.DOWNLOAD, {
      //pageURL:window.location.pathname,
      linkText: linkdata?.cardImageUrl
        ? linkdata?.cardImageUrl?.title
        : linkdata?.linkText,
      linkUrl: linkdata?.cardImageUrl
        ? linkdata?.cardImageUrl?.file
        : linkdata?.linkDocument
        ? linkdata?.linkDocument?.url
        : linkdata?.linkUrl,
      fileName: linkdata?.cardImageUrl
        ? linkdata?.cardImageUrl?.file?.split("/")?.[6]
        : linkdata?.linkDocument
        ? linkdata?.linkDocument?.url?.split("/")?.[6]
        : linkdata?.linkUrl?.split("/")?.[6],
    });
  }
  //GA for Signed In
  signedIn(user: any) {
    this.track(SegmentECommerceEvents.SIGNED_IN, {
      email: user?.email,
      userId: user?.sub,
    });
  }
  //GA for Signed Out
  signedOut() {
    this.track(SegmentECommerceEvents.SIGNED_OUT, {
      email: undefined,
      userId: undefined,
    });
  }
  trackClick(slug: string, text: string, isExternal: boolean) {
    this.track(slug, {
      slug,
      text,
      isExternal,
    });
  }
}

let segment = new SClient();
export { segment };
