import React, { ReactNode } from 'react';
import { LozengeText, bannerContents } from './WebContentSingleBanner';
import { Options, documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { Document, BLOCKS, INLINES, MARKS } from '@contentful/rich-text-types';
import { isTablet } from '../../types';

const renderOptions: Options = {
  renderText: (text: string) =>
    text
      .split('\n')
      .reduce(
        (children: any, textSegment: any, index: number) => [...children, index > 0 && <br key={index} />, textSegment],
        []
      ),

  renderMark: {
    [MARKS.BOLD]: (text: ReactNode) => (
      <div className="pb-8" style={{ fontSize: '17px', lineHeight: '18px', marginLeft: '0' }}>
        <b>{text}</b>
      </div>
    ),
  },
  renderNode: {
    [BLOCKS.HEADING_1]: (_, children: ReactNode) => (
      <h1 className="py-2 mx-auto text-2xl md:text-3xl text-black">{children}</h1>
    ),
    [BLOCKS.PARAGRAPH]: (_, children: ReactNode) => (
      <p className="lg:text-lg text-md text-white font-semibold">{children}</p>
    ),
    [INLINES.HYPERLINK]: (node: any, children: ReactNode) => (
      <a href={node?.data?.uri} className="sky-text-daylight">
        {children}
      </a>
    ),
    [BLOCKS.TABLE]: (_, children: ReactNode) => (
      <table>
        <tbody>{children}</tbody>
      </table>
    ),

    [BLOCKS.UL_LIST]: (_, children: ReactNode) => (
      <ul className="text-white font-semibold" style={{ width: '280px' }}>
        <li>{children}</li>
      </ul>
    ),

    [BLOCKS.LIST_ITEM]: (_, children: ReactNode) => (
      <li className="text-white font-semibold pb-4" style={{ fontSize: '14px', lineHeight: '22px', display: 'block' }}>
        <div className="flex">
          <div className="pr-2">
            <svg
              aria-hidden="true"
              focusable="false"
              data-prefix="fas"
              data-icon="check-circle"
              className="svg-inline--fa fa-check-circle fa-w-16 w-4 h-4"
              role="img"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 512 512">
              <path
                fill="currentColor"
                d="M504 256c0 136.967-111.033 248-248 248S8 392.967 8 256 119.033 8 256 8s248 111.033 248 248zM227.314 387.314l184-184c6.248-6.248 6.248-16.379 0-22.627l-22.627-22.627c-6.248-6.249-16.379-6.249-22.628 0L216 308.118l-70.059-70.059c-6.248-6.248-16.379-6.248-22.628 0l-22.627 22.627c-6.248 6.248-6.248 16.379 0 22.627l104 104c6.249 6.249 16.379 6.249 22.628.001z"></path>
            </svg>
          </div>
          <div>{children}</div>
        </div>
      </li>
    ),
  },
};

export const SingleBannerThirdWidthComponent = ({ bannerContent, contentWidth, contentAlign }: bannerContents) => {
  return (
    <>
      <div
        className={`absolute lg:top-48 overflow-hidden md:top-48 md:${
          contentAlign ? contentAlign : ""
        } ${contentWidth ? "" : "left-2 top-20"}`}
        style={{ width: contentWidth }}
      >
        <div className={`mt-6 lg:flex md:flex gap-4 sky-text-midnight`}>
          {bannerContent?.detailParagraph && (
            <div className="mt-4 sky-h6 md:sky-h8 text-white">
              {documentToReactComponents(
                bannerContent?.detailParagraph as any,
                renderOptions
              )}
            </div>
          )}
          {bannerContent?.detailParagraphColumn2 && (
            <div className="mt-4 sky-h6 md:sky-h8 text-white">
              {documentToReactComponents(
                bannerContent?.detailParagraphColumn2 as any,
                renderOptions
              )}
            </div>
          )}
        </div>
        {bannerContent?.callToActionText && (
          <div
            className={`mt-6 lg:flex md:flex lg:gap-48 gap-4 sky-text-midnight`}
          >
            <div className="pb-4">
              <a
                href={bannerContent?.callToActionLink}
                onClick={(e) => {
                  e.preventDefault();
                  const link = bannerContent?.callToActionLink;
                  if (
                    link &&
                    (link.startsWith("http://") ||
                      link.startsWith("https://") ||
                      link.includes("."))
                  ) {
                    window.open(link, "_blank", "noopener,noreferrer");
                  } else if (link) {
                    window.location.href = link;
                  }
                }}
              >
                <button className="bg-gray-300 hover:bg-social-twitter hover:text-white sky-h7 font-bold py-4 px-10 md:px-4  rounded-full">
                  {bannerContent?.callToActionText}
                </button>
              </a>
            </div>
            {bannerContent?.callToActionSecondaryText && (
              <div>
                <a
                  href={bannerContent?.callToActionLinkSecondary}
                  onClick={(e) => {
                    e.preventDefault();
                    const link = bannerContent?.callToActionLinkSecondary;
                    if (
                      link &&
                      (link.startsWith("http://") ||
                        link.startsWith("https://") ||
                        link.includes("."))
                    ) {
                      window.open(link, "_blank", "noopener,noreferrer");
                    } else if (link) {
                      window.location.href = link;
                    }
                  }}
                >
                  {/* <p className="underline sky-h5 font-bold text-white py-4">
                                        {bannerContent?.callToActionSecondaryText}
                                    </p> */}
                  <button className="bg-gray-300 hover:bg-social-twitter hover:text-white sky-h7 font-bold py-4 px-10 md:px-4  rounded-full">
                    {bannerContent?.callToActionSecondaryText}
                  </button>
                </a>
              </div>
            )}
          </div>
        )}
      </div>
      {bannerContent?.copyrightParagraph && (
        <div className="absolute md:pl-[1.5rem]" style={{ bottom: "0.5rem", right: "0rem" }}>
          <h4 className="sky-h7 md:sky-h8 text-white">
            {documentToReactComponents(
              bannerContent?.copyrightParagraph as any
            )}
          </h4>
        </div>
      )}

      {bannerContent?.channelOrSponsorLogos?.url && (
        <div className="absolute" style={{ bottom: "1rem", right: "4rem" }}>
          <img
            src={bannerContent?.channelOrSponsorLogos?.url}
            alt="channel logo"
            className="lg:h-12 md:h-6"
          />
        </div>
      )}
    </>
  );
};

export const SingleBannerThirdWidthContent = ({ bannerContent }: bannerContents) => {
  return (
    <>
      {/* Mobile view */}
      <div
        className="md:hidden lg:hidden relative sky-orange-coral-gradient"
        style={{
          height: `${
            bannerContent?.detailParagraphColumn2 ? "800px" : "588px"
          }`,
        }}
      >
        <div
          className="flex bg-no-repeat bg-cover md:hidden lg:hidden relative"
          style={{
            backgroundImage: `url('${
              bannerContent?.heroImageMobile?.url
                ? bannerContent?.heroImageMobile?.url
                : bannerContent?.heroImage?.url
            }')`,
            backgroundSize: "cover",
            height: "420px",
            clipPath: "polygon(12% 0%, 100% 0%, 88% 100%, 0% 100%)",
            marginLeft: "-6%",
          }}
        ></div>
        {/* <SingleBannerThirdWidthComponent bannerContent={bannerContent} /> */}
        <div
          className="absolute font-bold text-white"
          style={{ top: "8rem", left: "2rem" }}
        >
          {bannerContent?.lozengeText && (
            <LozengeText lozengeText={bannerContent?.lozengeText} />
          )}
          <div className="sky-h2 md:sky-h1 text-white md:top-[7rem]">
            {documentToReactComponents(bannerContent?.heading as any)}
          </div>
        </div>

        <div
          className={`absolute left-2`}
          style={{ width: "20rem", top: "14rem" }}
        >
          <div className={`mt-6 lg:flex md:flex gap-4 sky-text-midnight`}>
            {bannerContent?.detailParagraph && (
              <div className="mt-4 sky-h6 md:sky-h8 text-white">
                {documentToReactComponents(
                  bannerContent?.detailParagraph as any,
                  renderOptions
                )}
              </div>
            )}
            {bannerContent?.detailParagraphColumn2 && (
              <div className="mt-4 sky-h6 md:sky-h8 text-white">
                {documentToReactComponents(
                  bannerContent?.detailParagraphColumn2 as any,
                  renderOptions
                )}
              </div>
            )}
          </div>
          {bannerContent?.callToActionText && (
            <div
              className={`mt-6 lg:flex md:flex lg:gap-48 gap-4 sky-text-midnight`}
            >
              <div className="pb-4">
                <a
                  href={bannerContent?.callToActionLink}
                  onClick={(e) => {
                    e.preventDefault();
                    const link = bannerContent?.callToActionLink;
                    if (
                      link &&
                      (link.startsWith("http://") ||
                        link.startsWith("https://") ||
                        link.includes("."))
                    ) {
                      window.open(link, "_blank", "noopener,noreferrer");
                    } else if (link) {
                      window.location.href = link;
                    }
                  }}
                >
                  <button className="bg-gray-300 hover:bg-social-twitter hover:text-white sky-h7 font-bold py-4 px-10 md:px-4  rounded-full">
                    {bannerContent?.callToActionText}
                  </button>
                </a>
              </div>
              {bannerContent?.callToActionSecondaryText && (
                <div>
                  <a
                    href={bannerContent?.callToActionLinkSecondary}
                    onClick={(e) => {
                      e.preventDefault();
                      const link = bannerContent?.callToActionLinkSecondary;
                      if (
                        link &&
                        (link.startsWith("http://") ||
                          link.startsWith("https://") ||
                          link.includes("."))
                      ) {
                        window.open(link, "_blank", "noopener,noreferrer");
                      } else if (link) {
                        window.location.href = link;
                      }
                    }}
                  >
                    {/* <p className="underline sky-h5 font-bold text-white py-4">
                                            {bannerContent?.callToActionSecondaryText}
                                        </p> */}
                    <button className="bg-gray-300 hover:bg-social-twitter hover:text-white sky-h7 font-bold py-4 px-10 md:px-4  rounded-full">
                      {bannerContent?.callToActionSecondaryText}
                    </button>
                  </a>
                </div>
              )}
            </div>
          )}
        </div>
        {bannerContent?.channelOrSponsorLogos?.url && (
          <div className="absolute" style={{ bottom: "1.5rem", right: "1rem" }}>
            <img
              src={bannerContent?.channelOrSponsorLogos?.url}
              alt="channel logo"
              className="lg:h-12 h-6"
            />
          </div>
        )}
        {bannerContent?.copyrightParagraph && (
          <div className="absolute md:pl-[1.5rem]" style={{ bottom: "1rem", right: "0rem" }}>
            <h4 className="sky-h7 md:sky-h8 text-white">
              {documentToReactComponents(
                bannerContent?.copyrightParagraph as any
              )}
            </h4>
          </div>
        )}
      </div>

      {/* Desktop view */}
      <div className="hidden lg:flex relative sky-orange-coral-gradient">
        <div
          className="hidden lg:flex relative d-flex  bg-center bg-cover"
          style={{
            backgroundImage: `url(${bannerContent?.heroImage?.url})`,
            height: "588px",
            width: "66%",
            margin: "2rem 2rem 2rem -10rem",
            clipPath: "polygon(0 0, 100% 0, 82% 100%, 0 100%, 0% 38%)",
          }}
        ></div>
        <SingleBannerThirdWidthComponent
          bannerContent={bannerContent}
          contentWidth="40rem"
          contentAlign="right-1"
        />
        <div className="absolute font-bold text-white lg:top-48 lg:left-4 md:top-48 md:left-2">
          {bannerContent?.lozengeText && (
            <LozengeText lozengeText={bannerContent?.lozengeText} />
          )}
          <div className="md:sky-h1 text-white">
            {documentToReactComponents(bannerContent?.heading as any)}
          </div>
        </div>
      </div>
      {/* Tablet view */}
      <div
        className="hidden md:flex lg:hidden relative sky-dark-blue-gradient"
        style={{ height: "588px" }}
      >
        <div
          className="hidden md:flex bg-no-repeat bg-cover lg:hidden relative"
          style={{
            backgroundImage: `url(${bannerContent?.heroImage?.url})`,
            width: "110%",
            height: "420px",
            marginLeft: "-6%",
            marginTop: "15px",
            clipPath: "polygon(10% 0%, 100% 0%, 90% 100%, 0% 100%)",
            backgroundSize: "cover",
          }}
        ></div>
        <SingleBannerThirdWidthComponent
          bannerContent={bannerContent}
          contentWidth="32rem"
          contentAlign="left-2"
        />
        <div
          className="absolute font-bold text-white  md:left-2"
          style={{ top: "10rem" }}
        >
          {bannerContent?.lozengeText && (
            <LozengeText lozengeText={bannerContent?.lozengeText} />
          )}
          <div className="sky-h2 md:sky-h3 text-white">
            {documentToReactComponents(bannerContent?.heading as any)}
          </div>
        </div>
      </div>
    </>
  );
};
