import { useNavigate } from "react-router-dom";
interface links {
  itemUrl: string;
  publicName: string;
}
interface menuSections {
  slug?: string;
  itemUrl?: string;
  sectionName?: string;
  name?: string;
  sectionLinks?: links[];
}

interface sitemap {
  navigationItem?: {
    name?: string;
    itemUrl?: string;
  }
  menuSections?: menuSections[];
}
interface Sitemaps {
  value?: sitemap
}
export const Sitemap = ({ value }: Sitemaps) => {
  let navigate = useNavigate();
  return (
    // Appending site map list items and sub list items
    <div className="ml-20 text-lg mt-[2%]">

      {value && value?.navigationItem && (
        <ul className="sitemapUL">
          <li className=" cursor-pointer hover:underline  "
            // onClick={() => (navigate(`${value?.navigationItem?.itemUrl}`))}
            onClick={(e) => {
              e.preventDefault();
              const link = value?.navigationItem?.itemUrl;
              if (
                link &&
                (link.startsWith("http://") ||
                  link.startsWith("https://") ||
                  link.includes("."))
              ) {
                window.location.href = link
              } else if (link) {
                navigate(`/${link}`)
              }
            }}
            key={value?.navigationItem?.name}>
            <p className="sitemapColor">{value?.navigationItem?.name}</p>
            </li>
            {value && value?.menuSections?.map((item: menuSections) => (
              <ul className="sitemapUL">
                <li className=" cursor-pointer hover:underline  "
                  // onClick={() => (navigate((item?.slug ? item?.slug : item?.itemUrl) as string))}
                  onClick={(e) => {
                    e.preventDefault();
                    const link = item?.slug ? item?.slug : item?.itemUrl;
                    if (
                      link &&
                      (link.startsWith("http://") ||
                        link.startsWith("https://") ||
                        link.includes("."))
                    ) {
                      window.location.href = link
                    } else if (link) {
                      navigate((item?.slug ? item?.slug : item?.itemUrl) as string)
                    }
                  }}
                  key={item?.sectionName ? item?.sectionName : item?.name}>
                  <p className="sitemapColor">{item?.sectionName ? item?.sectionName : item?.name}</p></li>
                <ul className="sitemapUL">{item?.sectionLinks && item?.sectionLinks?.map((sub: any) => {
                  return(
                    <>
                    {sub && sub?.sectionName && (
                      <>
                      <li className=" cursor-pointer hover:underline  "
                        // onClick={() => (navigate((sub?.slug ? sub?.slug : sub?.itemUrl) as string))}
                        onClick={(e) => {
                          e.preventDefault();
                          const link = sub?.slug ? sub?.slug : sub?.itemUrl;
                          if (
                            link &&
                            (link.startsWith("http://") ||
                              link.startsWith("https://") ||
                              link.includes("."))
                          ) {
                            window.location.href = link;
                          } else if (link) {
                            navigate((sub?.slug ? sub?.slug : sub?.itemUrl) as string)
                          }
                        }}
                        key={sub?.sectionName ? sub?.sectionName : sub?.publicName}>
                        <p className="sitemapColor">{sub?.sectionName ? sub?.sectionName : sub?.publicName}</p></li>
                    <ul className="sitemapUL">{sub?.sectionLinks && sub?.sectionLinks?.map((nested: any) => {
                      return (
                        <>
                        {nested && nested?.sectionName && (
                          <>
                          <li className=" cursor-pointer hover:underline  "
                            // onClick={() => (navigate((nested?.slug ? nested?.slug : nested?.itemUrl) as string))}
                            onClick={(e) => {
                              e.preventDefault();
                              const link = nested?.slug ? nested?.slug : nested?.itemUrl;
                              if (
                                link &&
                                (link.startsWith("http://") ||
                                  link.startsWith("https://") ||
                                  link.includes("."))
                              ) {
                                window.location.href = link;
                              } else if (link) {
                                navigate((nested?.slug ? nested?.slug : nested?.itemUrl) as string)
                              }
                            }}
                            key={nested?.sectionName ? nested?.sectionName : nested?.publicName}>
                            <p className="sitemapColor">{nested?.sectionName ? nested?.sectionName : nested?.publicName}</p></li>
                        <ul className="sitemapUL">{nested?.sectionLinks && nested?.sectionLinks?.map((sectionItem: any) => {
                          return <li className=" cursor-pointer hover:underline  "
                            // onClick={() => (navigate(`/${sectionItem?.itemUrl}`))}
                            onClick={(e) => {
                              e.preventDefault();
                              const link = sectionItem?.itemUrl;
                              if (
                                link &&
                                (link.startsWith("http://") ||
                                  link.startsWith("https://") ||
                                  link.includes("."))
                              ) {
                                window.location.href = sectionItem?.itemUrl
                              } else if (link) {
                                navigate(`/${sectionItem?.itemUrl}`)
                              }
                            }}
                            key={sectionItem?.name}>
                            <p className="sitemapColor">{sectionItem?.name}</p></li>;
        
        
                        })}</ul></>
                      )}{nested?.name &&
                        <li className=" cursor-pointer hover:underline  "
                        // onClick={() => (navigate(`/${nested?.itemUrl}`))}
                        onClick={(e) => {
                          e.preventDefault();
                          const link = nested?.itemUrl;
                          if (
                            link &&
                            (link.startsWith("http://") ||
                              link.startsWith("https://") ||
                              link.includes("."))
                          ) {
                            window.location.href = nested?.itemUrl
                          } else if (link) {
                            navigate(`/${nested?.itemUrl}`)
                          }
                        }}
                        key={nested?.name}>
                        <p className="sitemapColor">{nested?.name}</p></li>
                      }
                        </>
                      );
    
    
                    })}</ul></>
                  )}{sub?.name &&
                    <li className=" cursor-pointer hover:underline  "
                    // onClick={() => (navigate(`/${sub?.itemUrl}`))}
                    onClick={(e) => {
                      e.preventDefault();
                      const link = sub?.itemUrl;
                      if (
                        link &&
                        (link.startsWith("http://") ||
                          link.startsWith("https://") ||
                          link.includes("."))
                      ) {
                        window.location.href = sub?.itemUrl
                      } else if (link) {
                        navigate(`/${sub?.itemUrl}`)
                      }
                    }}
                    key={sub?.name}>
                    <p className="sitemapColor">{sub?.name}</p></li>
                  }
                    </>
                  )
                  
                })}</ul>
              </ul>
            ))}
        </ul>
      )}
    </div>
  )
}