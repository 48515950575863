import React from 'react';
import { Helmet } from 'react-helmet';

export const VideoSrcScript = ({ brightCoveVideoExperienceId }: { brightCoveVideoExperienceId?: string }) => {
  return (
    <>
      <Helmet>
        <script
          data-testid="video-script"
          src={`https://players.brightcove.net/6122285394001/experience_${brightCoveVideoExperienceId}/live.js`}>
          </script>
      </Helmet>
    </>
  );
};
