import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import "./App.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import {
  HttpLink,
  ApolloClient,
  ApolloLink,
  InMemoryCache,
  ApolloProvider,
  concat,
} from "@apollo/client";

import { BrowserRouter, BrowserRouter as Router } from "react-router-dom";
import { Root } from "./root";
import {GRAPHQL_URL ,DOMAIN,ClientId,AUTH0_LOGOUT_URL} from "./config"
const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
const client = new ApolloClient({
  uri: GRAPHQL_URL,
  //uri:"http://localhost:4000/graphql",
  cache: new InMemoryCache(),
  connectToDevTools: process.env.REACT_APP_ENV === 'DEV' ? true : false,
  // link: concat(headersMiddleware, httpLink),
});

root.render(
  <React.StrictMode>
    <ApolloProvider client={client}>
      <BrowserRouter>
        <Root />
      </BrowserRouter>
    </ApolloProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
