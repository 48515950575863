import React, { useEffect, useState } from "react";
import { DocumentToReactComponents } from "./DocumentToReactComponents";
import { useLocation } from "react-router-dom";

interface LinkDoc {
  file: string;
}

interface Props {
  data: {
    heading: string;
    date: string;
    linkText: string;
    linkDocument: LinkDoc;
    linkUrl: string;
    articleType: string;
  }[];
  searchText: string;
  articlesPerPage: number;
  description: string;
}

const TableWithPaginationAndDownload: React.FC<Props> = ({
  data,
  searchText,
  articlesPerPage,
  description,
}) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(articlesPerPage);
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const filteredData = data
  .filter((item) => {
    return true; 
  })
  .sort((a, b) => {
    if (searchText === "corporate_governance") {
      return a.heading.localeCompare(b.heading); 
    } else {
      return new Date(b.date).getTime() - new Date(a.date).getTime();
    }
  })
  .slice(0, searchText === "investor_centre" ? 10 : data.length) 


  // filter by year if want use this
  
  // const filteredData = data
  //   .filter((item) => {
  //     if (searchText === "investor_centre") {
  //       return new Date(item.date).getFullYear() === new Date().getFullYear();
  //     } else {
  //       return true;
  //     }
  //     return false;
  //   })
  //   .sort((a, b) => {
  //     if(searchText === "corporate_governance") {
  //      return a.heading.localeCompare(b.heading); // Example sorting by 'heading'
  //     }else {
  //       return new Date(b.date).getTime() - new Date(a.date).getTime()
  //     }  
  //   });

  // sort by month if want use this

  // const filteredData = data
  // .filter(item => {
  //   if (searchText === "investor_centre") {
  //     return new Date(item.date).getFullYear() === new Date().getFullYear();
  //   }
  //   return true;
  // })
  // .sort((a, b) => {
  //   const dateA = new Date(a.date);
  //   const dateB = new Date(b.date);

  //   if (dateA.getFullYear() !== dateB.getFullYear()) {
  //     return dateA.getFullYear() - dateB.getFullYear();
  //   }

  //   return dateA.getMonth() - dateB.getMonth();
  // });

  // Calculate total pages
  const totalPages = Math.ceil(filteredData.length / itemsPerPage);

  // Slice data for the current page
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const currentData = filteredData.slice(startIndex, endIndex);

  // Handle page change
  const handlePageChange = (page: number) => {
    setCurrentPage(page);
    setDropdownOpen(false); // Close the dropdown after selection
  };

  // Handle items per page change
  const handleItemsPerPageChange = (
    e: React.ChangeEvent<HTMLSelectElement>
  ) => {
    setItemsPerPage(parseInt(e.target.value, articlesPerPage));
    setCurrentPage(1);
  };

  // Toggle dropdown open/close
  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
  };

  const { pathname } = useLocation();

  const [isMobile, setIsMobile] = useState(false);
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const slug = pathname.replace("/", "");
  return (
    <div className="table-div max-w-7xl my-8 mx-auto pl-2 md:px-4 font-sans">
      {slug === "investor-centre" && description && description ? (
        <div
          className={
            description
              ? "text-center text-5xl richTextTable"
              : "text-center text-5xl"
          }
          style={{ paddingTop: "1rem", width: "30%" }}
        >
          <DocumentToReactComponents richtextEditorProps={description as any} />
        </div>
      ) : (
        ""
      )}
      <table
        className="table-auto w-full"
        style={{
          width: description && slug === "investor-centre" ? "74%" : "",
          marginLeft:
            description && slug === "investor-centre" && isMobile
              ? "4rem"
              : description && slug === "investor-centre"
              ? "24rem"
              : "",
          marginTop:
            description && slug === "investor-centre" && isMobile
              ? "1rem"
              : description && slug === "investor-centre"
              ? "-6rem"
              : "",
        }}
      >
        <tbody>
          {currentData.map((row, index) => {
            if (row?.linkText === null && searchText === null) return null;

            const isEvenRow = index % 2 === 0;
            const rowStyle = {
              backgroundColor: isEvenRow ? "#F5F5F5" : "",
              border: isEvenRow ? "1px solid #F5F5F5" : "",
              fontFamily: "MarkPro-Book SKY",
            };

            return (
              <tr key={index}>
                <td
                  className="px-4 py-3 align-middle sky-h7 md:sky-h7"
                  style={{ ...rowStyle, width: "20%" }}
                >
                  {row?.date}
                </td>
                <td
                  className="px-4 py-3 align-middle sky-h7 md:sky-h7"
                  style={rowStyle}
                >
                  {row?.heading}
                </td>
                <td
                  className="px-4 py-3 align-middle sky-h7 md:sky-h7"
                  style={{
                    ...rowStyle,
                    color: "#0b5fff",
                    cursor: "pointer",
                    fontSize: "16px",
                    width: row?.linkText === "View More" ? "15%" : "",
                  }}
                >
                  {row?.linkText === "View More" ? (
                    <button
                      onClick={(e) => {
                        e.preventDefault();
                        window.open(row?.linkUrl);
                      }}
                    >
                      {row?.linkText}
                    </button>
                  ) : (
                    <a
                      href={
                        row?.linkUrl ? row?.linkUrl : row?.linkDocument?.file
                      }
                      download
                      onClick={(e) => {
                        e.preventDefault();
                        window.open(
                          row?.linkUrl ? row?.linkUrl : row?.linkDocument?.file
                        );
                      }}
                    >
                      {row?.linkText}
                    </a>
                  )}
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>

      {totalPages > 1 && (
        <div className="pagination sky-h7 md:sky-h7">
          <div
            className="custom-select"
            onClick={toggleDropdown}
            onBlur={() => setDropdownOpen(false)}
            tabIndex={0}
          >
            <span
              style={{
                fontWeight: "600",
                fontSize: "18px",
                color: "#6b6c7e",
              }}
            >
              Page {currentPage} of {totalPages}
              <i
                className="lfr-icon-menu-arrow caretIcon1"
                style={{
                  display: "inline-block",
                  height: 0,
                  marginLeft: "5px",
                  verticalAlign: "middle",
                  width: 0,
                  borderTop: ".3em solid",
                  borderLeft: ".3em solid transparent",
                  borderRight: " .3em solid transparent",
                }}
              ></i>
            </span>
            {dropdownOpen && (
              <ul
                className="page-list"
                style={{
                  maxHeight: totalPages > 6 ? "15rem" : "none",
                  overflowY: totalPages > 6 ? "scroll" : "visible",
                }}
              >
                {Array.from({ length: totalPages }, (_, index) => (
                  <li
                    key={index + 1}
                    className={currentPage === index + 1 ? "active" : ""}
                    onClick={() => handlePageChange(index + 1)}
                  >
                    {index + 1}
                  </li>
                ))}
              </ul>
            )}
          </div>
          <div className="pagination-controls">
            <button
              disabled={currentPage === 1}
              onClick={() => handlePageChange(1)}
              style={{
                color: currentPage === 1 ? "#6b6c7e" : "#0b5fff",
              }}
              className="pagination-button fisrt-button"
            >
              ← First
              {currentPage === 1 && <i className="disabled-icon"></i>}{" "}
              {/* Use your icon here */}
            </button>
            <button
              disabled={currentPage === 1}
              onClick={() => handlePageChange(currentPage - 1)}
              style={{
                color: currentPage === 1 ? "#6b6c7e" : "#0b5fff",
              }}
              className="pagination-button"
            >
              Previous
              {currentPage === 1 && <i className="disabled-icon"></i>}
            </button>
            <button
              style={{
                color: currentPage === totalPages ? "#6b6c7e" : "#0b5fff",
              }}
              onClick={() => handlePageChange(currentPage + 1)}
              disabled={currentPage === totalPages}
              className="pagination-button"
            >
              Next
              {currentPage === totalPages && <i className="disabled-icon"></i>}
            </button>
            <button
              style={{
                color: currentPage === totalPages ? "#6b6c7e" : "#0b5fff",
              }}
              disabled={currentPage === totalPages}
              onClick={() => handlePageChange(totalPages)}
              className="pagination-button fisrt-button"
            >
              Last →
              {currentPage === totalPages && <i className="disabled-icon"></i>}
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default TableWithPaginationAndDownload;
