import React, { useEffect } from "react";

import { caret } from "../icon";
import { SocialButtons } from "../social";
import { NavigationLinks, Link } from "../../types";
import { useQuery } from "@apollo/client";
import { getMenuSectionListQuery } from "../../graphql/queries/getMenuList";
import { tags } from "../../config";

interface composePageSubMenuDataProps {
  name: string;
  itemUrl: string;
  itemIcon: string;
  internalName: string;
}

interface link {
  itemIcon: string | undefined;
  slug: null;
  itemUrl: string;
  sectionName: string;
  sectionLinks: [composePageSubMenuDataProps];
}

interface footerCopyrightText {
  footerCopyright:string
}
export let Footer = () => {

  const footerGradient = {
    background: "linear-gradient(to right, #00013A, #1C9AD6)",
  };

  const { data: GlobalmenuSectionList } = useQuery<any>(
    getMenuSectionListQuery,
    {
      variables: {
        usePreview: false,
        tags: tags?.globalFooter,
      },
      fetchPolicy: "cache-and-network",
    }
  );

  const composePageSubMenuDataNew: link[] =
    GlobalmenuSectionList?.GlobalMenuList?.[0]?.menuSections;

  const footerCopyright:footerCopyrightText = GlobalmenuSectionList?.GlobalMenuList?.[0]?.footerCopyright.content[0]?.content[0]?.value

  useEffect(() => {
    // https://shielded.co.nz/
    let shielded = (window as any).ds07o6pcmkorn;
    if (shielded) {
      let frameName = new shielded({
        openElementId: "#shielded-logo",
      });
      frameName.init();
    }
  }, []);

  return (
    <footer className="bg-white pb-16 md:pb-0 border-t border-gray-dark">
      <div className="text-blue-darker block lg:flex">
        <div className="flex-col relative flex-1 text-blue-darker block lg:flex">
          <div
            className="flex relative bottom-1 md:mx-6 lg:mx-0 bottom-1 lg:mb-4 lg:mr-13 lg:ml-0"
            style={{ height: 300 }}
          >
            {composePageSubMenuDataNew && <div style={{ minWidth: 300 }} className="hidden lg:block ">
              <img
                className="h-full"
                src="https://static.sky.co.nz/sky/logos/footer-logo-new.svg"
                alt=""
              />
            </div>}
            <div className="flex flex-wrap md:flex-no-wrap flex-1">
              {/* desktop view */}
              <div className="hidden md:flex flex-wrap w-full justify-between">
                <ul
                  data-testid="links"
                  className="flex flex-row justify-between flex-no-wrap w-9/14"
                >
                  {composePageSubMenuDataNew?.map((link) => {
                    if (
                      link?.slug != "social_media" &&
                      link?.slug != "terms_list"
                    ) {
                      return (
                        <li
                          key={link?.sectionName}
                          className="pt-8 pb-7 md:sky-sub w-1/4"
                        >
                          <p className="py-2 font-bold">{link?.sectionName}</p>
                          <ul className="py-2">
                            {link?.sectionLinks?.map((subLink) => {
                              return (
                                <li
                                  key={subLink?.name}
                                  className="pt-2 font-book"
                                >
                                  <a href={subLink?.itemUrl}>{subLink?.name}</a>
                                </li>
                              );
                            })}
                          </ul>
                        </li>
                      );
                    }
                  })}
                </ul>
                <div className="flex flex-col pt-8 pr-0 pb-7 w-3/14 flex-grow justify-between items-end">
                  <div data-testid="social-links" className="flex justify-end">
                    <SocialButtons link={composePageSubMenuDataNew} />
                  </div>
                  <div>
                    <div className="footer-placeholder"></div>
                    <img
                      alt="shielded"
                      id="shielded-logo"
                      height="45"
                      width="45"
                      style={{
                        cursor: "pointer",
                        margin: "0px auto",
                        display: "inherit",
                      }}
                      src="https://shielded.co.nz/img/custom-logo.png"
                    />
                  </div>
                </div>
              </div>
              {/* mobile view */}
              <div className="block md:hidden w-full ">
                {composePageSubMenuDataNew?.map((link,i) => {
                  if (
                    link?.slug != "social_media" &&
                    link?.slug != "terms_list"
                  ) {
                    return (
                      <div
                        key={link?.sectionName}
                        className="relative text-xl mx-6"
                      >
                        <label
                          className="block py-6 font-bold border-opacity-40 border-b border-gray-dark"
                          htmlFor={'toggle' + i}
                        >
                          {link?.sectionName}
                        </label>
                        <input
                          id={'toggle' + i}
                          type="checkbox"
                          className="absolute toggle opacity-0"
                        />
                        <div className="caret w-8 h-8 my-6 absolute top-0 right-0 pointer-events-none" style={{width:"3rem"}}>
                          {caret}
                        </div>
                        <ul className="ml-2 p-1">
                          { link?.sectionName && link?.sectionLinks?.map((subLink) => {
                            return (
                              <li key={subLink?.name} className="text-base">
                                <a href={subLink?.itemUrl} target="_blank" rel="noopener noreferrer">{subLink?.name}</a>
                              </li>
                            );
                          })}
                        </ul>
                      </div>
                    );
                  }
                })}
                <div className=" flex justify-around md:justify-end py-5">
                  <SocialButtons link={composePageSubMenuDataNew} />
                </div>
                <div className="p-3">
                  <img
                    alt="shielded"
                    id="shielded-logo"
                    height="40"
                    width="40"
                    style={{
                      cursor: "pointer",
                      margin: "0px auto",
                      display: "inherit",
                    }}
                    src="https://shielded.co.nz/img/custom-logo.png"
                  />
                </div>
                <div className="pt-0">
                  <div
                    className="border-t py-4 border-gray-dark flex flex-wrap justify-start md:justify-end flex-row
                      md:flex-row-reverse"
                    style={{ ...footerGradient }}
                    id="footerTermsAndCond"
                  >
                    <ul className="flex w-full py-3 md:w-auto justify-between">
                      {composePageSubMenuDataNew?.map((link) => {
                        if (link?.slug === "terms_list" && link?.sectionLinks) {
                          return link?.sectionLinks?.map((subLink) => (
                            <li
                              key={subLink?.name}
                              className="sky-sub font-book px-0 md:px-6 lg:px-10 xl:px-12 text-white mx-4"
                            >
                              <a href={subLink?.itemUrl}>{subLink?.name}</a>
                            </li>
                          ));
                        } else {
                          // Handle cases where link.slug !== "terms_list" or sectionLinks is undefined
                          return null;
                        }
                      })}
                    </ul>
                    <div className="pt-4 pb-3 whitespace-no-wrap sky-sub font-book text-white mx-4">
                      { GlobalmenuSectionList?.GlobalMenuList?.[0]?.footerCopyright.content[0]?.content[0]?.value}
                    </div>
                    <div className="footer-placeholder"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="hidden md:flex justify-between absolute w-full bottom-0">
            <div
              className="flex justify-between w-full border-t mt-3 pt-3 border-gray-dark"
              style={{ ...footerGradient }}
            >
              <div className="md:sky-sub whitespace-no-wrap w-full ml-16 flex-1 text-white">
               { GlobalmenuSectionList?.GlobalMenuList?.[0]?.footerCopyright.content[0]?.content[0]?.value}
              </div>
              <ul className="flex flex-1 flex-row justify-end flex-no-wrap mr-14 pb-2 text-white">
                {composePageSubMenuDataNew?.map((link) => {
                  if (link?.slug === "terms_list" && link?.sectionLinks) {
                    return link?.sectionLinks?.map((subLink) => (
                      <li
                        key={subLink?.name}
                        className="md:sky-sub font-book ml-4"
                      >
                        <a href={subLink?.itemUrl}>{subLink?.name}</a>
                      </li>
                    ));
                  } else {
                    // Handle cases where link.slug !== "terms_list" or sectionLinks is undefined
                    return null;
                  }
                })}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};
function useState(arg0: string): [any, any] {
  throw new Error("Function not implemented.");
}

