import * as React from 'react';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { HomePageProductCard } from './homepageproductcard';
import Slider from 'react-slick';
// import { NextIcon } from '../icon';
import { ReactComponent as NextIcon } from '../../assets/icon_right_arrow.svg';
import { ReactComponent as PrevIcon } from '../../assets/icon_left_arrow.svg'
interface CardPanelProps {
  module: any;
  // sendProduct: any;
}

// const CardPanel: React.FC<CardPanelProps> = ({ module, sendProduct }) => {
  const CardPanel: React.FC<CardPanelProps> = ({ module}) => {

  React.useEffect(() => {
    const mobile = document.querySelectorAll('.scroll-on-mobile');
    mobile.forEach((item) => {
      item.scrollLeft = 300;
    });
  }, []);

  const settings = {
    infinite: false,
    speed: 500,
    slidesToShow: 3.1,
    slidesToScroll: 1,
    initialSlide: 0,
    // nextArrow: <NextArrow />,
    // prevArrow: <PrevArrow />,
    responsive: [
      {
        breakpoint: 1100,
        settings: {
          slidesToShow: 2.2,
          slidesToScroll: 2,
          initialSlide: 1,
          arrows: false,
          dots: true,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1.05,
          slidesToScroll: 1,
          initialSlide: 1,
          arrows: false,
          dots: true,
        },
      },
    ],
  };

  function NextArrow(props: any) {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{
          ...style,
          display: 'block',
          zIndex: 50,
          position: 'absolute',
          right: -35,
          top: 260,
        }}
        onClick={onClick}
      >
        <NextIcon />
      </div>
    );
  }

  function PrevArrow(props: any) {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{
          ...style,
          display: 'block',
          zIndex: 50,
          position: 'absolute',
          left: -80,
          top: 260,
        }}
        onClick={onClick}
      >
        <PrevIcon />
      </div>
    );
  }

  return (
    <section className="section-card-panel pt-[40px] pl-[20px] pr-[0px] lg:px-[30px] mx-auto max-w-[1280px]">
      <div className="text-center font-skyBlack text-black" style={{fontWeight: 'bolder',fontSize:"48px"}}>
        {documentToReactComponents(module?.cardPanelHeading)}
      </div>
      <div className="product-slider slider max-w-[1200px] mx-auto">
        <Slider {...settings}>
          {module?.cardsToShow.map((cardData: any, index: number) => {
            // const cardData = card.fields;
            function sendProduct(arg0: string, arg1: string, arg2: string, title: any, title1: any, title2: any, price: any, arg7: number, sku: any, arg9: any): void {
              throw new Error('Function not implemented.');
            }

            return (
              <div
                className="slide px-sky-sm flex h-full pt-14 pl-0 "
                key={index}
                style={{ marginTop: "10px" }}
              >
                {cardData?.lozengeText ? (
                  <div
                    className="font-skyBook text-white relative"
                    style={{
                      background: "linear-gradient(6deg, #254ab3, #f94cb1, #ff9e1c )",
                      fontSize: "20px",
                      lineHeight: "24px",
                      textAlign: "center",
                      padding: "8px",
                      fontWeight: 'bold',
                      borderRadius: "8px 8px 0px 0px",
                      height: '40px',
                      top: '-37px',
                      zIndex: 1,
                    }}
                  >{cardData?.lozengeText}</div>
                ) : null}
                <HomePageProductCard
                  // kkService={null as any}
                  handleProductCardCTA={() => sendProduct("Product Added", "acquisition", "DTH", cardData?.title, cardData?.title, cardData?.title, cardData?.price, 1, cardData?.sku, cardData?.type[0])}
                  card={{
                    addLink: cardData?.addLink,
                    bgColor: cardData?.bgColor ? cardData?.bgColor : '#FFFFFF',
                    billingFrequency: cardData?.priceFrequencyType[0],
                    thumbnail: {
                      text: cardData?.thumbnail?.text ?? '',
                      url: cardData?.thumbnail?.url ?? '',
                    },
                    lozengeText: cardData?.lozengeText,
                    sku: cardData?.sku,
                    subtitle: cardData?.description,
                    discountedPrice: cardData?.acquistionOfferPrice ?? cardData?.price,
                    title: cardData?.title,
                    type: cardData?.type,
                    coupon: null,
                    termsAndConditions: cardData?.termsAndConditionsCaption,
                    descriptionList: cardData?.detailsList,
                    copyrightText: cardData?.copyrightText,
                    ctaName: cardData?.ctaName,
                    detailsText:cardData?.detailsText,
                    // iconList: cardData.iconList?.map((icon: any) => ({
                    //   url: icon?.fields?.file?.url ?? '',
                    //   text: icon?.fields?.file?.text ?? '',
                    // })),
                    detailsLink:cardData?.detailsLink,
                    discountText: cardData?.discountText,
                    fromPrice: cardData?.acquistionOfferPrice ? cardData?.price : null,
                    addLinkText:cardData?.addLinkText,
                  }}
                />
              </div>
            ) ;
          })}
        </Slider>
      </div>    
      <div className="text-[12px] mb-[20px] lg:mb-[64px] mt-[40px] lg:mt-[50px] w-full max-w-[85vw]">
        {documentToReactComponents(module?.termsAndConditions)}
      </div>
    </section>
  );
};

export default CardPanel;
