import { HomePagePromotionalCard, HomePagePromotionaCardDetails } from './homepagepromotionalcard';
// import {
//     IPopUpContentConfig,
//     JSONConfigService,
//     KonakartService,
//     T_PlanSelector,
//     T_Product,
//     redirectToSubdomain
//   } from '@sky-tv-group/shared';

// import { Button } from '@sky-tv-group/components'

export interface HomePageProductCardProps {
    card: HomePagePromotionaCardDetails;
    bgColor?: string;
    disabled?: boolean;
    subscribed?: boolean;
    fixedWidth?: number;
    isStarterInCart?: boolean;
    handleProductCardCTA?: () => void;
    compactMode?: boolean;
    inCart?: boolean;
    selectedTuiAddress?: string;
    discountText?: string;
  }

export function HomePageProductCard({
    card,
    bgColor,
    compactMode,
    fixedWidth,
    handleProductCardCTA
  }: HomePageProductCardProps) {
    const productDetails = {
      bgColor: bgColor ?? card.bgColor ?? '#1F0124',
      billingFrequency: card?.billingFrequency,
      campaign: card?.campaign,
      disclaimer: card.disclaimer,
      discountText: card.discountText,
      discountedPrice: card.discountedPrice,
      fromPrice: card.fromPrice,
      thumbnail: {
        text: card?.thumbnail?.text ?? '',
        url: card?.thumbnail?.url ?? '',
      },
      lozengeText: card?.lozengeText,
      sku: card?.sku,
      occurrenceType: card?.occurrenceType,
      subtitle: card.subtitle,
      termsAndConditions: card.termsAndConditions,
      title: card.title,
      type: card?.type,
      coupon: card?.coupon,
      descriptionList: card?.descriptionList,
      detailsText:card?.detailsText,
      detailsLink:card?.detailsLink,
      // iconList: card.iconList,
      copyrightText: card?.copyrightText,
      ctaName: card?.ctaName,
      addLink:card?.addLink,
      addLinkText:card?.addLinkText,
    };
  
    return (
      <HomePagePromotionalCard
        cardType="PRODUCT"
        compactMode={compactMode}
        card={productDetails}
        buttons={
          <div className={`flex flex-row ${compactMode ? '' : 'mt-sky-lg'} w-full p-5 justify-center`}>
          {productDetails?.detailsText && (
            <a
            className = "sky-hp-button sky-button font-bold  sky-button--secondary-light btn btn-nm py-4 rounded-full"

            // className='sky-hp-button sky-button sky-button--secondary-light btn btn-nm sky-h7 font-bold py-4 '
              style = {{ width: '100%',cursor:"pointer",minWidth:"6rem"}}
              onClick={(e) => {
                e.preventDefault();
                const link = productDetails?.detailsLink;
                if (
                  link &&
                  (link.startsWith("http://") ||
                    link.startsWith("https://") ||
                    link.includes("."))
                ) {
                  window.open(link, "_blank", "noopener,noreferrer");
                } else if (link) {
                  window.location.href = link
                }
              }}
            >
              {productDetails?.detailsText}
            </a>
          )}

            {card?.addLink &&<a
              className = "sky-hp-button sky-button font-bold px-4 sky-bg-daylight text-white py-4 rounded-full"
              style = {{width: '100%',minWidth:"6rem"}}
              onClick={() => {
                if (card?.addLink) {
                  window.open(card?.addLink, '_blank')?.focus();
                }
              }}>
               {productDetails?.addLinkText}
            </a>}
        </div>
        }
        bgColor={card?.bgColor ?? bgColor}
        fixedWidth={fixedWidth}
      />
    );
  }