import { gql } from '@apollo/client';
import { menuList, } from '../fragments/menuList';

export const getMenuSectionListQuery = gql`
query GetMenuListItemheaderData ($usePreview: Boolean, $tags: String) {
  GlobalMenuList(usePreview: $usePreview,tags:$tags) {
    internalName  
    footerCopyright
    menuSections{
      slug
      sectionName
      sectionLinks {
        name
        internalName
        itemUrl
        itemIcon
      }
     }
  }
}`;
 
export const getMenuSectionListMobileQuery = gql`
query GetMenuListItemheaderData ($usePreview: Boolean, $tags: String) {
  GlobalMenuList(usePreview: $usePreview,tags:$tags) {
    internalName  
    footerCopyright
    menuSections{
      slug
      sectionName
      publicName
      sectionLinks {
        sectionName
        name
        internalName
        itemUrl
        itemIcon
        sectionLinks {
         itemUrl
          name
        }
      }
     }
  }
}`;