import React, { ReactNode, useEffect, useRef, useState } from "react";
import { Document, BLOCKS, INLINES, MARKS } from "@contentful/rich-text-types";
import {
  Options,
  documentToReactComponents,
} from "@contentful/rich-text-react-renderer";

interface ContentProps {
  value: Document;
  heading: string;
  isRelatedContent?: boolean;
  path?: string;
}

export const DisplayContent = ({
  value,
  heading,
  isRelatedContent,
  path,
}: ContentProps) => {
  const targetRef = useRef<HTMLParagraphElement | null>(null);
  const [isMobile, setIsMobile] = useState(false);
useEffect(() => {
  const handleResize = () => {
    setIsMobile(window.innerWidth <= 768);
  };
  handleResize();
  window.addEventListener("resize", handleResize);
  return () => {
    window.removeEventListener("resize", handleResize);
  };
}, []);
  let tableProps = {
    style: { borderWidth: "1px", fontSize: "1.125rem", },
    className: `${
      window.location.href?.includes("terms-and-conditions")
        ? "border-none"
        : ""
    } border-collapse border-black text-left`,
  };

  let relatedTableProps = {
    style: { borderWidth: "none" },
    className: "text-relatedOrange border-none border-collapse text-left",
  };

  let relatedTablePropsCell = {
    style: { borderWidth: "1px" },
    className:
      " text-darkText border-none border-collapse border-black text-left",
  };
  let aboutBillClass =
    "border-separate border-spacing-1 border border-black text-left";

  const tableBodyStyle = {
    style: { ...tableProps.style },
    className: `${
      isRelatedContent || path?.includes("terms-and-conditions")
        ? relatedTablePropsCell.className
        : path?.includes("bill")
        ? aboutBillClass
        : tableProps.className
    } ${path?.includes("bill") ? "" : "py-2 mt-[30px]"}`,
  };
  let paraStyle = "text-[1.1rem]";
  const renderOptions: Options = {
    renderText: (text: string) =>
      text
        .split("\n")
        .reduce(
          (children: any, textSegment: any, index: number) => [
            ...children,
            index > 0 && <br key={index} />,
            textSegment,
          ],
          []
        ),

    renderMark: {
      [MARKS.BOLD]: (text: ReactNode) => <b>{text}</b>,
    },
    renderNode: {
      [BLOCKS.HEADING_1]: (_, children: ReactNode) => (
        <h1 className="text-darkText font-semibold text-[2.6rem]">
          {children}
        </h1>
      ),
      [BLOCKS.HEADING_3]: (_, children: ReactNode) => (
        <h3 className="text-[1.2rem] text-darkText font-semibold mb-2 mt-4">
          {children}
        </h3>
      ),
      [BLOCKS.HEADING_4]: (_, children: ReactNode) => (
        <h4 className="py-2 text-darkText mx-auto text-xl mt-5  font-bold">
          {children}
        </h4>
      ),
      [BLOCKS.HEADING_6]: (_, children: ReactNode) => (
        <h6 className="mx-auto" style={{ fontSize: "9pt" }}>
          {children}
        </h6>
      ),
      [BLOCKS.PARAGRAPH]: (_, children: ReactNode) => (
        <p
          className={
            !isRelatedContent ? `text-darkText text-[1.3rem]` : paraStyle
          }

          ref={targetRef}
        >
          {children}
        </p>
      ),
      [INLINES.HYPERLINK]: (node: any, children: ReactNode) => (
        <a
          href={node?.data?.uri}
          onClick={(e) => {
            e.preventDefault();
            const url = node?.data?.uri;

            if (
              url &&
              (url.startsWith("http://") ||
                url.startsWith("https://") ||
                url.includes("."))
            ) {
              window.open(url, "_blank", "noopener,noreferrer");
            } else if (
              url &&
              (url.startsWith("#_ftn") || url.startsWith("#_ftnref"))
            ) {
              const targetElement = targetRef.current;
              if (targetElement) {
                targetElement.scrollIntoView({
                  behavior: "smooth",
                  block: url.startsWith("#_ftnref") ? "end" : "start",
                });
                window.location.href = url;
              }
            } else {
              window.location.href = url;
            }
          }}
          className="sky-text-daylight"
          style={{
            color: "#0b5fff",
            fontSize: "16px",
          }}
        >
          {children}
        </a>
      ),
      [BLOCKS.OL_LIST]: (_, children: ReactNode) => (
        <div className="desk:px-6 pb-8 lg:px-0 mx-auto">
          <ol className="text-lg text-black orderedList desk:pl-10">
            {children}
          </ol>
        </div>
      ),
      // [BLOCKS.LIST_ITEM]: (_, children: ReactNode) => (
      //   <ul className="text-lg text-black mb-6 unorderedList pl-6">
      //     <li style={{ fontSize: "1.3rem" }}>{children}</li>
      //   </ul>
      // ),
      [BLOCKS.UL_LIST]: (_, children: ReactNode) => (
        <ul className="text-lg text-black mb-6 unorderedList pl-6">
          {children}
        </ul>
      ),
      [BLOCKS.TABLE]: (_, children: ReactNode) => (
        <table
          {...tableBodyStyle}
          style={{  width: "80%",display:isMobile?"block":"",overflowX:isMobile?"scroll":"hidden" }}
        >
          <tbody>{children}</tbody>
        </table>
      ),
      [BLOCKS.TABLE_HEADER_CELL]: (_, children: ReactNode) => (
        <th {...(isRelatedContent ? relatedTableProps : tableProps)}>
          {children}
        </th>
      ),
      [BLOCKS.TABLE_ROW]: (_, children: ReactNode) => (
        <tr
          {...(isRelatedContent ? relatedTablePropsCell : tableProps)}
          style={{ borderSpacing: "1rem" }}
        >
          {children}
        </tr>
      ),
      [BLOCKS.TABLE_CELL]: (_, children: ReactNode) => (
        <td
          {...(isRelatedContent ? relatedTablePropsCell : tableProps)}
          style={{
            padding: "0.5rem",
            overflow: "hidden",
            textOverflow: "ellipsis",
            width: "20%",
            minWidth: "auto",
            borderWidth:"1px"
          }}
        >
          {children}
        </td>
      ),
      [BLOCKS.EMBEDDED_ENTRY]: (node, children) => {
        if (node.data.target.sys.contentType.sys.id === "freeformContent") {
          return (
            <div>
              <DisplayContent
                value={node?.data?.target?.fields?.content}
                heading={node?.data?.target?.fields?.heading}
              />
            </div>
          );
        }
      },
      [BLOCKS.EMBEDDED_ASSET]: (node, children) => {
        return (
          <img
            src={`https://${node.data.target.fields.file.url}`}
            height="340px"
            width="670.99px"
            alt={node.data.target.fields.description}
          />
        );
      },
    },
  };
  return (
    <>
      <div>
        <h1 className="text-darkText font-semibold text-[2.6rem]">{heading}</h1>
      </div>
      {documentToReactComponents(value, renderOptions)}
    </>
  );
};
