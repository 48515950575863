import React from 'react';

export const User = () => {
  return (
    <div className="flex pr-2 relative" style={{ top: -6 }} data-testId = "userIcon">
      <div className="w-8 h-full relative text-blue-pure">
        <svg
          className="w-8 h-full"
          xmlns="https://static.sky.co.nz/sky/icons/userIcon.svg"
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round">
          <path d="M12 14c2.209 0 4-2.239 4-5s-1.791-5-4-5-4 2.239-4 5 1.791 5 4 5z" />
          <path d="M12 14c2.209 0 4-2.239 4-5s-1.791-5-4-5-4 2.239-4 5 1.791 5 4 5z" />
          <path d="M21 22v-2c0-2.761-4.486-4-9-4s-9 1.239-9 4v2" />
        </svg>
      </div>
    </div>
  );
};

export default User;
