import React from 'react';
import { bannerContents } from './WebContentSingleBanner';
import { VideoSrcScript } from './VideoSrcScript';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';

export const BannerVideoIframe = ({ brightCoveVideoExperienceId }: { brightCoveVideoExperienceId?: string }) => {
  return (
    <>
      <div data-experience={brightCoveVideoExperienceId} className="lg:w-1/2 w-3/2" style={{ overflow: 'auto' }}>
        <VideoSrcScript brightCoveVideoExperienceId={brightCoveVideoExperienceId} />
      </div>
    </>
  );
};

export const SingleBannerWithVideoContent = ({ bannerContent }: bannerContents) => {
  return (
    <>
      {/* Mobile view */}
      <div
        className="flex bg-no-repeat bg-cover md:hidden lg:hidden relative mt-4"
        style={{
          backgroundImage: `url('${
            bannerContent?.heroImageMobile?.url ? bannerContent?.heroImageMobile?.url : bannerContent?.heroImage?.url
          }')`,
          backgroundSize: 'cover',
          height: '430px',
        }}>
        <div className="absolute top-4 text-center" style={{ left: '50%', transform: 'translate(-50%, -50%)' }}>
          {bannerContent?.bannerName && <div className="sky-h4 md:sky-h2 text-white">{bannerContent?.bannerName}</div>}
          {bannerContent?.detailParagraph && (
            <div className="mt-4 sky-h6 md:sky-h7 text-white">
              {documentToReactComponents(bannerContent?.detailParagraph as any)}
            </div>
          )}
        </div>

        <div
          className="w-full lg:flex absolute"
          style={{ top: '10rem', left: '0.5rem' }}
          id="video"
          data-experience={bannerContent?.brightCoveVideoExperienceId}>
          {bannerContent?.brightCoveVideoExperienceId && (
            <BannerVideoIframe brightCoveVideoExperienceId={bannerContent?.brightCoveVideoExperienceId} />
          )}
        </div>
      </div>
      {/* Desktop view */}
      <div
        className="hidden lg:flex relative w-100 h-100 relative bg-center bg-cover mt-4 overflow-hidden"
        style={{
          backgroundImage: `url(${bannerContent?.heroImage?.url})`,
          height: '588px',
        }}>
        <div className="absolute top-4 text-center" style={{ left: '50%', transform: 'translate(-50%, -50%)' }}>
          {bannerContent?.bannerName && <div className="sky-h3 md:sky-h3 text-white">{bannerContent?.bannerName}</div>}
          {bannerContent?.detailParagraph && (
            <div className="mt-4 sky-h6 md:sky-h7 text-white">
              {documentToReactComponents(bannerContent?.detailParagraph as any)}
            </div>
          )}
        </div>

        <div
          className="w-full lg:flex absolute"
          style={{ top: '6rem', left: '25%' }}
          id="video-carousel"
          data-experience={bannerContent?.brightCoveVideoExperienceId}>
          {/* {bannerContent?.brightCoveVideoId && (
            <iframe
              src={HORIZONTAL_VIDEO_PLAYER_URL + bannerContent?.brightCoveVideoId}
              allowFullScreen
              allow="fullscreen"
              frameBorder={0}
              width={'720'}
              height={'405'}></iframe>
          )} */}
          {bannerContent?.brightCoveVideoExperienceId && (
            <BannerVideoIframe brightCoveVideoExperienceId={bannerContent?.brightCoveVideoExperienceId} />
          )}
        </div>
      </div>

      {/* Tablet view */}
      <div
        className="hidden md:flex lg:hidden bg-no-repeat bg-cover relative mt-4"
        style={{
          backgroundImage: `url(${bannerContent?.heroImage?.url})`,
          height: '588px',
        }}>
        <div className="absolute top-4 text-center" style={{ left: '50%', transform: 'translate(-50%, -50%)' }}>
          {bannerContent?.bannerName && <div className="sky-h2 md:sky-h4 text-white">{bannerContent?.bannerName}</div>}
          {bannerContent?.detailParagraph && (
            <div className="mt-4 sky-h6 md:sky-h7 text-white">
              {documentToReactComponents(bannerContent?.detailParagraph as any)}
            </div>
          )}
        </div>
        <div
          className="w-full lg:flex absolute"
          style={{ top: '8rem', left: '0.5rem' }}
          id="video-carousel"
          data-experience={bannerContent?.brightCoveVideoExperienceId}>
          {bannerContent?.brightCoveVideoExperienceId && (
            <BannerVideoIframe brightCoveVideoExperienceId={bannerContent?.brightCoveVideoExperienceId} />
          )}
        </div>
      </div>
    </>
  );
};
