import { gql } from '@apollo/client';
import { ComposePage } from '../fragments/composePage';

export const getComposePageQuery = gql`
  query GetComposePage($usePreview: Boolean, $slug: String) {
    composePage(usePreview: $usePreview, slug: $slug) {
      ...ComposePage
    }
  }
  ${ComposePage}`;
