import React from 'react';

import { CarouselContainer } from '../carousel/CarouselContainer';
import { NextArrow, PrevArrow } from '../carousel/DartCarouselArrow';
import { BannerContent, WebContentSingleBanner } from './WebContentSingleBanner';

interface rollingBanner {
  bannerContent?: BannerContent[];
  heroBrandBackground?:string;
}

const slickSettings = {
  adaptiveHeight: true,
  dots: true,
  infinite: false,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  customClassName: 'more-choice-for-you-section-carousel more-choice-for-you-section-carousel-slick-dots mb-sky-lg',
};

const DartPrevArrow = () => {
  return (
    <div className={'w-12 h-12 z-100 block'}>
      <svg width="32" height="32" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle opacity="0.6" cx="24" cy="24" r="24" transform="rotate(-180 24 24)" fill="black" />
        <path
          d="M16.744 25.4125C18.9184 27.3164 21.0929 29.2203 23.2674 31.1243C24.2081 31.9471 25.1444 32.7745 26.0918 33.5895C26.533 33.9701 27.0842 34.0307 27.5243 33.7815C27.9531 33.539 28.1866 33.0529 28.1181 32.5522C28.0654 32.1683 27.8195 31.9146 27.5445 31.6732C24.8502 29.3191 22.1616 26.9594 19.4607 24.6121C19.2126 24.3965 19.189 24.291 19.4528 24.0631C21.2748 22.4915 23.081 20.903 24.8918 19.319C25.8325 18.4962 26.7777 17.6778 27.7084 16.8437C28.1361 16.4598 28.2394 15.967 28.0407 15.4865C27.8498 15.0262 27.4457 14.7523 26.9035 14.7478C26.5398 14.7243 26.2434 14.9432 25.9549 15.1957C22.8879 17.8821 19.8188 20.5674 16.7507 23.2526C15.9267 23.9745 15.9234 24.6952 16.744 25.4125Z"
          fill="white"
        />
      </svg>
    </div>
  );
};

const DartNextArrow = () => {
  return (
    <div className={'w-12 h-12 z-100 block'}>
      <svg width="32" height="32" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle opacity="0.6" cx="24" cy="24" r="24" fill="black" />
        <path
          d="M31.256 22.5875C29.0816 20.6836 26.9071 18.7797 24.7326 16.8757C23.7919 16.0529 22.8556 15.2255 21.9082 14.4105C21.467 14.0299 20.9158 13.9693 20.4757 14.2185C20.0469 14.461 19.8134 14.9471 19.8819 15.4478C19.9346 15.8317 20.1805 16.0854 20.4555 16.3268C23.1498 18.6809 25.8384 21.0406 28.5393 23.3879C28.7874 23.6035 28.811 23.709 28.5472 23.9369C26.7252 25.5085 24.919 27.097 23.1082 28.681C22.1675 29.5038 21.2223 30.3222 20.2916 31.1563C19.8639 31.5402 19.7606 32.033 19.9593 32.5135C20.1502 32.9738 20.5543 33.2477 21.0965 33.2522C21.4602 33.2757 21.7566 33.0568 22.0451 32.8043C25.1121 30.1179 28.1812 27.4326 31.2493 24.7474C32.0733 24.0255 32.0766 23.3048 31.256 22.5875Z"
          fill="white"
        />
      </svg>
    </div>
  );
};

export const RollingBannerCarousel = ({ bannerContent,heroBrandBackground }: rollingBanner) => {
  return (
    <div className="rolling-banner-carousel pb-[1rem]">
      <CarouselContainer
        {...{
          // ...slickSettings,
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: true,
          dots: true,
          infinite: true,
          speed: 250,
          prevArrow: (
            <div>
              <PrevArrow className={`relative -right-5`} />
            </div>
          ),
          nextArrow: (
            <div>
              <NextArrow className={`relative -left-7`} />
            </div>
          ),
          responsiveBlock: [
            {
              breakpoint: 750,
              settings: {
                ...slickSettings,
                dots: true,
                arrows: false,
                infinite: true,
                slidesToShow: 1,
                swipeToSlide: true,
                centerMode: true,
                centerPadding: '2px',
                prevArrow: (
                  <div>
                    <button className={'relative'} style={{ top: '24rem', right: '-10rem' }}>
                      <DartPrevArrow />
                    </button>
                  </div>
                ),
                nextArrow: (
                  <div>
                    <button className={'relative'} style={{ top: '24rem', left: '-10rem' }}>
                      <DartNextArrow />
                    </button>
                  </div>
                ),
              },
            },
            {
              breakpoint: 1024,
              settings: {
                ...slickSettings,
                dots: true,
                arrows: false,
                infinite: true,
                swipeToSlide: true,
                slidesToShow: 1,
                prevArrow: (
                  <div>
                    <button className={'relative'} style={{ top: '13.5rem', right: '-21rem' }}>
                      <DartPrevArrow />
                    </button>
                    {/* <PrevArrow className={`  opacity-50  relative -right-7`} /> */}
                  </div>
                ),
                nextArrow: (
                  <div>
                    {/* <NextArrow className={`relative -left-7`} /> */}
                    <button className={'relative'} style={{ top: '13.5rem', left: '-21rem' }}>
                      <DartNextArrow />
                    </button>
                  </div>
                ),
              },
            },
          ],
        }}>
        {(bannerContent ?? []).map((rollingBanner: any, i: number) => {
          return (
            <div key={i}>
              <WebContentSingleBanner bannerContent={rollingBanner} heroBrandBackground = {heroBrandBackground}/>
            </div>
          );
        })}
      </CarouselContainer>
    </div>
  );
};
