import { gql } from '@apollo/client';

export const GetArticlePage = gql `
query articlePage($usePreview: Boolean, $articleType: String,$selectCategories: String) {
  articlePage(usePreview: $usePreview,articleType:$articleType,selectCategories:$selectCategories) {
     internalName
     heading
     date
     articleType
     linkUrl
     linkText
     linkDocument{
       title
       description
       file
     }
     selectCategories
   }
   }
`