import React, { ReactNode } from 'react';
import { Document, BLOCKS, INLINES, MARKS } from '@contentful/rich-text-types';
import { Options, documentToReactComponents } from '@contentful/rich-text-react-renderer';

const renderOptions: Options = {
  renderText: (text: string) =>
    text
      .split('\n')
      .reduce(
        (children: any, textSegment: any, index: number) => [...children, index > 0 && <br className = "sky-h4"key={index} />, textSegment],
        []
      ),

  renderMark: {
    [MARKS.BOLD]: (text: ReactNode) => <b>{text}</b>,
  },
  renderNode: {
   
    [BLOCKS.HEADING_1]: (_, children: ReactNode) => (
      <h1 className="py-2 mx-auto text-2xl md:text-3xl text-black">{children}</h1>
    ),
    [BLOCKS.HEADING_2]: (_, children: ReactNode) => (
      <h2 className="text-darkText font-semibold text-[2rem] text-center">
        {children}
      </h2>
    ),
    [BLOCKS.HEADING_3]: (_, children: ReactNode) => (
      <h3 className="text-[2rem] text-darkText font-semibold mb-2 mt-4">
        {children}
      </h3>
    ),
    [BLOCKS.HEADING_5]: (_, children: ReactNode) => (
      <h4 className="text-sm" style={{marginTop:"1rem"}}>
        {children}
      </h4>
    ),
    [BLOCKS.EMBEDDED_ASSET]: (node, children) => {
      return (
        <img
          src={`https://${node.data.target.fields.file.url}`}
          // height="340px"
          // width="670.99px"
          alt={node.data.target.fields.description}
        />
      );
    },
    [BLOCKS.PARAGRAPH]: (_, children: ReactNode) => <p className="text-lg text-black sky-h4" style={{fontSize:"1.3rem"}}>{children}</p>,
    [INLINES.HYPERLINK]: (node: any, children: ReactNode) => (
      <a
          href={node?.data?.uri}
          onClick={(e) => {
            e.preventDefault();
            const url = node?.data?.uri;

            if (
              url &&
              (url.startsWith("http://") ||
                url.startsWith("https://") ||
                url.includes("."))
            ) {
              window.open(url, "_blank", "noopener,noreferrer");
            } else {
              window.location.href = url;
            }
          }}
          className="sky-text-daylight"
          style={{
            color: "#0b5fff",
            fontSize: "1.3rem",
          }}
        >
          {children}
        </a>
    ),
    [BLOCKS.OL_LIST]: (_, children: ReactNode) => (
      <div className="desk:px-6 pb-8 lg:px-0 mx-auto">
        <ol className="text-lg text-black orderedList desk:pl-10 ">{children}</ol>
       </div>
  ),
  // [BLOCKS.LIST_ITEM]: (_, children: ReactNode) => <ul className="text-lg text-black mb-6 unorderedList pl-6"><li style={{fontSize:"1.3rem"}}>{children}</li></ul>,
  // [BLOCKS.UL_LIST]: (_, children: ReactNode) => <ul className="text-lg text-black mb-6 unorderedList pl-6">{children}</ul>,
  
    [BLOCKS.UL_LIST]: (_, children: ReactNode) => <ul className="text-lg text-black unorderedList">{children}</ul>,
  },
};

export const DocumentToReactComponents = ({ richtextEditorProps }: { richtextEditorProps?: Document }) => {
  return <>{richtextEditorProps && <>{documentToReactComponents(richtextEditorProps, renderOptions)}</>}</>;
};

