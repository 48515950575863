import React, { useEffect } from 'react';

export interface VideoProp {
  showVideo?: boolean;
  src?: string;
  width?: string;
  height?: string;
}

export const VideoDisplay = ({ src }: VideoProp) => {
  return (
    <div
      className="w-full "
      style={{ position: 'relative', paddingBottom: '56.25%' }}
      id="video-carousel"
      data-testid = "video-iframe-ID"
      data-experience="64d189d76f5fc7eecab9be7c">
      <iframe
        src={`https://players.brightcove.net/6122285394001/Uk656dFgP_default/index.html?videoId=${src}`}
        allow="encrypted-media"
        className="absolute top-0 left-0 w-full h-full"
        frameBorder="0"
        data-testid = "video-iframe"
        allowFullScreen></iframe>
    </div>
    // <>
    // <Helmet>
    //     <script
    //       src={`https://players.brightcove.net/6122285394001/Uk656dFgP_default/index.html?videoId=${src}`}></script>
    //   </Helmet>
    // </>
  );
};
